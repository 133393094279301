/**
 * Created by haolunchai on 2017-01-03.
 */
import { connect } from 'react-redux'
import Nav from '../../components/navigationBar/nav'
import * as uiAction from '../../actions/uiActions'
// import {logoutUser} from "../../actions/authActions";
import {getPartner} from "../../selectors/partnerSelectors";
import shoppingCart from "../../reducers/shoppingCart";

const mapStateToProps = (state, ownProps) => {
    // console.log(ownProps);
    const pathname = ownProps.location.pathname;
    const pathArr = pathname.split('/')
    let pathPartnerId = parseInt(pathArr[1],10); // "" ,  "id" , "slug"
    pathPartnerId = !isNaN(pathPartnerId) ? pathPartnerId : null;
    const shoppingCartPartnerId = state.shoppingCart.shoppingCart && state.shoppingCart.shoppingCart.partner && state.shoppingCart.shoppingCart.partner.id;
    //console.log(pathPartnerId, shoppingCartPartnerId)
    const partnerId = pathPartnerId || shoppingCartPartnerId
    return {
        // currentUser: state.auth.user,
        uiNavBar: state.ui.navBar,
        uiModal: state.ui.modal,
        shoppingCartQuantity:state.shoppingCart.shoppingCart.totalQuantity,
        shouldShowBanner:state.ui.isFirstBannerVisit&&ownProps.location&&ownProps.location.pathname==="/",
        menuLanguage: state.ui.menuLanguage,
        partnerSelected: getPartner(state, {extraInfo:{partnerId}}),
        isQR: state.auth.isQR,
        shoppingCartId: state.shoppingCart.shoppingCart.id,
        tableNum: state.shoppingCart.shoppingCart.table_num,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToggle: (rect,type) => {
            dispatch(uiAction.toggleDownMenu(rect,type))
        },
        initAccountIconId:(id) =>{
            dispatch(uiAction.initAccountIconId(id))
        },
        finishFirstBanner:() =>{
            dispatch(uiAction.firstFirstBanner());
        },
        // logout: () => dispatch(logoutUser()),
        changeMenuLanguage: (index, abbr)=>dispatch(uiAction.changeMenuLanguage(index, abbr))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
