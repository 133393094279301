/**
 * Created by haolunchai on 2017-01-17.
 */
import * as actionTypes from '../actions/uiActions';
import {
    SHOW_LOADING_SPINNER,
    HIDE_LOADING_SPINNER,
    SHOW_POPUP_WINDOW,
    CLOSE_POPUP_WINDOW,
    START_SOUND_BEEP,
    END_SOUND_BEEP,FIRST_VISIT_END,
    FIRST_BANNER_END,
    ADD_FLAG,
    DISMISS_FLAG,
  MENU_LANGUAGE_CHANGE
} from '../actions/uiActions'
import { List, Map, Record } from 'immutable';

const initialUiState = new Record({
    navBar: new Map(
        {
            fixed: true,
            enableShopCartBtn: true,
            accountIconId:null, //so any place can open up modal with correct position
        }
    ),
    flagCount: 0,
    flags: List(),
    modal: new Map(
        {
            isTogged: false,
            rect: new Map({
                bottom:0,
                right:0
            }),
            type:null,
        }
    ),
    payment : new Map(
        {
            paymentSuccess: false
        }
    ),

    popup: new Map(
        {
            show: false,
            title: "",
            message: "",
            showConfirmButton: false,
            showCancelButton: false,
            confirmTitle: "",
            cancelTitle: "",
            confirmAction: ()=>{},
            cancelAction: ()=>{},
        }
    ),

    //Determine whether should show a spinner
    showLoading: false,
    isPlayingBeep:false,
    isFirstTimeVisit:true, //for pop up
    isFirstBannerVisit:true, // for banner

    menuLanguage: {index: 0, abbr: 'En'},
})();
export default function ui(state=initialUiState, action) {
    switch (action.type) {
        case actionTypes.TOGGLE_UP_MENU:
            return state.update('modal', modal=> modal.merge({isTogged: false,type:null}))
        case actionTypes.TOGGLE_DOWN_MENU:
            return state.update('modal', modal=> modal.mergeDeep(action.payload))
        case actionTypes.FIXING_NAV_BAR:
            return state.setIn(['navBar', 'fixed'], true)
        case actionTypes.UN_FIXING_NAV_BAR:
            return state.setIn(['navBar', 'fixed'], false)
        case actionTypes.DISABLE_SHOP_CART_BTN:
            return state.update('navBar', modal=> modal.merge(action.payload))
        case actionTypes.ENABLE_SHOP_CART_BTN:
            return state.update('navBar', modal=> modal.merge(action.payload))
        case actionTypes.INIT_ACCOUNT_ICON_ID:
            return state.setIn(['navBar','accountIconId'],action.ref)
        case SHOW_LOADING_SPINNER:
            return state.set("showLoading", true)
        case HIDE_LOADING_SPINNER:
            return state.set("showLoading", false)
        case SHOW_POPUP_WINDOW:
            let tempInfo = new Map({
                show:true,
                title:action.info.title,
                message:action.info.message,
                showConfirmButton:action.info.showConfirmButton,
                showCancelButton:action.info.showCancelButton,
                confirmTitle:action.info.confirmTitle,
                cancelTitle:action.info.cancelTitle,
                confirmAction:action.info.confirmAction,
                cancelAction:action.info.cancelAction,
            })
            return state.set("popup", tempInfo)
        case CLOSE_POPUP_WINDOW:
            return state.setIn(['popup','show'],false)
        case START_SOUND_BEEP:
            return state.set("isPlayingBeep",true)
        case END_SOUND_BEEP:
            return state.set("isPlayingBeep",false)
        case FIRST_VISIT_END:
            return state.set("isFirstTimeVisit",false)
        case FIRST_BANNER_END:
            return state.set("isFirstBannerVisit",false)
        case DISMISS_FLAG:
            return state.set("flags", state.flags.slice(1));
        case ADD_FLAG:
            return state.set("flags", state.flags.unshift(
                {
                    id: state.flags.size,
                    key: state.flags.size,
                    ...action.payload
                }
            ))
      case MENU_LANGUAGE_CHANGE:
        return state.set('menuLanguage', action.lang);
        default:
            return state;

    }
}