import Button from "@atlaskit/button";
import styled from "styled-components";
import extract from "../utils";

const baseStyles = {
    border: 'none',
};
const customTheme =  {
    secondary: {
        background: {
            default: '#333',
            hover: 'rgba(51, 51, 51, 0.85)',
            disabled: '#EBECF0',
            active: "#222",
        },
        color: {
            default: '#fff',
            disabled: '#A5ADBA',
        },
        boxShadowColor: {
            focus: '#7A869A',
        },
    },

  primary: {
    background: {
      default: '#ff4e3d',
      hover: 'rgba(255, 78, 61, 0.85)',
      disabled: '#EBECF0',
      active: "#CC1B0A",
    },
    color: {
      default: '#fff',
      disabled: '#A5ADBA',
    },
    boxShadowColor: {
      focus: '#7A869A',
    },
  }
};
const ThemedButton = (props) => (
    <Button
        {...props}
        theme={(currentTheme, themeProps) => {
            const { buttonStyles, ...rest } = currentTheme(themeProps);
            return {
                buttonStyles: {
                    ...buttonStyles,
                    ...baseStyles,
                    ...extract(customTheme, themeProps),
                },
                ...rest,
            };
        }}
    />
);
const CustomBtn = ({theme, className, style, children, ...props}) => (
    <span className={className} style={style}>
        <ThemedButton shouldFitContainer {...props}>
            {children}
        </ThemedButton>
    </span>
);

const ThemedBtn = styled(CustomBtn)`
    display: flex;
    flex: 1;
`;

export default ThemedBtn;
