import Modal from "react-modal";
import React from "react";
import {BarButton, H3} from "../styledComponents";
import {useIntl} from "react-intl";
import successIcon from "./success-svgrepo-com.svg";
type Props = {
    isOpen: boolean;
    onEndTransaction: () => void
}

const AfterPayment = ({isOpen, onEndTransaction}:Props) => {
    const { formatMessage } = useIntl();
    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            ariaHideApp={false}
            // onRequestClose={()=>this.setState({verifyingAddress: '',isVerifying : false, validatedAddress: null, distance: 0})}
            style={{
                content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)'
                }
            }} >
            <div style={{width: '100%', paddingLeft: 20, paddingRight: 20, height: '200px', display:'flex', flexDirection: 'column'}} >
                <div  style={{fontWeight: 'bold', fontSize: 24, flex: 1, display:'flex', alignItems:'center', flexDirection: 'column'}}>
                    <img src={successIcon} alt='successIcon' width={45}/>
                    <H3>{formatMessage({
                    defaultMessage: "Your order has been placed!",
                    description: "textCheckoutSuccess",
                })}</H3></div>
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'flex-end', marginTop: 8}}>
                    <BarButton appearance="primary" onClick={onEndTransaction} style={{flex: 1}}>
                        {formatMessage({
                            defaultMessage: "Back To Shop",
                            description: "buttonBackToShop",
                        })}
                    </BarButton>
                </div>
            </div>
        </Modal>)
}

export default AfterPayment
