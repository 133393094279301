/**
 * Created by LittleM on 2017-04-10.
 */
import {defineMessages} from 'react-intl'

export const generalI18nMsg = defineMessages({
    requiredFieldEmptyErr: {
        id: "errorMsg.requiredFieldEmptyErr",
        defaultMessage: "This field is required.",
        description: "required field Empty Err",
    },

    errorShoppingCartEmpty: {
        id: "errorMsg.errorShoppingCartEmpty",
        defaultMessage: "Your shopping cart is empty.",
        description: "Err: Shopping Cart Empty",
    },
    errorShippingAddressNotSelected: {
        id: "errorMsg.errorShippingAddressNotSelected",
        defaultMessage: "Please select a shipping address.",
        description: "Err: Shipping Address Not Selected",
    },
    errorStartEndTime: {
        id: "errorMsg.errorStartEndTime",
        defaultMessage: "Please select both start time and end time.",
        description: "Err: both start and end time select required",
    },
    errorInvalidTimeRange: {
        id: "errorMsg.errorInvalidTimeRange",
        defaultMessage: "Please select a valid time range.",
        description: "Err: time range invalid",
    },
    errorTipsInsufficient: {
        id: "errorMsg.errorTipsInsufficient",
        defaultMessage: "Tips is too less",
        description: "Err: error Tips Insufficient",
    },

    errorUnexpectedServerError: {
        id: "errorMsg.errorUnexpectedServerError",
        defaultMessage: "Unexpected error, please try again later.",
        description: "Err: Unexpected Server Error",
    },

    errorEnteredInvalidAddress: {
        id: "errorMsg.errorEnteredInvalidAddress",
        defaultMessage: "Please enter a valid address.",
        description: "Err: Entered Invalid Address",
    },


    inputFieldTextAddress:{
        id: "generalText.inputFieldTextAddress",
        defaultMessage: "Address",
        description: "text address for input field",
    },

    inputFieldTextEmail:{
        id: "generalText.inputFieldTextEmail",
        defaultMessage: "Email Address",
        description: "text email address for input field",
    },
    inputFieldTextPassword:{
        id: "generalText.inputFieldTextPassword",
        defaultMessage: "Password",
        description: "text password for input field",
    },
    inputFieldTextFirstName:{
        id: "generalText.inputFieldTextFirstName",
        defaultMessage: "First Name",
        description: "text first name for input field",
    },
    inputFieldTextLastName:{
        id: "generalText.inputFieldTextLastName",
        defaultMessage: "Last Name",
        description: "text Last Name for input field",
    },
    inputFieldTextName:{
        id: "generalText.inputFieldTextName",
        defaultMessage: "Full Name",
        description: "text Name for input field",
    },
    inputFieldTextPhoneNumber:{
        id: "generalText.inputFieldTextPhoneNumber",
        defaultMessage: "Phone Number",
        description: "text Phone number for input field",
    },
    inputFieldTextConfirmPassword:{
        id: "generalText.inputFieldTextConfirmPassword",
        defaultMessage: "Confirm password",
        description: "text Confirm password for input field",
    },
    inputFieldTextNewPassword:{
        id: "generalText.inputFieldTextNewPassword",
        defaultMessage: "New password",
        description: "text new password for input field",
    },
    inputFieldTextConfirmNewPassword:{
        id: "generalText.inputFieldTextConfirmNewPassword",
        defaultMessage: "Confirm new password",
        description: "text confirm new password for input field",
    },

    popupTitleWarning:{
        id: "generalText.popupTitleWarning",
        defaultMessage: "Warning!",
        description: "popup Title Warning",
    },
    popupButtonContinue:{
        id: "generalText.popupButtonContinue",
        defaultMessage: "Continue",
        description: "popup Continue",
    },
    popupButtonCancel:{
        id: "generalText.popupButtonCancel",
        defaultMessage: "Cancel",
        description: "popup Cancel",
    },

    textShopClosed:{
        id: "generalText.textShopClosed",
        defaultMessage: "CLOSED",
        description: "text Shop Closed",
    },
    textComponentLoading:{
        id: "generalText.textComponentLoading",
        defaultMessage: "Loading...",
        description: "component loading",
    },

    unitKilometer:{
        id: "generalText.unitKilometer",
        defaultMessage: "Km",
        description: "unit Kilometer",
    },

    areaAll: {
        id: "areaMenuText.areaAll",
        defaultMessage: "All",
        description: "area Option All",
    },
    areaAllArea: {
        id: "areaMenuText.areaAllArea",
        defaultMessage: "All Area",
        description: "area Option All Area",
    },
    areaAllCities: {
        id: "areaMenuText.areaAllCities",
        defaultMessage: "All Cities",
        description: "area Option All Cities",
    },
    areaDowntown: {
        id: "areaMenuText.areaDowntown",
        defaultMessage: "Downtown",
        description: "area Option Downtown",
    },
    areaNorthYork: {
        id: "areaMenuText.areaNorthYork",
        defaultMessage: "North York",
        description: "area Option North York",
    },
    areaScarborough: {
        id: "areaMenuText.areaScarborough",
        defaultMessage: "Scarborough",
        description: "area Option Scarborough",
    },
    areaMarkham: {
        id: "areaMenuText.areaMarkham",
        defaultMessage: "Markham",
        description: "area Option Markham",
    },
    areaRichmondHill: {
        id: "areaMenuText.areaRichmondHill",
        defaultMessage: "Richmond Hill",
        description: "area Option Richmond Hill",
    },


    textYes: {
        id: "generalText.textYes",
        defaultMessage: "Yes",
        description: "textYes",
    },
    textNo: {
        id: "generalText.textNo",
        defaultMessage: "No",
        description: "textNo",
    },
    textRequired: {
        id: "generalText.textRequired",
        defaultMessage: "Required",
        description: "textRequired",
    },

    warningClosed: {
        id: "generalText.warningClosed",
        defaultMessage: "Closed",
        description: "warningClosed",

    }


})