/**
 * Created by haolunchai on 2017-05-03.
 */
import Cookies from 'universal-cookie'
import {cookies} from './index'
import { COOKIES_DOMAIN } from './actions/api'
import enLocalization from './l10n/en.json';
import zhLocalization from './l10n/zh.json';
import {createIntl, createIntlCache, IntlProvider} from 'react-intl';
import store from './store/configureStore'

import moment from "moment";

const cache = createIntlCache();


export function sortFunc(isAscend, field) {
    return (o1, o2) => {
        if (field) {
            return (isAscend ? o1[field] - o2[field] : o2[field] - o1[field]);
        }
        return (isAscend ? o1 - o2 : o2 - o1);
    };
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function get_cookie (name, options) {
    return cookies.get(name, options)
}

export function set_cookie (name, value, options) {
    console.log('setcookie', name, value);
    options['domain'] = COOKIES_DOMAIN
    return cookies.set(name, value, options)
}

export function get_intl(){
    let locale = get_cookie('django_language') || 'en';
    let messages = locale === 'zh-hans' ? zhLocalization : enLocalization
    const intl = createIntl({
        locale: locale,
        messages: messages
    }, cache)
    return intl;
}


export function getLocaleName(data, field){
    const menuLanguage = store.getState().ui.menuLanguage;
    // console.log(menuLanguage)
    if(!field){
        return (`${menuLanguage.index}` === '0' ? data.name_en : data.name_zh_hans)
    }
    return (`${menuLanguage.index}` === '0' ? data[`${field}_en`] : data[`${field}_zh_hans`])
}

export function getLocaleName2(data, field){
    let locale = get_cookie('django_language') || 'en';
    if(!field){
        return (locale === 'en' ? data.name_en : data.name_zh_hans)
    }
    return (locale==='en' ? data[`${field}_en`] : data[`${field}_zh_hans`])
}

export function isOpen(openHours, closingRules, time=null) {
    let momentTime =  moment()
    for(let i=0;i < closingRules.length;i+=1){
        if(moment(closingRules[i].start)<= momentTime && momentTime < moment(closingRules[i].end)){
            return false
        }
    }
    for(let i=0;i < openHours.length;i+=1){
        if(momentTime.isBetween(moment(openHours[i].from_hour, 'HH:mm:ss'), moment(openHours[i].to_hour, 'HH:mm:ss'), null, '[)' ))
            return true
    }
    return false

}
export function round(date, duration, method) {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}

export function formatPhoneNumber(phoneNumberString) {

    let match = phoneNumberString.match(/^\+1(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return ['(', match[1], ') ', match[2], '-', match[3]].join('')
    }
    return null
}

(function() {
    /**
     * Decimal adjustment of a number.
     *
     * @param {String}  type  The type of adjustment.
     * @param {Number}  value The number.
     * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number} The adjusted value.
     */
    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    // Decimal round
    if (!Math.round10) {
        Math.round10 = function(value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }
    // Decimal floor
    if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }
    // Decimal ceil
    if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }
})();
