import {CardElement, useStripe, useElements, Elements,} from '@stripe/react-stripe-js';
import {loadStripe,} from "@stripe/stripe-js";
import {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {PlaceOrderRequest} from "../../store/shoppingCart/types";

type Props = {
    onErrorUpdating?: (errorMessage: string | null) => void;
    onCardChange?: (cardComplete: boolean, captured?: boolean) => void;
    clientSecret?: string;
    // orderData: PlaceOrderRequest;
}
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    },
    hidePostalCode: true
};
const CardCtn = styled.div`
  margin: 20px 0;
  padding: 0;
  border-style: none;
  background-color: #FAFBFC;
  border-radius: 3px;
  will-change: opacity, transform;
`
const Row = styled.div`
  margin-left: 15px;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  .StripeElement {
    width: 100%;
    padding: 11px 0 11px 0;
  }
`
const CreditCard = (props:Props) => {
    const stripe = useStripe();
    const {onErrorUpdating, onCardChange, clientSecret} = props
    const elements = useElements();
    const cardElement = elements?.getElement(CardElement);
    const [cardComplete, setCardComplete] = useState(false);

    const setError = (error: { type: "validation_error"; code: string; message: string; } | undefined) => {
        if (error) onErrorUpdating&&onErrorUpdating(error.message)
    }
    // option 1:
    // useEffect(() => {
    //     if(startPlacing) handleSubmit()
    //
    // }, [startPlacing])

    //option 2: flow over its parent
    useEffect(() => {
        clientSecret&&handleSubmit(clientSecret)
        },[clientSecret])
    useEffect(() => {
            onCardChange&&onCardChange(cardComplete)
    },[cardComplete]
    )
    // useEffect(()=> cancel(),[])
    const handleSubmit = async (clientSecret: string) => {
        // this.setState({isPaying: true});
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();

        if (!stripe || !cardElement) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        // const checkoutSecret = await getStripeSecret(orderData)
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            }
        });
        if (result.error?.message) {
            // Show error to your customer (e.g., insufficient funds)
            // this.setState({status: 'error', isPaying: false, errorMessage: result.error.message})
            onErrorUpdating&&onErrorUpdating(result.error.message)
        } else {
            // The payment has been processed!
            //TODO: @Depreciated: status === 'succeeded'
            if (result.paymentIntent?.status === 'requires_capture') {
                //this.setState({status: 'success', isPaying: false})
                // reset();
                onCardChange&&onCardChange(true,true)
            }
        }

    };
    // const checkCartTimeout = async () => {
    //     try{
    //         const result = await checkShoppingCart()
    //         if (!result || result.status) {
    //             reset();
    //         } else {
    //             cancel();
    //             // this.setState({status: 'success', isPaying: false})
    //         }
    //     } catch(error){
    //         reset()
    //     }
    // };
    // const [isReady, cancel, reset] = useTimeoutFn(checkCartTimeout, 2000);

    return (
        <CardCtn>
           <Row> <CardElement
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);
                }}
            /></Row>
        </CardCtn>
       )
}
const CreditCardSection = (props: Props&{ stripe_pk: string;}) => {
    const {stripe_pk, ...restProps} = props
    const stripePromise = loadStripe(stripe_pk || 'pk_live_qv6aqOCBwPe4as6jZrD3GBA900Emr8EjeH');
    console.debug('Optimization Monitor Warning: Rendering CreditCardSection, expected 0 time(s) while parent rerender')
    return (

        <Elements stripe={stripePromise}>
           <CreditCard {...restProps} />
        </Elements>
    );
}

export default memo(CreditCardSection);
