/**
 * Created by yueyang on 2017/5/4.
 */
import { Component } from 'react';
import Modal from "react-modal"

import { connect } from 'react-redux'

const customStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.3)',
        zIndex            : 6000,
        display           : 'flex',
        alignItems        : 'center',
        justifyContent    : 'center'
    },
    content : {
        position                   : 'relative',
        width                      : '140px',
        height                     : '140px',
        border                     : 'none',
        background                 : 'rgba(0, 0, 0, 0.0)',
        overflow                   : 'auto',
        overflowX                  : 'hidden',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
        top:0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}


class Spinner extends Component {

    render() {
        const {showLoading} = this.props
        return (
            <Modal isOpen={showLoading} contentLabel="Spinner Modal" style={customStyles}>
                <i className="fa fa-spinner fa-pulse fa-5x fa-fw" style={{color:"black"}}/>
            </Modal>
        )
    }
}




const mapStateToProps = (state, ownProps) => {
    return {
        showLoading: state.ui.showLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);