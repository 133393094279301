import styled from 'styled-components';
import InputReset from "./InputReset";

const defaultTheme = {
    radioButton: {
        background: {
            default: "#333",
            hover: "#333",
            active: "#333",
            disabled: "#333",
            selected: "#333",
        },
        size: {
            medium: '24px',
            small: '12px'
        },
        padding: {
            medium: '3px',
            small: '2px'
        }
    }
};

const RadioButtonSkeleton = (props) => {
    const {
        className,
        checked,
        theme,
        ...restProps
    } = props;
    return(<div className={className}>
        <InputReset type="radio" checked={checked} {...restProps}/>
        {checked ? <div style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                backgroundColor: theme.radioButton.background.default,
            }}>
        </div> : null}
    </div>)
};
const RadioButton = styled(RadioButtonSkeleton).attrs(props=> ({
    size: props.size? props.theme.radioButton.size[props.size] : props.theme.radioButton.size.medium,
    padding: props.size? props.theme.radioButton.padding[props.size] : props.theme.radioButton.padding.medium,
}))`
    box-sizing: border-box;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #8e8e8e;
    padding: ${props=>props.padding};
    border-radius: 50%;
    height: ${props=>props.size};
    width:  ${props=>props.size};
`;
RadioButton.defaultProps = {
    theme: defaultTheme
};
export default RadioButton;
