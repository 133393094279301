
import {createSlice, Dispatch, EntityState} from "@reduxjs/toolkit";
import { API_ROOT, parseResponse } from "../../actions/api";
import {get_cookie} from "../../utils";
import {RootState} from "../types";



interface AuthInterfaceInitialState {
    isQR: boolean;
}

const initialState: AuthInterfaceInitialState = {
    isQR: false,
}

const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        verifiedIsQR(state,action){
            state.isQR = Boolean(get_cookie('qr_session') || !!action.payload);
        },
    }
})

const {actions,reducer} = authReducer
const {verifiedIsQR} = authReducer.actions


const authQRCode = (qr_code: string) =>
    async (dispatch: Dispatch, getState: ()=>RootState) => {
        const fullUrl = API_ROOT + `qrmenu/auth?qr_token=${qr_code}`;
        try {
            const json = await fetch(fullUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                method: "get",
            }).then(parseResponse)
            dispatch(verifiedIsQR(true))
            return Promise.resolve();
        } catch(e){
            return Promise.reject();
        }
    };



export default reducer
export {authQRCode, verifiedIsQR, actions}