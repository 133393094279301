import { Component } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components';
import {Link} from "react-router-dom";

const MainCtn = styled.div`
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 100px;
    max-width: 1350px;
    text-align: left;
    @media screen and (min-width: 768px){
        & {
            padding-left: 50px;
            padding-right: 50px;
            max-width: 576px;
        }
    }
    @media screen and (min-width: 1080px){
        & {
            max-width: 576px;
        }
    }
`;

class CompletePage extends Component {
    render() {
        return (
            <MainCtn>
                <h1>Thank you for your order</h1>
                <p> Your order has been confirmed and is now being processed. You'll be reached by our restaurant staff over the phone.</p>

                <Link to={this.props.location.state.returnTo}>Go Back</Link>
            </MainCtn>
        )
    }
}

export default CompletePage;