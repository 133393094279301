import styled from "styled-components";
const DefaultAdditionalPrice = ({price, quantity=1, className}) => (
    <span className={className}>
       + ${(price * quantity).toFixed(2)}
    </span>
);
const AdditionalPrice = styled(DefaultAdditionalPrice)`
    font-size: 12px;
    color: rgb(179,179,179);
    flex: 0 0 50px;
`;

export default AdditionalPrice