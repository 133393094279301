import {get_cookie} from "../../utils";
import moment from "moment";
const CHANGE_DAY_TIME_ASSUMPTION = '06:00:00';
const TIME_INTERVAL = 1800; // in sec
export const A_DAY = 24 * 60 * 60;
const A_WEEK = 7*24 * 60 * 60;

export function getCurrentTS(){
    const now = new Date();
    return now.getDay()*A_DAY+ now.getHours()* 60*60 + now.getMinutes()*60 + now.getSeconds();
}

function isTSWithinRange(ts, toTS, fromTS){
    if(fromTS>toTS){
        const nextToTS = toTS + A_WEEK; // extend a week for comparison
        const nextTS = ts + (ts <= toTS ? A_WEEK : 0); // if across week end, add a week
        return (nextTS <= nextToTS && nextTS >=fromTS)
    }
    return (ts <= toTS && ts>=fromTS)
}

export function isNowAvailable(shifts, initDelay){
    const currentTS = getCurrentTS()
    // console.log(currentTS);
    let isAvail = false
    shifts.sort(sortShift).forEach((shift=>{
        if(!isAvail){
            const time = computeDayTime(shift, true) // convert shift into TS
            time.toTime = (time.toTime + A_WEEK - initDelay) % A_WEEK;
            // console.log(time);
            isAvail = isTSWithinRange(currentTS, time.toTime, time.fromTime)

        }
        // console.log(time, time.fromTime, time.toTime, timestampToString(time.fromTime).name, timestampToString(time.toTime).name);
    }));
    return isAvail
}

export function timestampToFormatTime(ts){
    const todayDay = (new Date()).getDay();
    let day = Math.floor(ts/A_DAY)
    let rest = ts % A_DAY;
    let hour = Math.floor(rest / 3600);
    rest = rest % 3600;
    let min = Math.floor(rest/60);
    let sec = rest % 60
    const today = moment();
    today.second(sec).minute(min).hour(hour);
    if(day<todayDay){
        today.day(7+day);
    }else {
        today.day(day);
    }
    // console.log(today.toISOString());
    return today.toISOString();
}

function overlapTime(from1, to1, from2,to2){
    if(to1 < from2 || from1 > to2){ // |--|  |---------| |--|
        return null;
    }
    return {from:Math.max(from1, from2),to: Math.min(to1, to2)}
}

function getIntervalTimePoint(from,to){
    //console.log(from,to)
    const ret = [];
    for(let cur = to; cur >= from ; cur -=TIME_INTERVAL){
        // console.log(cur);
        ret.push(cur);
    }
    ret.reverse();
    return ret;
}

function getShiftAvailTime(fromTS, toTS, fromTime, toTime){
    let ret = [];
    if(fromTS > toTS){ //divide and conquer
        ret = ret.concat(getShiftAvailTime(fromTS, A_WEEK, fromTime, toTime));
        ret = ret.concat(getShiftAvailTime(0, toTS, fromTime, toTime));
    } else if (fromTime > toTime) {
        ret = ret.concat(getShiftAvailTime(fromTS, toTS, fromTime, A_WEEK));
        ret = ret.concat(getShiftAvailTime(fromTS, toTS, 0, toTime));
    } else {
        const overLap = overlapTime(fromTime, toTime, fromTS,toTS);
        // console.log(overLap)
        if(!overLap){
            return [];
        } else{
            ret = ret.concat(getIntervalTimePoint(overLap.from, overLap.to))
        }
    }
    // console.log(ret);
    return ret;
}

function ceilToNearstInterval(timestamp){
    return timestamp + ((timestamp % TIME_INTERVAL) ? (TIME_INTERVAL - (timestamp % TIME_INTERVAL)) % (A_WEEK) : 0)
}

function divTime(time){
    const ret = time.split(':');
    return {hour: parseInt(ret[0]), min:parseInt(ret[1]), sec:parseInt(ret[2])}
}

function computeDayTime(shift, shouldNotRound){
    let toTime = divTime(shift.to_hour);
    let fromTime = divTime(shift.from_hour);
    if(toTime.hour < fromTime.hour){ // over night
        toTime.hour += (((shift.weekday+1)%7) * 24);
    }else {
        toTime.hour += shift.weekday * 24;
    }
    fromTime.hour += shift.weekday * 24;

    fromTime = fromTime.hour * 3600 + fromTime.min * 60 + fromTime.sec;
    toTime = toTime.hour * 3600 + toTime.min * 60 + toTime.sec;
    if(!shouldNotRound){
        fromTime = ceilToNearstInterval(fromTime); // round to nearest 10min;
        toTime = ceilToNearstInterval(toTime); // round to nearest 10min;
    }
    return {fromTime, toTime};
}

function sortShift(shift1,shift2){
    if (shift1.weekday === shift2.weekday) {
        if (shift1.from_hour === shift2.from_hour) {
            if (shift1.to_hour === shift2.to_hour) {
                return shift1.key - shift2.key;
            }
            return shift1.to_hour > shift2.to_hour ? 1 : -1;
        }
        return shift1.from_hour > shift2.from_hour ? 1 : -1;
    }
    return shift1.weekday - shift2.weekday;
}

// const WEEKDAY_ABBR = ['Su' ,'M', 'T', 'W', 'Th', 'F', 'S'];
export const WEEKDAY_EN = ['Sun' ,'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const WEEKDAY_ZH_HANS = ['(日)', '(一)', '(二)','(三)', '(四)', '(五)', '(六)'];
function addZero(num){
    if(num < 10){
        return `0${num}`;
    }
    return num;
}

function timestampToString(ts){
    // console.log(ts, ts/A_DAY, Math.floor(ts/A_DAY))
    let day = Math.floor(ts/A_DAY)
    let rest = ts % A_DAY;
    let hour = Math.floor(rest / 3600);
    rest = rest % 3600;
    let min = Math.floor(rest/60);
    let sec = rest % 60
    return {name:`${get_cookie('django_language')==='zh-hans'? WEEKDAY_ZH_HANS[day]: WEEKDAY_EN[day]} ${addZero(hour)}:${addZero(min)}` , ts};//:${addZero(sec)}
}

export default function getAvailTime(shifts, initDelay){
    const now = new Date();
    let day = now.getDay();
    const momentnow = moment();
    //console.log(day)
    let fromTS = now.getDay() * A_DAY + now.getHours() *3600 + now.getMinutes() * 60 + now.getSeconds();
    fromTS = ceilToNearstInterval(fromTS); // round to nearest 10;
    let toTS = (fromTS + A_DAY) % A_WEEK;
    fromTS += (initDelay || 0)

    // console.log(fromTS, toTS);
    let availTime = [];
    shifts.sort(sortShift).forEach((shift=>{
        const time = computeDayTime(shift) // convert shift to ceilToNearstInterval(floor round to 600 since Sun)

        // console.log(time, time.fromTime, time.toTime, timestampToString(time.fromTime).name, timestampToString(time.toTime).name);
        availTime = availTime.concat(getShiftAvailTime(time.fromTime, time.toTime, fromTS,toTS))
    }));
    //console.log(availTime)
    //console.log(fromTS, availTime, availTime.map(ts=> timestampToString(ts)))
    availTime = availTime.filter(ts=> ts >= fromTS).concat(availTime.filter(ts=> ts < fromTS))
    return availTime.map(ts=> timestampToString(ts));
}
