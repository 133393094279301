import styled from 'styled-components'
import P from './P'
declare type AProps = {
  height? : number;
}
/*
* Using with as='a'
 */
const A = styled(P)<AProps>` 
  text-decoration: none;
  color: #FF4E3D;
  position: relative;
  :after{
    display: block;
    content: "";
    height: ${props => props.height ? `${props.height}px` : '1px'};
    visibility: hidden;
    position: absolute;
    left: 0;
    width: 0px;
    right: auto;
    background-color: #FF4E3D;
    transition: all .7s ease-out;
  }
  :hover:after{
    visibility: visible;
    width: 100%;
  }
`
export default A;
