

export default function extract(newTheme, { mode, appearance, state }) {
    if (!newTheme[appearance]) {
        return undefined;
    }
    const root = newTheme[appearance];
    return Object.keys(root).reduce((acc, val) => {
        let node = root;
        [val, state, mode].forEach(item => {
            if (!node[item]) {
                return undefined;
            }
            if (typeof node[item] !== 'object') {
                acc[val] = node[item];
                return undefined;
            }
            node = node[item];
            return undefined;
        });
        return acc;
    }, {});
}
