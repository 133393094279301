import styled from 'styled-components';
import {useEffect, useState} from "react";
import {useMeasure} from "react-use";
type BtnProps = {
    selected?: boolean;
}
const Ctn = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 4px;
  border-radius: 500px;
  height: 48px;
  background: #FFFFFF;
  position: relative;
  border: solid 2px;
  `

const Btn = styled.div<BtnProps>`
  background-color: initial;
  padding: 0 26px;
  transition: all 400ms ease 0s;
  text-align: center;
  color: ${props => props.selected?'#FFF':'#333'};
  border-radius: 500px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  `
const SelectedMask = styled.div`
  transition: all 400ms ease 0s;
  z-index: 0;
  background: rgb(0, 0, 0);
  border-radius: 500px;
  position: absolute;
  height: calc(100% - 8px);
  `
type BtnCtnProps = {
    value: string;
    label: string;
    selected?: boolean;
    index: number;
    dimUpdater: (width: number, value: string, index: number) => any;
    onBtnClick: (index: number) => void;
}
const BtnCtn = ({value, label, selected=false, index, dimUpdater, onBtnClick}: BtnCtnProps) => {
    const [ref, { width,}] = useMeasure<HTMLDivElement>();
    useEffect(() => {
        dimUpdater(width, value, index)
    }, [width,]) //just compare str is same instead of decimal object
    return <div ref={ref} style={{ zIndex: 1}}>
        <Btn selected={selected} onClick={() => onBtnClick(index)}>{label}</Btn>
    </div>
}
type SelectorStates = { [key:number]: {width: number, value: string} }
const Selector = ({values, onChangeCallback}:{values:{value: string, label:string}[], onChangeCallback?: (value: string) => any}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [btnData, setBtnData] = useState<SelectorStates>(()=>{
        const state:SelectorStates = {}
        values.forEach((value, index)=>{
            state[index] = {width:0, value: value.value}
        })
        return state
    });
    const btnDimUpdater = (width: number, value: string, index: number) => {
        setBtnData({...btnData, [index]:{width, value}})
    }
    const getTransformX = () => {
        let x = 0;
        for (const [key, value] of Object.entries(btnData)) {
            if(parseInt(key) < selectedIndex) x += value.width
            else break;
        }
        return x
    }
    useEffect(() => {
        // console.log('useEffect', onChangeCallback, btnData[selectedIndex].value)
        onChangeCallback&&onChangeCallback(btnData[selectedIndex].value)
    }, [selectedIndex])
    return (
        <Ctn>
            {values.map(
                (value, index) =>
                   <BtnCtn value={value.value} key={index} index={index} dimUpdater={btnDimUpdater}
                           selected={selectedIndex===index} label={value.label}
                           onBtnClick={setSelectedIndex}
                   />

            )}
            <SelectedMask style={{width: `${btnData[selectedIndex]?.width || 0}px`, transform: `translateX(${getTransformX()}px)`}}/>
        </Ctn>
    )
}

export default Selector;
