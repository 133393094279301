import { PureComponent } from 'react';
import styled, {css} from "styled-components";
import EditorDividerIcon from "@atlaskit/icon/glyph/editor/divider";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';

import {OPTION_BOOLEAN, OPTION_INTEGER} from '../../actions/schemas'
import {CircleButton} from "../styledComponents/buttons/";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import {getLocaleName} from "../../utils";
import {connect} from "react-redux";
import {getIsFullPartnerLoaded, getPartner} from "../../selectors/partnerSelectors";
import * as uiActions from "../../actions/uiActions";
import * as partnerActions from "../../actions/partnerActions";
import * as dishActions from "../../actions/dishActions";
// import * as shoppingActions from "../../actions/shoppingCartActions";
import {editOrderInShoppingCart} from "../../store/shoppingCart/reducer";

const OptionLine = styled.span`
    font-size: 16px;
`;

const ProductTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
`;
const ProductNotes = styled.div`
    font-size: 12px;
    margin-bottom: 12px;
`;

const EditableMarker = styled.span`
  transition: opacity 1s ease;
  margin-left: 8px;
  color: #00875A;
  opacity: ${props => props.isEditable ? 0 : 1};
`

//Single entry in shopping cart

class CartLine extends PureComponent {

  _buildOptions = () => {
    const {
      line: {
        options
      }
    } = this.props;
    return options.map(selectedOption => ({option: selectedOption.option.id, value: selectedOption.value}));
  };

  changeQuantity = (delta) => {
    const {
      line,
      index,
      editOrder
    } = this.props;
    if (delta === 0) {
      // editOrder(index, line.product.id, 0, this._buildOptions())
      editOrder(index, 0);
    } else {
      // editOrder(index, line.product.id, Math.max(0, line.quantity + delta), this._buildOptions())
      editOrder(index, Math.max(0, line.quantity + delta));
    }
  }

  //Calculate Price for an OrderEntry
  calculatePrice = () => {
    const {line} = this.props;
    let plainPrice = parseFloat(line.product.price)

    let optionsPrice = 0
    line.options.forEach((optionEntry) => {
      if (optionEntry.option.type === OPTION_BOOLEAN) {
        optionsPrice = optionsPrice + parseFloat(optionEntry.option.applied_price)
      } else if (optionEntry.option.type === OPTION_INTEGER) {
        optionsPrice = optionsPrice + (parseFloat(optionEntry.option.applied_price) * optionEntry.value)
      }
    })

    return ((plainPrice + optionsPrice) * line.quantity).toFixed(2)
  }

  render() {
    const {
      className,
      line,
      isEditable,
      isCheckout,
      //dish
    } = this.props;
    //console.log(dish);
    if(isCheckout){
      return(
        <div className={className}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '16px 12px',
          }}>
              <span style={{
                marginRight: 8,
                flex: 9,
                maxWidth: '90px',
              }}
              >
            {line.quantity}
            </span>
            <div style={{
              flex: 22,
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left'
            }}>
              <ProductTitle>
                {line.product.product_model &&
                <span style={{marginRight: '10px', color: '#6b6b6b', fontWeight: 400}}>{line.product.product_model}</span>}
                {getLocaleName(line.product,'title')}
              </ProductTitle>
              {
                //render each option
                line.options.map((optionEntry) => {
                  return <OptionLine key={optionEntry.option.id}>{getLocaleName(optionEntry.option)}</OptionLine>
                })
              }
              {line.notes && <ProductNotes>{line.notes}</ProductNotes>}
            </div>
            <div style={{
              flex: 10,
              marginLeft: 8,
              maxWidth: '100px',
            }}>${this.calculatePrice()}
            </div>

          </div>

        </div>
      )
    }
    return (
      <div className={className}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          padding: '16px 20px',
          background: isEditable? 'transparent' : 'lightgray'
        }}>
          { isEditable?
            <div style={{
              display: 'flex',
              width: '90px',
              justifyContent: 'space-around',
              alignItems: 'start',
              marginRight: '15px',
            }}>
              <CircleButton onClick={this.changeQuantity.bind(this, -1)}>
                <EditorDividerIcon size="small"/>
              </CircleButton>
              <span style={{width:20}} className="quantity">{line.quantity}</span>
              <CircleButton onClick={this.changeQuantity.bind(this, 1)}>
                <EditorAddIcon size="small"/>
              </CircleButton>
            </div>:
          <span style={{
            marginRight: '15px',
            width: '90px'
          }}
          >
            {line.quantity}
          </span>}
          <div style={{
            flex: '3 220px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left'
          }}>
            <ProductTitle>
              {line.product.product_model &&
              <span style={{marginRight: '10px', color: '#6b6b6b', fontWeight: 400}}>{line.product.product_model}</span>}
              {getLocaleName(line.product,'title')}
              <EditableMarker isEditable={isEditable}>
                <CheckCircleIcon size="small"/>
              </EditableMarker>
            </ProductTitle>
            {
              //render each option
              line.options.map((optionEntry) => {
                return <OptionLine key={optionEntry.option.id}>{getLocaleName(optionEntry.option)}</OptionLine>
              })
            }
            {line.notes && <ProductNotes>{line.notes}</ProductNotes>}
          </div>
          <div style={{
            flex: '1 100px'
          }}>${this.calculatePrice()}
          </div>

          { isEditable ? <div style={{
            display: 'flex',
            width: '60px',
            justifyContent: 'flex-end',
            alignItems: 'start',
          }}>
            <CircleButton onClick={this.changeQuantity.bind(this, 0)}>
              <CrossCircleIcon size='small' primaryColor="transparent"/>
            </CircleButton>
          </div>:<div style={{width:60}}/>}
        </div>

      </div>
    )
  }

}

const StyledCartLine = styled(CartLine).attrs({
  withDivider: props => props.index > 0
})`
    color: inherit;
    ${
  props => props.withDivider && css`
        &:not(:first-child)::before {
            width: calc(100% - 40px);
            border-top: 1px solid ${props => props.theme.cartLine.divider.background.default};
            content: "";
            display: block;
            margin: 0px auto;
        }
  `}
   ${OptionLine} {
        color: ${props => props.theme.cartLine.option.color.default};
    }

}
`;

StyledCartLine.defaultProps = {
  theme: {
    cartLine:
      {
        divider: {
          background: {
            default: '#EBECF0',
            hover: '#EBECF0',
          },
        },
        option: {
          color: {
            default: 'rgb(80, 95, 121)',
            hover: 'rgb(80, 95, 121)',
          }
        }
      }
  }
};

const mapStateToProps = (state, ownProps) => {
  //console.log( state.entities.partner.dish, ownProps.line.product)
  return {
    menuLanguage: state.ui.menuLanguage,
    //dish: state.entities.partner.dish[ownProps.line.product],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editOrder: function (index,quantity) {
      dispatch(editOrderInShoppingCart(index,quantity))
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(StyledCartLine);
