import ui from './ui'
// import shoppingCart from './shoppingCart'
import productModal from './productModal'
import entities from './entites'
import auth from  '../store/auth/reducer'
// import {reducer as notificationsReducer} from 'reapop';
import Immutable from 'immutable';
// import {
//     combineReducers
// } from 'redux';
import { combineReducers } from '@reduxjs/toolkit'
import shoppingCart from '../store/shoppingCart/reducer'
import {TypedUseSelectorHook, useSelector} from "react-redux";


const initialState = Immutable.fromJS({
    locationBeforeTransitions: null
});

const rootReducer = combineReducers({
    productModal,
    shoppingCart,
    ui,
    auth,
    entities,
    // notifications: notificationsReducer()
});


export function objToMap(obj,field){
    const mapObj = field? obj[field]:obj
    const map = Immutable.Map(mapObj);
    return field? {[field]:map}:map;
}

export default rootReducer;
