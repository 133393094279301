//@flow
// Action types to be dispatched by the user

export const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
export const WEBSOCKET_SEND = 'WEBSOCKET:SEND';
// Action types dispatched by the WebSocket implementation
export const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
export const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET:MESSAGE';

const webSocketMiddleware = () => {
    // Hold a reference to the WebSocket instance in use.
    let webSocket;

    const createWebSocket = (payload) => {
        const webSocket = (payload.webSocket) ? payload.webSocket : WebSocket;
        return new webSocket(payload.url);
    };

    const close = () => {
        if (webSocket) {
            webSocket.close();
            webSocket = null;
        }
    };

    const message = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === 'basket_delete') close();
        return {
            type: WEBSOCKET_MESSAGE,
            payload: {
                timestamp: new Date(),
                data: data.payload,
                action: data.action,
                event,
            }
        }
    }


    const open = (event) => ({
        type: WEBSOCKET_OPEN,
        payload: {
            timestamp: new Date(),
            event
        }
    });

    const closed = (event) => ({
        type: WEBSOCKET_CLOSED,
        payload: {
            timestamp: new Date(),
            event
        }
    });

    return (store) => (next) => (action) => {
        const {
            dispatch
        } = store;
        switch (action.type) {
            // User request to connect
            case WEBSOCKET_CONNECT:
                // Configure the object
                close();
                webSocket = createWebSocket(action.payload);
                // Attach the callbacks
                // Setup handlers to be called like this:
                // 更简单的方法 黑科技 compose + partial：
                // import { compose } from 'redux';
                // import partial from 'lodash/fp/partial';
                // const dispatchAction = partial(compose, [dispatch]);
                // compose(dispatch,open) = event => dispatch(open(event))
                //  dispatchAction(open)  相当于  dispatch(open(event))
                webSocket.onopen = event => dispatch(open(event));
                webSocket.onclose =  event => dispatch(closed(event));
                webSocket.onmessage = event => dispatch(message(event));
                break;

            // User request to send a message - Useless ATM!
            case WEBSOCKET_SEND:
                webSocket.send(JSON.stringify(action.payload));
                break;

            // User request to disconnect
            case WEBSOCKET_DISCONNECT:
                webSocket.close();
                break;

            default: // We don't really need the default but ...
                break;
        }
        next(action);
    }
};

export default webSocketMiddleware();
