// @flow
import { Component } from 'react';
// import Modal from "react-modal";
import isMobile from 'ismobilejs';
import Decimal from 'decimal.js'
import {Record, List, Map, fromJS} from 'immutable'
import {defineMessages, injectIntl} from "react-intl";

import styled from 'styled-components';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorDividerIcon from '@atlaskit/icon/glyph/editor/divider';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';

import OptionGroup, {OptionGroupTitle} from "./OptionGroup";
import * as Schemas from "../../actions/schemas";
import {CircleButton, BarButton} from "../styledComponents/buttons/";
import {getLocaleName} from "../../utils";
import { withTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Modal from '@material-ui/core/Modal'
import Paper from "@material-ui/core/Paper";


const OPTION_WIDTH = 400;
const OPTION_HEIGHT = 600;

const customStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(9, 30, 66, 0.54)',

        zIndex            : 500,
        display           : 'flex',
        alignItems        : 'center',
        justifyContent    : 'center',
    },
    content : {
        position                   : 'relative',
        width: '100%',
        maxWidth: OPTION_WIDTH + 'px',
        height: '100%',
        maxHeight: OPTION_HEIGHT + 'px',
        border                     : 'none',
        background                 : '#fff',
        overflow                   : 'initial',
        overflowX                  : 'initial',
        borderRadius               : '0px',
        outline                    : 'none',
        padding                    : 0,
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        display: 'flex',
    }
}

const Description = styled.p`
     font-size: 16px;
    letter-spacing: 0.14px;
    font-weight: 400;
    color: rgb(80, 95, 121);
    line-height: 1.33;
    white-space: pre-wrap;
`;

const ModalImage = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
`
const defaultState = (product) => new Record({
    //shopping cart basic info
    postLine: Map({
        product: product.id,
        quantity: 1,
        options: List(),
    }),
    basePrice: Decimal(product.price),
    quantity: 1,
    optionGroups: fromJS(product.option_groups.reduce(
        (result, group, idx, arr) => {
        result[group.id] = {selectedOptions: {}, ...group};
        return result
    }, {})),
    notes: '',
})();


class ProductModalDemo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: new Record({
                optionGroups: Map({}),
                basePrice: Decimal(0),
                quantity: 0,
                notes: '',
            })(),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.product.id !== this.props.product.id) {
            this.setState({data:defaultState(this.props.product)})
        }
    }

    getProductTotal = () => {
        const {
           data
        } = this.state;
        const totalAppliedPrice = data.optionGroups.map(group=>
            /* accumulates all additional price of selected options in each group */
            group.get('options')
                .filter(option=> group.get('selectedOptions').has(option.get('id').toString()))
                .update(options => options.reduce((sum, option) => {
                    switch (option.get('type')) {
                    case Schemas.OPTION_INTEGER:
                        return sum.add( Decimal(option.get('applied_price')).mul(
                            group.getIn(['selectedOptions', option.get('id').toString()])))
                    default:
                        return sum.add(option.get('applied_price'))
                }}, Decimal(0)))
        ).update(
            /* accumulates all group total */
            groupTotals => groupTotals.reduce((sum, groupTotal) => sum.add(groupTotal), Decimal(0))
        );
        return data.basePrice.add(totalAppliedPrice).mul(data.quantity)
    };

    validateProduct = () => {
        const {
            data
        } =  this.state;
        return data.optionGroups.filter(group => group.get('required')).reduce((result, group) => {
            return group.get('selectedOptions').size > 0 && result
        }, true)
    };

    changeQuantity = (delta) => {
        const {
            data
        } = this.state;
        this.setState({data: data.set('quantity', data.quantity + delta > 0 ?  data.quantity + delta : 1)})
    };

    onChange= (optionId, optionGroupId, value) => {
        const {
            data
        } = this.state;
        const nextData = data.updateIn(["optionGroups", optionGroupId.toString(), "selectedOptions"], prevSelected => {
            const isRequired = data.optionGroups.getIn([optionGroupId.toString(),'required']);
            const type = data.optionGroups.getIn([optionGroupId.toString(), "type"])
            if(type === Schemas.OPTION_GROUP_SINGLE_SELECT){
                if(!isRequired && prevSelected.get(optionId.toString())){
                    return Map({})
                }
                return Map({[optionId]: value});
            } else if (value === true || value > 0) {
                return prevSelected.set(optionId.toString(), value);
            }else {
                return prevSelected.delete(optionId.toString())
            }
        });
        this.setState({data: nextData })
    };

    onNotesChange = (e) =>{
        const {data} = this.state;

        this.setState({data: data.set('notes', e.target.value)})
    }

    onSubmit = () => {
        const {
            product,
            onAddToCartSubmit
        } = this.props;
        const {
            data,
        } = this.state;
        if( !this.validateProduct()) return;
        const options = data.optionGroups
            .reduce((prevList, group) =>
                prevList.concat(group.get('selectedOptions').mapEntries(([optionId, value]) => ([optionId, {option: parseInt(optionId), value: value}])).toList().toJS()), []);

        onAddToCartSubmit&&onAddToCartSubmit({options, product: product.id, quantity: data.quantity, notes: data.notes,})
    }

    render() {
        const {
            isOpen,
            product,
          theme,
            onCloseProductModal
        } = this.props;
        const {
            data,
        } = this.state;
        const optionsGroupsSelectedOptions = data.optionGroups.map(group=>group.get("selectedOptions"));
        //{...this.props}

        return (
                <Modal
                    // contentRef={node => this.modalNode = node}
                    open={isOpen}
                    ariaHideApp={false}
                    onClose={onCloseProductModal}
                    style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                >
                    <Paper elevation={2}
                           style={{
                               width: '100%',
                               maxWidth: OPTION_WIDTH + 'px',
                               height: '100%',
                               maxHeight: OPTION_HEIGHT + 'px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1 1 auto',
                               position:'relative',
                               outline:'none',
                    }}>

                        <CircleButton style={{position: 'absolute', right: '20px', top: '20px', cursor: 'pointer', zIndex: 1}} onClick={onCloseProductModal}>
                        <EditorCloseIcon size='medium'/>
                        </CircleButton>
                        <div style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            flex: '1 1 auto',
                            padding: '20px 20px',
                        }} ref={ref => this.scrollArea = ref}>


                            <div style={{
                                marginBottom: '40px',
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                    <div style={{
                                        fontSize:'24px',
                                        fontWeight: 400
                                    }}>{getLocaleName(product,'title')}</div>
                                    <Description>{getLocaleName(product,'description')}</Description>
                                {product.image &&
                                <picture>
                                    <source srcSet={`${product.image} 1024w, ${product.image} 768w`}/>
                                    <ModalImage alt={`${product.title}`} src={product.image}/>
                                </picture>
                                }
                            </div>

                            {product.option_groups.map((optionGroup)=>
                                <OptionGroup intl={this.props.intl}
                                             key={`ogk-${optionGroup.id}`}
                                             onChange={this.onChange}
                                             selectedOptions={ optionsGroupsSelectedOptions.get(optionGroup.id.toString())}
                                             {...optionGroup}/>
                            )}
                            <div style={{marginTop: '15px'}}>
                                <div style={{
                                    display: "flex", justifyContent: "space-between",
                                    background: '#EBECF0',
                                    margin: '0 -20px',
                                    padding: '15px 20px'
                                }}>
                                    <OptionGroupTitle>{this.props.intl.formatMessage(productModalTranslation.textNotes)}</OptionGroupTitle>
                                </div>
                                <InputBase
                                    rows={3}
                                    multiline
                                    placeholder={this.props.intl.formatMessage(productModalTranslation.textNotesPlaceholder)}
                                    value={data.notes}
                                    onChange={this.onNotesChange}
                                    style={{
                                        marginTop: '15px',
                                        marginLeft: '10px',
                                        padding: 0,
                                        display: 'flex'
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flex: '0 0 auto',
                            transition:' box-shadow 200ms ease 0s',
                            boxShadow: 'rgb(235, 236, 240) 0px -2px 0px 0px',
                            padding: '14px 20px'
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                flex: '0 0 120px',
                            }}>
                                <CircleButton onClick={this.changeQuantity.bind(this, -1)}>
                                    <EditorDividerIcon size="medium"/>
                                </CircleButton>
                                <span className="quantity">{data.quantity}</span>
                                <CircleButton onClick={this.changeQuantity.bind(this, 1)}>
                                    <EditorAddIcon size="medium"/>
                                </CircleButton>
                            </div>
                            <BarButton
                                    style={{
                                        marginLeft: '32px'
                                    }}
                                    appearance={'primary'}
                                    isDisabled={!this.validateProduct()}
                                    onClick={this.onSubmit}>{this.props.intl.formatMessage(productModalTranslation.buttonAddItem)+
                            this.getProductTotal().toFixed(2)
                            }</BarButton>
                        </div>
                    </Paper>
                </Modal>
        );
    }
}
export const productModalTranslation = defineMessages({
    textItemPrice: {
        id: "productModal.textItemPrice",
        defaultMessage: "ADD ITEM - $",
        description: "text item price",
    },
    buttonAddItem: {
        id: "productModal.buttonAddItem",
        defaultMessage: "Add to Order - $",
        description: "button Add Item",
    },
    textRequired: {
        id: "productModal.optionRequired",
        defaultMessage: "Required",
        description: "text Required",
    },
    textSingleSelect: {
        id: "productModal.textSingleSelect",
        defaultMessage: "Single",
        description: "textSingleSelect",
    },
    textMultiSelect: {
        id: "productModal.textMultiSelect",
        defaultMessage: "Multiple",
        description: "textMultiSelect",
    },
    textOptional: {
        id: "productModal.optionOptional",
        defaultMessage: "Optional",
        description: "text Optional",
    },
    toolTipClose: {
        id: "productModal.toolTipClose",
        defaultMessage: "Close",
        description: "tool Tip Close",
    },
    textNotes: {
        id: "productModal.textNotes",
        defaultMessage: "Extra Instructions",
        description: "textNotes",
    },
    textNotesPlaceholder: {
        id: "productModal.textNotesPlaceholder",
        defaultMessage: "Leave notes for this item",
        description: "textNotesPlaceholder",
    },
})


export default injectIntl(withTheme(ProductModalDemo))
