import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import webSocket from "../middlewares/websocket";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import DevTools from '../containers/devTools'
export type RootState = ReturnType<typeof rootReducer>
let enhancer = applyMiddleware(thunk, webSocket);
if (process.env.NODE_ENV !== 'production') {

  enhancer = compose(
      enhancer,
      DevTools.instrument()
  );
}
function configureStore(initialState?:RootState) {
   const store = createStore(rootReducer, initialState, enhancer);
  if (process.env.NODE_ENV !== 'production' && module.hot) {

      module.hot.accept('../reducers', () =>
          store.replaceReducer(require('../reducers')) // eslint-disable-line global-require
      );
  }
  return store
}
const store = configureStore()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
