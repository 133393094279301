import { createSelector } from 'reselect';
import {Schema} from "../actions/schemas";
import {denormalize} from "normalizr";

// entities selectors
export const getEntities = state => state.entities.partner;

export const getRecommendPartnerLists = state => state.partnerReducer.recommendRestaurants;
export const getRecommendDishLists = state => state.partnerReducer.recommendDishes;
export const getMarkedPartnersLists = state => state.partnerReducer.markedPartners;


export const getRecommendPartners = createSelector(
    getRecommendPartnerLists,
    getEntities,
    (recommendPartnerLists, entities) => (recommendPartnerLists?
        denormalize(recommendPartnerLists, [Schema.partnerSchema], entities):[]),
);

export const getRecommendDishes = createSelector(
    getRecommendDishLists,
    getEntities,
    (recommendDishLists, entities) => (recommendDishLists?
        denormalize(recommendDishLists, [Schema.dishSchema], entities):[]),
);


export const getDish =(dishID)=> createSelector(
    getEntities,
    (entities) => (dishID?
        denormalize(dishID, Schema.dishSchema, entities):{}),
);

export const getMarkPartners = createSelector(
    getMarkedPartnersLists,
    getEntities,
    (markedPartnersLists, entities) => (markedPartnersLists?
        denormalize(markedPartnersLists.toArray(), [Schema.partnerSchema], entities):[]),
);

export const getPartnerId = (state, props) => {
  if(props.extraInfo && props.extraInfo.partnerId){
    return Number(props.extraInfo.partnerId);
  }
  return (props.match && props.match.params && props.match.params.partnerId ? Number(props.match.params.partnerId) : 0);
} //240

export const getIsQR = (state) => {
  return state.auth.isQR
}

export const getPartner = createSelector(
    getPartnerId,
    getEntities,
  getIsQR,
    (id, entities, isQR) => {
      if(id in entities.partners){
        const ret = denormalize(id, Schema.partnerSchema, entities);
        if(isQR){ // already checked is_avail on server side
          return ret;
        }
        ret.categories = ret.categories.filter(cate=> cate.is_online_avail).map((cate=>{
          const rt = {...cate};
          rt.products = rt.products.filter(prod=> prod.is_online_avail);
          return rt;
        })).filter(cate => cate.products.length).sort((cate1,cate2)=>cate1.sort_order - cate2.sort_order)
        return  ret
      }
       return null;
    },
);

export const getIsFullPartnerLoaded = createSelector(
    getPartnerId,
    getEntities,
    (id, entities) => Boolean(id in entities.partners && ((!entities.partners[id].categories || !entities.partners[id].products) ||
      (entities.partners[id].categories.length || entities.partners[id].products.length))), //if product/category is not defined = is loaded but nothing there
);