import ReactDOM from 'react-dom';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Root from './containers/root';
import './globalStyle/index.css';
import 'font-awesome/css/font-awesome.css'
import store from './store/configureStore';
import { IntlProvider } from 'react-intl';
import enLocalization from './l10n/en.json';
import zhLocalization from './l10n/zh.json';
import Cookies from 'universal-cookie';
import {ThemeProvider} from "styled-components";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from  './theme'
import {materialUITheme} from './constants/theme'
import Modal from 'react-modal'
export const cookies = new Cookies();
/*
if (process.env.NODE_ENV === 'production') { //SameSite
  set_cookie('SameSite', 'Lax',{})
} else {
  set_cookie('SameSite', 'None',{})
}*/

document.cookie = 'SameSite=None; Secure';

function loadLocaleData(locale) {
    switch (locale) {
        case 'zh':
            return import('./l10n/zh.json')
        default:
            return import('./compiled-lang/en.json')
    }
}

const ReactIntlWrapper = (message) => {
    let locale = cookies.get('django_language') || 'en';
    // let message = locale === 'zh-hans' ? zhLocalization : enLocalization

    return (
        <IntlProvider locale={locale} messages={message}>
            <ThemeProvider theme={theme}>
                <MuiThemeProvider theme={materialUITheme}>
                    <Root store={store}/>
                </MuiThemeProvider>
            </ThemeProvider>
        </IntlProvider>
    )
}

async function bootstrapApplication(mainDiv) {
    let locale = cookies.get('django_language') || 'en';
    const message = await loadLocaleData(locale)
    ReactDOM.render(ReactIntlWrapper(message), mainDiv)
}

Modal.setAppElement(document.getElementById('root'));

bootstrapApplication(document.getElementById('root'))
// ReactDOM.render(
//     ReactIntlWrapper(),
//     document.getElementById('root')
// );

