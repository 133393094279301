import {createMuiTheme} from "@material-ui/core";

export const themeColor = {
  primary: {
    extremeLight: '#FFE7D6', // 60%
    faintLight: '#FFB4A3', // 40%
    light: '#FF8170', // 20%
    main: '#ff4e3d',
    dark: '#CC1B0A', // 20%
    contrastText: '#fff',
  },
  secondary: {
    extremeLight: '#C9FFFF', // 60%
    faintLight: '#96F6FF', // 40%
    light: '#63C3FA', // 20%

    main: '#3090C7',
    dark: '#005D94', // 20%
    contrastText: '#fff',
  },
};

export const cssTheme = {
  palette: {
    primary: themeColor.primary,
    secondary: themeColor.secondary,
  },
  primary: themeColor.primary, // TODO: delete this
  text: {
    size: {
      large: 36,
      medium: 24,
      small: 16,
      mini: 12,
    },
  },
  background: {
    bar: {
      firstLevel: 'silver',
      secondLevel: 'gainsboro',
      thirdLevel: 'whitesmoke',
      dirty: 'pink',
      notification: '#FFE7D6',
    },
    table: {
      normal: 'greenyellow',
      occupied: 'pink',
    },
    error: 'red',
    selected: 'lightcyan',
  },
  datePicker: {
    selectColor: '#ff4e3d',
  },
  flatButton: {
    primaryTextColor: '#ff4e3d',
  },
  shoppingCart:
    {
      shoppingCartTitle: {
        color: {
          default: '#333',
          // secondary: '#505F79',
        },
      },
      option: {
        color: {
          default: 'rgb(80, 95, 121)',
          hover: 'rgb(80, 95, 121)',
        }
      }
    },
  cartLine:
    {
      divider: {
        background: {
          default: '#EBECF0',
          hover: '#EBECF0',
        },
      },
      option: {
        color: {
          default: 'rgb(80, 95, 121)',
          hover: 'rgb(80, 95, 121)',
        }
      }
    },
  dimension: {
    controlBarHeight: 60,
    actionBarWidth: 180,
    billWidth: 350,
    bottomBorderDisplaced: 60,
    buttonMedium: 64,
    barIconSize: 40,
    menuItem: {
      height: 60,
    },
    keyboardButtonDim: 60,
  },
  spacing: 8,
  /* overrides: {
      MuiExpansionPanelSummary: {
          expanded: {
        margin: 0,
      },
    },
  }, */
};

export const materialUITheme = createMuiTheme(cssTheme);