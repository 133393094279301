/**
 * Created by yueyang on 2017/2/27.
 */
import {Record,Map} from 'immutable'
import {SchemaDefault} from  '../actions/schemas'
import * as Schemas from '../actions/schemas'
import * as dishActions from '../actions/dishActions'

//For keep track state of each option entry
const OptionCheckBox = new Record({
    id : 0,
    type : "",
    quantity : 1,
    additionalPrice : 0.0,
    selected : false,
})

//For keep track state of each option group
const OptionCheckBoxGroup = new Record({
    id : 0,
    type : "",
    required : false,
    selectedNumber : 0,
    optionCheckBoxes : Map({})
})

//state for product modal, only one modal can be shown at a time,
//so it only save the state for currently selected dish
const initialState = new Record({
    dish: SchemaDefault.dishDefault,
    optionCheckBoxGroups: Map({}),
    isOpen: false,
    allowAddOrder: false,
    additionalPrice: 0.0,
    quantity: 1,
})

export default function productModal(state = new initialState(), action){
    switch (action.type) {
        case dishActions.SHOW_PRODUCT_MODAL:
            let temporaryState = new initialState()
            temporaryState = temporaryState.set('dish',action.dish)

            //Build optionCheckBoxGroups
            action.dish.option_groups.map((optionGroup)=> {
                let optionCheckBoxGroup = new OptionCheckBoxGroup({
                    id: optionGroup.id,
                    type: optionGroup.type,
                    required: optionGroup.required,
                    selectedNumber: 0,
                    optionCheckBoxes: new Map({})
                })
                temporaryState = pushCheckBoxGroupToState(temporaryState,optionCheckBoxGroup)

                //Build optionCheckBoxes
                optionGroup.options.map((option)=> {
                    let optionCheckBox = new OptionCheckBox({
                        id: option.id,
                        type: option.type,
                        additionalPrice: parseFloat(option.applied_price),
                        selected: false,
                    })
                    temporaryState = pushToOptionCheckBoxGroupByID(temporaryState,optionGroup.id,optionCheckBox)
                })
            })
            temporaryState = temporaryState.set("allowAddOrder", getAllowAddOrderStatus(temporaryState))
            return temporaryState.set('isOpen', true)

        case dishActions.OPTION_CHECKBOX_CLICKED:
            var optionCheckBoxID = action.optionGroupID
            var optionCheckBoxGroupID = action.optionID
            var checkboxStatus = action.checkboxStatus
            var optionCheckBoxGroup = state.optionCheckBoxGroups.get(optionCheckBoxGroupID.toString())
            var optionCheckBox = state.optionCheckBoxGroups.get(optionCheckBoxGroupID.toString()).optionCheckBoxes.get(optionCheckBoxID.toString())
            var tempState = state

            //First, check the type of its parent group
            switch (optionCheckBoxGroup.type) {
                //Single selection, make the selection number = 1 or 0 in its parent group/
                //un-select the other option in its parent group
                //change the price: minus previous option price, plus new opito price
                case Schemas.OPTION_GROUP_SINGLE_SELECT:
                    if (checkboxStatus) {
                        let tempGroup = optionCheckBoxGroup.set("selectedNumber",1)
                        let additionPriceSum = getAllSelectedOptionAdditionPrice(tempGroup)
                        tempGroup = unSelectAllOptionCheckBoxes(tempGroup)
                        let tempOption = optionCheckBox.set("selected",true)
                        tempState = pushCheckBoxGroupToState(tempState,tempGroup)
                        tempState = pushToOptionCheckBoxGroupByID(tempState,tempGroup.id,tempOption)
                        tempState = tempState.set("additionalPrice", tempState.additionalPrice + optionCheckBox.additionalPrice - additionPriceSum)
                    }
                    else {
                        let tempGroup = optionCheckBoxGroup.set("selectedNumber",0)
                        tempGroup = unSelectAllOptionCheckBoxes(tempGroup)
                        tempState = pushCheckBoxGroupToState(tempState,tempGroup)
                        tempState = tempState.set("additionalPrice", tempState.additionalPrice - optionCheckBox.additionalPrice)
                    }
                    break;

                //Multiple selection, make the selection number +- 1 in its parent group
                //change the price of the state
                case Schemas.OPTION_GROUP_MULTI_SELECT:
                    if (checkboxStatus) {
                        let tempGroup = optionCheckBoxGroup.set("selectedNumber",optionCheckBoxGroup.selectedNumber + 1)
                        let tempOption = optionCheckBox.set("selected",true)
                        tempState = pushCheckBoxGroupToState(tempState,tempGroup)
                        tempState = pushToOptionCheckBoxGroupByID(tempState,tempGroup.id,tempOption)
                        tempState = tempState.set("additionalPrice", tempState.additionalPrice + optionCheckBox.additionalPrice)
                    }
                    else {
                        let tempGroup = optionCheckBoxGroup.set("selectedNumber",optionCheckBoxGroup.selectedNumber - 1)
                        let tempOption = optionCheckBox.set("selected",false)
                        tempState = pushCheckBoxGroupToState(tempState,tempGroup)
                        tempState = pushToOptionCheckBoxGroupByID(tempState,tempGroup.id,tempOption)
                        tempState = tempState.set("additionalPrice", tempState.additionalPrice - optionCheckBox.additionalPrice)
                    }
                    break;
            }
            tempState = tempState.set("allowAddOrder", getAllowAddOrderStatus(tempState))
            return tempState

        case dishActions.INCREMENT_DISH_IN_PRODUCTMODAL:
            return state.set("quantity", state.quantity + 1)

        case dishActions.REDUCE_DISH_IN_PRODUCTMODAL:
            return state.set("quantity", Math.max(state.quantity - 1, 1))

        case dishActions.INCREMENT_OPTION_IN_PRODUCTMODAL:
            var optionCheckBoxGroupID = action.optionCheckBoxGroupID
            var optionCheckBoxID = action.optionCheckBoxID
            var optionCheckBoxGroup = state.optionCheckBoxGroups.get(optionCheckBoxGroupID.toString())
            var tempOption = optionCheckBoxGroup.optionCheckBoxes.get(optionCheckBoxID.toString())
            var tempState = state
            var unitPrice = tempOption.additionalPrice / tempOption.quantity
            //Change option quantity and additional price, the shopping cart addition price
            tempOption = tempOption.set("quantity", tempOption.quantity + 1)
            tempOption = tempOption.set("additionalPrice", unitPrice * tempOption.quantity)
            tempState = pushToOptionCheckBoxGroupByID(tempState, optionCheckBoxGroupID, tempOption)
            //Only change the additional price when user select it
            if(tempOption.selected) {
                tempState = tempState.set("additionalPrice", tempState.additionalPrice + unitPrice)
            }
            return tempState;

        case dishActions.REDUCE_OPTION_IN_PRODUCTMODAL:
            var optionCheckBoxGroupID = action.optionCheckBoxGroupID
            var optionCheckBoxID = action.optionCheckBoxID
            var optionCheckBoxGroup = state.optionCheckBoxGroups.get(optionCheckBoxGroupID.toString())
            var tempOption = optionCheckBoxGroup.optionCheckBoxes.get(optionCheckBoxID.toString())
            var tempState = state
            var unitPrice = tempOption.additionalPrice / tempOption.quantity
            //Change option quantity and additional price, the shopping cart addition price
            let isOne = tempOption.quantity <= 1;
            tempOption = tempOption.set("quantity", Math.max(1,tempOption.quantity - 1))
            tempOption = tempOption.set("additionalPrice", unitPrice * tempOption.quantity)
            tempState = pushToOptionCheckBoxGroupByID(tempState, optionCheckBoxGroupID, tempOption)
            //Only change the additional price when user select it
            if(tempOption.selected&& !isOne) {
                tempState = tempState.set("additionalPrice", tempState.additionalPrice - unitPrice)
            }
            return tempState;

        case dishActions.CLOSE_PRODUCT_MODAL:
            return initialState();

        default:
            return state;
    }
}

/*****************************************************
 * * * * * *  checkbox state functions * * * * * *
 *****************************************************/
//Push a optionCheckBox to optionChechBoxGroup according to ID
function pushToOptionCheckBoxGroupByID(state,optionCheckBoxGroupID,optionCheckBox){
    let optionCheckBoxGroup = state.optionCheckBoxGroups.get(optionCheckBoxGroupID.toString())
    let tempGroup = optionCheckBoxGroup.set("optionCheckBoxes",
                                            optionCheckBoxGroup.optionCheckBoxes.set(optionCheckBox.id.toString(),optionCheckBox))
    let tempState = pushCheckBoxGroupToState(state,tempGroup)
    return tempState
}

//Push a optionCheckBoxGroup to ProductModal state
function pushCheckBoxGroupToState(state,optionCheckBoxGroup){
    return state.set("optionCheckBoxGroups",
                     state.optionCheckBoxGroups.set(optionCheckBoxGroup.id.toString(),optionCheckBoxGroup))
}

function unSelectAllOptionCheckBoxes(optionCheckBoxGroup){
    return optionCheckBoxGroup.set(
        "optionCheckBoxes",
        optionCheckBoxGroup.optionCheckBoxes.map((optionCheckBox)=>{
            return optionCheckBox.set("selected",false)
        }))
}

function getAllSelectedOptionAdditionPrice(optionCheckBoxGroup){
    let additionPriceSum = 0
    optionCheckBoxGroup.optionCheckBoxes.map((optionCheckBox)=>{
        if(optionCheckBox.selected === true){
            additionPriceSum = additionPriceSum + optionCheckBox.additionalPrice
        }
    })
    return additionPriceSum
}

//Check all the required option groups, if one of them do not selected, return false
function getAllowAddOrderStatus(state){
    return state.optionCheckBoxGroups.toArray().every((optionCheckBoxGroup) => {
        if(optionCheckBoxGroup.required && (optionCheckBoxGroup.selectedNumber < 1)){
            return false
        }
        else{
            return true
        }
    })
}