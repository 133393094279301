/**
 * Created by haolunchai on 2017-02-09.
 */
import {Component, createRef} from 'react';
import styled from 'styled-components';
import ShoppingCart from '../../components/shoppingCart/ShoppingCart'
import ProductModal from "../product/ProductModal";
import {generalI18nMsg} from "../../generalI18nMsg";
import ProductCell, {productCardTranslation} from "./ProductCell";
import {formatPhoneNumber, get_cookie, getLocaleName, getParameterByName, sortFunc} from "../../utils";
import {withSpinner} from "../../hoc";
import Error from '@atlaskit/icon/glyph/error';
import {colors} from '@atlaskit/theme';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from "@material-ui/core/Fab";
import ToTopIcon from '@material-ui/icons/Publish'
import {withTheme} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import {getPartner} from "../../selectors/partnerSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import {H1, P, PDiv} from "../styledComponents";
import moment from "moment";
import {ScrollRegister} from "../../widgets/scrollRegister";
import {WEEKDAY_EN} from "../checkout/utils";

// document.documentElement.scrollTop

const H2 = styled.div`
  font-size: 24px;
  margin: 16px 0;
`;

const GridCtnWrapper = styled.div`
  position: relative;
  /*&:before{
        content: '';
        display: block;
        width: 100%;
        margin-bottom: 32px;
        border-top: 1px solid rgba(0, 0, 0, 0.137255);
    }*/
`
const MobileFullGridCtn = styled(Paper)`
    width: calc(100% - 40px);
    box-sizing: content-box;
    text-align: left;
    margin: 0;
    padding: 0 20px;
    position: relative;
    /*@media screen and (max-width: 1080px){
      width: 100%;
      padding: 0;
    }*/
`;


const PartnerBackgroundWrapper = styled.div`
  max-height: 300px;
  background-color: white;
  background-size: cover;
  background-image: url(${props=>props.image});
  background-position: center center;
  position: fixed;
`;

const PartnerLogo = styled.img`
  display: block;
  width: 144px;
  height: 144px;
  padding: 0;
  object-fit: contain;
`

const SpanDivider = styled.span`
  position: relative;
  display: inline-block;
  &:not(:last-child){
    margin-right: 20px;
    &:after{
      position: absolute;
      content: '';
      left: calc(100% + 8px) ;
      width: 3px;
      height: 64%;
      top: 23%;
      background-color: rgb(255, 78, 61);
    }
  }
`
const CategoryCtn = styled.div`
  padding: 16px 0 8px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.274510);
  @media screen and (min-width: 1080px){
  & {
    border-top: none;
  }
}
`
const ProductsCtn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);  
  @media screen and (min-width: 1080px){
    & {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 24px; 
    }
  }
`;

function CChip({isOnlineAvail, intl}){
  return (
    !isOnlineAvail  && <Chip style={{marginLeft: 8, backgroundColor:'red', fontSize: 18, color: 'white'}} label={intl.formatMessage(generalI18nMsg.warningClosed)} />
  )
}

function OpenHourDayTime(weekday, shifts){
  if(!shifts.length){
    return null;
  }
  return (
    <div key={weekday} style={{marginBottom:2}}>
      <span style={{width: 48, display: "inline-block"}}>({WEEKDAY_EN[weekday]})</span>
      {shifts.map((shift, index)=>
        <span style={{width: 160, display: "inline-block"}} key={index}>{moment(shift.from_hour, "HH:mm:ss").format("h:mm A")} - {moment(shift.to_hour, "HH:mm:ss").format("h:mm A")}</span>)}
    </div>)
}

const mapStateToProps = (state, ownProps) => {
  const partner = getPartner(state, ownProps);
  // console.log(partner)
  return {
    isOnlineAvail: partner && partner.is_online_avail,
  }
}

export const ClosedChip =  withRouter(connect(mapStateToProps,null)(injectIntl(CChip)))

class PartnerDetail extends Component {
    state = {
        productModal: undefined,
    }
    socket = null;
    titleRef = createRef();
    titleRefHeight = 0;
    constructor(props) {
      super(props);
      this.scrollReg = new ScrollRegister(this.changeSelection, 150);

    }

    componentWillMount() {
        // const {partners, partnerId, getPartnerById, disableShopCartModal,partnerSelected } = this.props;

        const {isFullPartnerLoaded, getPartnerById,
            match,
            location,
            onPromiseWithLoading
        } = this.props;

        if(isFullPartnerLoaded){
        }
        else onPromiseWithLoading(
            /*Keeping Spinner until getPartnerId returns, */
            ()=> getPartnerById(match.params.partnerId, getParameterByName('token',location.search))
        )
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.updateDimensions);
        //scrollSpy.update(); //Warning: Cannot in here moved to Child Component
        //this.rightMenuCtn&&this.setState({rightMenuMargin: window.getComputedStyle(this.rightMenuCtn).getPropertyValue("margin-left")})
    }
    componentWillUnmount() {
        const {enableShopCartModal,closeProductModal} = this.props
        enableShopCartModal()
        closeProductModal()
        this.socket&&this.socket.close();
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate() {
      if(this.titleRefHeight !== this.titleRef.current.scrollHeight){
        this.titleRefHeight = this.titleRef.current.scrollHeight;
        this.forceUpdate();
      }
    }

    changeSelection= (i) => {
      this.setState({selectedCategory: i});
    }

  handleScroll=()=>{
    }

    // componentDidUpdate(preProps, preState){
    //     console.log('partnerDetail componentDidUpdate', this.state, preState, this.props, preProps)
        /*
        * Let's try connect WS if user is sitting with table
         */
        /*if(partnerSelected&&shoppingCart.table_id&&preProps.shoppingCart.table_id!==shoppingCart.table_id){
            this.socket = setupSocket({
                partnerId:shoppingCart.partner.id,
                tableId: shoppingCart.table_id})
        }*/
    // }

    renderQuickMenu = ()=>{
        const { partnerSelected } = this.props;
        if(!partnerSelected || !partnerSelected.categories || !partnerSelected.categories.length){
            return null;
        }
        return (

          <div style={{width:'100%', display:"flex", alignItems:'center', maxWidth:'1024px', margin: '0 auto'}}>
            {this.titleRef.current && (window.scrollY > this.titleRef.current.scrollHeight) && <ClosedChip/>}
              <Tabs
                value={this.scrollReg.getCurrentScrollIndex()}
                onChange={(e,val)=>{this.scrollReg.scrollToRefTop(val)}}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                  {partnerSelected.categories.sort(sortFunc(true, 'sort_order')).map((category,index)=>
                    <Tab key={index} label={getLocaleName(category)} value={index} style={{fontSize: 20, fontWeight:'bold'}}/>
                  )}
              </Tabs>
          </div>
        )
    }

    renderMenu = () => {
        const { partnerSelected } = this.props
        const shouldRenderCategories = !!partnerSelected && partnerSelected.categories && partnerSelected.categories.length;
        const shouldRenderProducts = !shouldRenderCategories && !!partnerSelected&&partnerSelected.products&&partnerSelected.products.length
        return (
            <div style={{maxWidth: '1024px', margin: '0 auto', paddingBottom:'40px'}}>
                {shouldRenderCategories &&
                partnerSelected.categories.sort(sortFunc(true, 'sort_order')).map((category,index) => this.renderCategory(category,index))}
              {shouldRenderProducts && this.renderProducts(partnerSelected.products)}
            </div>
        )

    }

    onClickHandler = (product) => {
        const {
            shoppingCartPartner,
            closePopup,
            getDishDetailById,
            intl,
            addFlag,
            shoppingCart
        } = this.props;
        if(shoppingCartPartner&&shoppingCartPartner.id.toString() !== this.props.partnerId){
            let info = {
                title: intl.formatMessage(generalI18nMsg.popupTitleWarning),
                message: intl.formatMessage(productCardTranslation.warningNoMultiRestaurant),
                showConfirmButton:true,
                showCancelButton:true,
                confirmTitle:intl.formatMessage(generalI18nMsg.popupButtonContinue),
                cancelTitle:intl.formatMessage(generalI18nMsg.popupButtonCancel),
                confirmAction:()=>{
                    getDishDetailById(product.id)
                    closePopup()
                },
                cancelAction:()=> closePopup(),
            }
            // showPopup(info)
            addFlag({
                description: intl.formatMessage(productCardTranslation.warningNoMultiRestaurant),
                title: intl.formatMessage(generalI18nMsg.popupTitleWarning),
                icon: <Error label="Error icon" secondaryColor={colors.Y300} />,
                appearance: 'warning'
            })
        }
        else if(shoppingCart.status === 'Saved') {
            addFlag({
                description: 'Looks like your already checked out the table, please ask the staff to continue.',
                title: 'Unable to add new dish',
                icon: <Error label="Error icon" secondaryColor={colors.Y300} />,
                appearance: 'warning'
            })
        }
        else {
            getDishDetailById(product.id)
            this.setState({productModal: product})
        }
    }

    renderCategory = (category, index) => {
        if(!category.products || !category.products.length){
            return null;
        }
        return (
            <CategoryCtn id={`c${index}`}
                         key={index}
                         ref={ref => this.scrollReg.registerRef(ref,index)} >
                <H2 style={{marginBottom: '8px', color: '#ff4e3d', fontWeight: 'bold'}}>{getLocaleName(category)}</H2>
                {getLocaleName(category,'description') &&
                <div style={{marginBottom: '8px', marginLeft: 8, color: 'rgb(80,95,121)'}}>
                  <span style={{whiteSpace:'pre-wrap'}}>{getLocaleName(category,'description')}</span>
                </div>}
                <ProductsCtn>
                    {category.products&&category.products.map((product, index) => {
                        return (<ProductCell product={product}
                                             index={index}
                                             key={`product-cell-${product.id}`}
                                             onClickHandler={this.onClickHandler.bind(this, product)}/>)
                    })}
                </ProductsCtn>
            </CategoryCtn>
        )
    };

    renderProducts = (products) => { //render as ONE category
        if(!products || !products.length){
            return null;
        }
        return (
            <div>
                <ProductsCtn>
                    {products&&products.map((product, index) => {
                        return (<ProductCell product={product} index={index}
                                             key={`product-cell-${product.id}`}
                                             onClickHandler={this.onClickHandler.bind(this, product)}
                        />)
                    })}
                </ProductsCtn>
            </div>
        )
    }

    updateDimensions = () => {
        let w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
            height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
        const {enableShopCartModal, disableShopCartModal} = this.props
        // console.log('partnerDetail updateDimensions')
        if(width<1400){
            this.setState({hideCart: true});

            enableShopCartModal()
        }
        else {
            this.setState({hideCart:false})
            //TODO: dispatch ToggleUP(cart)
            disableShopCartModal()
        }
        this.setState({width: width, height: height});
        if(this.rightMenuCtn){
            this.setState({rightMenuMargin: w.getComputedStyle(this.rightMenuCtn).getPropertyValue("margin-left")})
            this.setState({rightMenuWidth: w.getComputedStyle(this.rightMenuCtn).getPropertyValue("width")})
        }
    }

    onAddToCartSubmit = (newLineObj) => {
        const {
            addOrderToShoppingCart,
            closeProductModal
        } = this.props;
        addOrderToShoppingCart(newLineObj)
        closeProductModal()
    };

    handleOpenModal = () => {
        this.setState({ isOpen: true });
    }

    handleCloseModal = () => {
        this.props.closeProductModal()
    }

    renderHours = (open_hours) => {
        const now = new Date()
      // if current time is < 4 am, still count as not passed the day
      const fstDay = (now.getDay() + (now.getHours() < 4 ? -1 : 0) +7)%7
      const secDay = (now.getDay() + (now.getHours() < 4 ? 0 : 1) +7)%7

      return (
        <>
          {OpenHourDayTime(fstDay, open_hours.filter(hours => (hours.weekday === fstDay)))}
          {OpenHourDayTime(secDay, open_hours.filter(hours => (hours.weekday === secDay)))}
        </>
      )
     /*const shiftsOfDay=[]
        return [open_hours.filter(hours => (hours.weekday === now.getDay()) || (hours.weekday === now.getDay() + (now.getHours() < 4 ? -1 : 1))).map((day,index) =>
            <div key={index}>({WEEKDAY_EN[day.weekday]}) {moment(day.from_hour, "HH:mm:ss").format("h:mm A")} -
                {moment(day.to_hour, "HH:mm:ss").format("h:mm A")}</div>
        )]*/
    }
    render() {
        const {
            partnerSelected: partner,
            history,
          theme,
          productModal,
        } = this.props;
        const backgroundImg = partner&& partner.background_image? partner.background_image:null
      /*console.log(1)
      const newCookie = new Cookies('.app.localhost')
      console.log(newCookie.getAll())
      console.log(cookies.get('session', {domain:'.app.localhost'}))
      console.log(2)*/
      //console.log(partner)
      // console.log(this.titleRef && this.titleRef.current && this.titleRef.current.scrollHeight, window.scrollY)
        return (
            <div style={{position: 'absolute', right: 0, top: 0,left:0}}>
                <div style={{position: 'absolute', left: 0, top: 0, width: !this.state.hideCart?'75%':'100%',
                   }}>
                  {/*<PartnerBackgroundWrapper image={backgroundImg}>
                    </PartnerBackgroundWrapper>*/}
                      <MobileFullGridCtn elevation={2} square
                                       style={{/*minHeight: '147px', padding: '16px', */
                                         width: !this.state.hideCart?'75%':'100%',
                                         padding: 0,
                                         display: 'flex',
                                         flexDirection:'column',
                                         position:'fixed', backgroundColor:'white', zIndex:10}} ref={this.titleRef}>
                        <div style={{
                            textAlign: 'left',
                            paddingTop: '20px',
                            // paddingLeft: 20,
                            width: '100%',
                            maxWidth:'1024px', margin: '0 auto',
                            // boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
                            background: '#fff',
                          display: (this.titleRef.current && (window.scrollY > this.titleRef.current.scrollHeight))? 'none': 'flex',
                          //flexDirection:'column',
                           flex:' 1 1 auto',
                            flexWrap: 'wrap'
                        }}>
                            {partner?.logo && <div style={{display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                <PartnerLogo alt="partner-logo" src={partner.logo} /></div>}
                            <div style={{margin: '0 20px'}}>
                            <div style={{textAlign: 'left', marginBottom: 15, minWidth: 240}}>
                                <H1 style={{display:'flex', alignItems:'center'}}>{partner?.name}
                                    <ClosedChip/></H1>
                                <P style={{whiteSpace:'pre-wrap'}}>{partner?.description}</P>
                            </div>
                            <P><SpanDivider>{partner?.address}</SpanDivider>
                                <a style={{  textDecoration: 'none', color: 'inherit'}}
                                   href={`tel:${partner?.phone_number}`}>
                                    <SpanDivider>{partner?.phone_number && formatPhoneNumber(partner?.phone_number)}</SpanDivider></a>
                            </P>
                              <PDiv>
                                <div style={{display: 'flex'}}>
                                  <div>Open Hours: </div>
                                  <div style={{display:'flex', flexDirection:'column', marginLeft: 8}}>
                                    {partner?.open_hours && this.renderHours(partner?.open_hours)}
                                  </div>
                                </div>
                              </PDiv>
                            </div>
                        </div>

                        {this.renderQuickMenu()}
                      </MobileFullGridCtn>
                  <div style={{width:'100%', height: this.titleRef.current ? this.titleRef.current.scrollHeight : 0}}/>
                    <GridCtnWrapper>
                        <MobileFullGridCtn innerRef={(ref) => this.rightMenuCtn = ref}>
                            {this.renderMenu()}

                          <Fab color="primary" style={{
                            bottom: theme.spacing(2),
                            right:!this.state.hideCart?`calc(25% + ${theme.spacing(2)}px)`:theme.spacing(2),
                            position:'fixed',
                          }}
                               disabled={window.scrollY === 0}
                               onClick={()=>window.scrollTo({ top: 0, behavior: 'smooth' })}
                          >
                            <ToTopIcon/>
                          </Fab>
                        </MobileFullGridCtn>
                    </GridCtnWrapper>
                  <ProductModal product={productModal.dish}
                                  onAddToCartSubmit={this.onAddToCartSubmit}
                                  isOpen={productModal.isOpen}
                                  onCloseProductModal={this.handleCloseModal}
                                  // shouldCloseOnOverlayClick={true}

                    />

                </div>
                { !this.state.hideCart&&(<div style={{position: 'fixed', right: 0, top: '70px', width: '25%', bottom: '0px', zIndex: 11}}>
                    <ShoppingCart fullWidth location={this.props.location} history={history}/>
                </div>)
                }

            </div>
        )
    }
}



export default withSpinner(withTheme(PartnerDetail));
