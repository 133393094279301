import merge from 'lodash.merge';

const initialState = {
    partner: {
        dish: {},
        partners: {},
        users: {},
        categories: {},
        optionGroups: {},
        options: {},
    },
    orders:{
        ordersMap:{},
    }
};

export default function entities(state = initialState, action) {
    if (action.entities&&action.entities.partner) {
        return Object.assign({},state, {partner: merge({}, state.partner, action.entities.partner)});
    }else if (action.entities&&action.entities.orders) {
        return Object.assign({},state, {orders: merge({}, state.orders, action.entities.orders)});

    }
    return state;
}
