import {injectIntl, defineMessages} from 'react-intl';
import styled from "styled-components";
import {getLocaleName} from "../../utils";


const imageMinDimension = 100;

const MediaDiv = styled.div.attrs(props=>({
    // height:  props.image? '220px': '0px',
    // width: props.image? '220px': '0px',
    dimension: props.image? 150 : 0,
    display: props.image? 'flex': 'none',
}))`
  height: ${props => props.dimension}px;
  width: ${props => props.dimension}px;
  min-width: ${props => props.dimension}px;
  display: ${props => props.display};
  background: ${props => `url(${props.image})`} no-repeat center;
  flex:0;
  background-size: cover;
  // margin-top: 16px;
  @media screen and (min-width: 1080px){
    & {
        margin-top: 0;
    }
  }
`;
const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
  
  @media screen and (min-width: 1080px){
    & {
        padding: 20px;
    }
  }
  
  & h3 {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin:  5px 5px 5px 0;
  -webkit-box-orient: vertical;
  }
  & p {
  color: rgb(80,95,121);
  margin: 0 0 5px;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }
  & div{
  display: flex;
  flex: 1;
  align-items: center;
  }
`;

// Used when migrate to Next.js or other Typescript:
// declare type ProductCardProps = {
//     product: Product;
//     onClickHandler: (product: Product) => any
//     className: string
// }
const ProductCard = ({className, product, onClickHandler}) => {
    return (
        <div className={className} onClick={onClickHandler}>
            <ContentDiv>
                <div>
                    <h3>{product.product_model &&
                    <span style={{marginRight: '10px', color: '#6b6b6b', fontWeight: 400}}>{product.product_model}</span>}{getLocaleName(product,'title')}
                    </h3>
                    {product.endorsement && product.endorsement.map(endorse=>
                        <img key={endorse.name} alt={`${endorse.name}-img`} height="16" width="16" style={{marginLeft: 2, marginRight:2}} src={endorse.url}/>)}
                </div>
                <p style={{whiteSpace:'pre-wrap'}}>{getLocaleName(product,'description')}</p>
                <div> {`$${product.price}`}</div>
            </ContentDiv>
            <MediaDiv image={product.image}/>
        </div>
    )
};

const StyledProductCard = styled(ProductCard)`
  display: flex;
  font-size: 16px;
  flex-direction: row;
  cursor: pointer;
  padding: 8px;
  &:not(:first-child){
    border-top: 1px solid rgba(217, 219, 224, 0.5);
  }
  &:hover ${ContentDiv} {
    background: rgba(0,0,0,0.137);
    box-shadow : rgba(9, 30, 66, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  }
  
  @media screen and (min-width: 1080px){
    & {
         border: 1px solid rgba(0,0,0,0.2);
    }
  }
`

export default injectIntl(StyledProductCard)

export const productCardTranslation = defineMessages({
    warningNoMultiRestaurant: {
        id: "productCard.warningNoMultiRestaurant",
        defaultMessage: "Sorry, we don't support shopping in multiple restaurants at same time. If you still want to add new item, your shopping cart will be cleared!",
        description: "warning Not support Multi Restaurant",

    }
})
