import { PureComponent } from 'react';
import styled from 'styled-components';
import {Redirect} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withSpinner} from "../../hoc";
import queryString from 'query-string'
import {authQRCode} from "../../store/auth/reducer";

class QRAuth extends PureComponent {

  componentWillMount() {
    const {
      getPartnerById,
      onPromiseWithLoading,
      match,
      authQRCode
    } = this.props;
    const urlParams = queryString.parse(this.props.location.search);
    // console.log(urlParams)
    onPromiseWithLoading(()=>authQRCode(urlParams.qr_token), true)
  }

  render() {
    const {
      isQR,
      match,
    } = this.props;
    // console.log(isQR)
    return(
      isQR&&<Redirect to={{
        pathname: `/${match.params.partnerId}/`,
      }}
      />
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    isQR: state.auth.isQR,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getPartnerById: (partnerId) => dispatch(partnerActions.fetchPartnersById(partnerId)),
    authQRCode: (qr_code) => dispatch(authQRCode(qr_code))
  }
};
/*
* Simple Compo don't want to separate files for its Container.
 */
export default connect(mapStateToProps, mapDispatchToProps)(withSpinner(QRAuth));