import styled from 'styled-components';
import InputReset from "./InputReset";
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox';

const defaultTheme = {
    checkBox:{
        background: {
            default: "#fff",
            hover: "#333",
            active: "#333",
            disabled: "#333",
            selected: "#333",
        },
        size: {
            medium: '24px',
            small: '12px'
        },
        padding: {
            medium: '3px',
            small: '2px'
        }
    }

};

const DefaultController = styled.div.attrs({
    size: props=> props.size? props.theme.checkBox.size[props.size] : props.theme.checkBox.size.medium,
    color: props=> props.checked? props.theme.checkBox.background.selected:props.theme.checkBox.background.default
})`
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    background-color: ${props => props.color};
    border-radius: 4px;
    height:  ${props=>props.size};
    width:  ${props=>props.size};
    border-style: solid;
    border-width: 1px;
    border-color:  ${props=> props.checked?props.theme.checkBox.background.selected:'#8e8e8e'} ;
}
`;
const CheckBoxSkeleton = (props) => {
    const {
        className,
        checked,
        theme,
        ...restProps
    } = props;
    return(<div className={className}>
        <InputReset type="checkbox" checked={checked} {...restProps}/>
        <DefaultController checked={checked} theme={theme}>
            {checked ? <CheckboxIcon /> : <div style={{width:24, height:24, padding:3}}/>}
        </DefaultController>
    </div>)
}
const CheckBox = styled(CheckBoxSkeleton)`
    box-sizing: border-box;
    position: relative;
`;

CheckBox.defaultProps = {
    theme: defaultTheme
};
export default CheckBox;