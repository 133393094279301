import styled from "styled-components";

export const OptionLine = styled.div`
    margin-top: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
`;

export const OptionNameSpan = styled.span`
    flex: 1 1 auto;
    display: flex;
    font-size: 14px;
    margin-left: 15px;
`;

export const OptionQuantitySpan = styled.span`
    margin-left: 15px;
    margin-right: 15px;
    line-height: 1.5;
    display: inline;
`;