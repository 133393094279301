/**
 * Created by haolunchai on 2017-01-03.
 */
/**
 * Created by Carrycat on 2016-10-17.
 */
import { Component } from 'react';
import debounce from 'lodash/debounce'
import styled from 'styled-components';
import isMobile from 'ismobilejs'
import EmojiFoodIcon from '@atlaskit/icon/glyph/emoji/food';
import Badge from '@atlaskit/badge';
import { get_cookie, set_cookie } from '../../utils'
import {injectIntl, defineMessages} from 'react-intl';
import Popup from "@atlaskit/popup";
import Button from "@atlaskit/button/dist/cjs/components/Button";

const H1 = styled.h1`
  font-size: 40px;
  margin: 0;
  padding: 0 0 0 24px;
  line-height: normal;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff4e3d;
  font-weight: bold;
`;

const FlexCenteredRow = styled.div`
  transform: translateZ(0px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`
const StyledNav = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
   max-height: 70px;
   min-height: 70px;
  text-align: center;
  z-index: 450;
  background: white;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
`

const MainCtn = styled.div`
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1350px;
    @media screen and (min-width: 1080px){
        & {
            max-width: 1430px;
        }
    }
    @media screen and (min-width: 768px){
        & {
            padding-left: 50px;
            padding-right: 50px;
            max-width: 1390px;
        }
    }
`;

const NavCtn = styled(MainCtn)`
   max-height: 70px;
   min-height: 70px;
   display: flex;
   flex-flow: row-reverse;
   position: absolute;
   top:0;
   right:0;
`;
const NavItem = styled.li`
    transform: translateZ(0px);
    position: relative;
    display: flex;
   
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    transition: .5s color cubic-bezier(.77, 0, .175, 1);
    font-size: 15px;
    font-weight: 700;
    line-height: 11px;
    color: ${props => props.selected? props.theme.header.navItem.color.selected:props.theme.header.navItem.color.default};
    cursor: ${props => props.selected? 'default':'pointer'};
    
    &:hover {
      color: ${props => props.theme.header.navItem.color.hover};
    }
`;
NavItem.defaultProps = {
    theme: {
        header:
            {
                navItem: {
                    color: {
                        default: '#505F79',
                        hover: '#333',
                        selected: '#333',
                    },
                },
                option: {
                    color: {
                        default: 'rgb(80, 95, 121)',
                        hover: 'rgb(80, 95, 121)',
                    }
                }
            }
    }
}

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile.any,
            drawerOpen: false
        };

        this.onResize = debounce(this.onResize, 750)
    }

    componentWillUnmount() {
        !isMobile.any&&window.removeEventListener("resize", this.onResize)

    }

    componentDidMount(){
        this.onResize()
        !isMobile.any&&window.addEventListener("resize", this.onResize)
        if(this.linkCtn) {
            this.props.initAccountIconId(this.linkCtn.id)
        }
        this.bannerHeight = 80
        const {changeMenuLanguage} = this.props;
        let currentLocale = get_cookie('django_language') || 'en'
        let availIndex = currentLocale === 'zh-hans' ? 1 : 0;
        let abbr = currentLocale === 'zh-hans' ? '简' : 'En';
        changeMenuLanguage(availIndex, abbr)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {partnerSelected:oldPartner} = this.props;
        const {partnerSelected:newPartner, changeMenuLanguage, menuLanguage} = nextProps;
       // console.log(newPartner)
        let currentLocale = get_cookie('django_language') || 'en'
        // console.log(currentLocale);
        // console.log(menuLanguage)
        let availIndex = 0;
        let abbr = currentLocale === 'zh-hans' ? '简' : 'En';
        if(newPartner && newPartner.id && ((oldPartner && oldPartner.id && oldPartner.id !== newPartner.id) || !oldPartner)){
            const {avail_language} = newPartner;
            availIndex = avail_language.findIndex(lang=> lang.locale === currentLocale);
            // console.log(availIndex)
            if(availIndex === -1){
                availIndex = 0;
            } else {
                abbr = avail_language[availIndex].abbr
            }
            if(!(menuLanguage.index === availIndex && menuLanguage.abbr === abbr)){

                // console.log(availIndex,abbr)
                changeMenuLanguage(availIndex, abbr)

            }
        } else if (!newPartner){
            if(!(menuLanguage.index === availIndex && menuLanguage.abbr === abbr)){

                // console.log(availIndex,abbr)
                changeMenuLanguage(availIndex, abbr)

            }
        }
    }

    onResize = () => {
        let w = window,
            d = document,
            documentElement = d.documentElement, body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth
        width<480!==this.state.isMobile&&this.setState({isMobile:width<480})
    }

    getNavStyles = () => {
        const { currentUser, uiNavBar,shouldShowBanner} = this.props
        let isFixed = uiNavBar.get('fixed')
        var styles = {
            default:{
                left: 0,
                right:0,
                top: 0,
                zIndex: 4500,
                background: 'transparent',
                color: '#fff',
            },
            fixed:{
                color: '#fff',
                position: 'fixed',
                background: 'rgba(10,120,185, 1)',
                zIndex: 4500,
                boxShadow: 'rgba(57, 70, 78, 0.2) 0px 4px 1px -2px',
                left: 0
            },
            fixedHome:{
                background: 'rgba(10,120,185, 0.85)',
            },
            modal: {
                background:'rgba(221, 65, 50, 0)'
            }
        }

        return Object.assign(
            {},
            styles.default,
            isFixed && styles.fixed,
            this.state.isMobile && styles.fixed
        );
    }

    _onClickAccountLink = () => {
        let rect = this.linkCtn.getBoundingClientRect()
        const {onToggle} = this.props
        onToggle&&onToggle({bottom: rect.bottom, right: rect.right},'account')
    }

    _onClickDrawerAccountLink = () => {

        const {onToggle} = this.props
        this.setState({drawerOpen: false})
        onToggle&&onToggle({bottom: 44, right: 0},'account')
    }

    _onClickShopCartLink = () => {
        const {onToggle, uiModal, uiNavBar} = this.props
        if(!uiNavBar.get('enableShopCartBtn')) return //happens on paymentPage and full PartnerDetailPage
        // uiModal.get('type')==='account'&&router.push(location.state.returnTo); //TODO：[TOGGLE_UP] never trigger this since ui modal reset
        onToggle&&onToggle({bottom: 70},'cart')
    }

    _logout = () => {
        this.props.logout();
        this.props.history.push('/');
    }

    /*_onClickChangeLanguage = () => {
        let currentLocale = get_cookie('django_language') || 'en'
        set_cookie('django_language', currentLocale === 'en' ? 'zh-hans' : 'en', { path: '/',});
        window.location.reload();
    }*/

  onChangeLanguage = (lang) => {
    //let currentLocale = get_cookie('django_language') || 'en'
      let currentLocale = get_cookie('django_language') || 'en'
      if(currentLocale !== lang.locale){
          set_cookie('django_language', (lang.locale || 'en'), { path: '/',});
          window.location.reload();
      }
  }

    renderLanguagePopup = ()=>{
        const {isMenuOpen} = this.state;
        const {partnerSelected, menuLanguage, } = this.props;
        const avail_language = partnerSelected? partnerSelected.avail_language : [];
        let list = avail_language.map((lang, index)=>({name:lang.name,value:`${index}`,abbr:lang.abbr, locale:lang.locale}));
        if(!avail_language.find(lang=> lang.locale === 'en')){
            list.push({name:'English', value: `0`, locale: 'en', abbr: 'En'})
        }
        if(!avail_language.find(lang=> lang.locale === 'zh-hans')){
            list.push({name:'简体中文',value: `0`, locale:'zh-hans', abbr: '简'})
        }
        return (
          <Popup
            isOpen={isMenuOpen}
            onClose={() => this.setState({isMenuOpen:false})}
            placement="bottom-end"
            zIndex={451}
            offset={"0,0"}
            content={() => (
              <div style={{backgroundColor:'whitesmoke', border:'1px solid grey'}}>
                  {list && list.map(lang=>(
                    <div key={lang.abbr} style={{backgroundColor:(lang.abbr === menuLanguage.abbr? 'grey':"transparent"), cursor:'default', padding: 4}} onClick={()=>{this.onChangeLanguage(lang);this.setState({isMenuOpen:false})}}>
                        {lang.name}
                  </div>))}
              </div>
            )}
            trigger={triggerProps => (
              <Button
                {...triggerProps}
                isSelected={isMenuOpen}
                onClick={() => this.setState({isMenuOpen:!isMenuOpen})}
                style={{fontWeight:'bold', borderRadius: 4, border: '1px solid grey', padding: '0 2px'}}
              >
                  {menuLanguage.abbr}
              </Button>
            )}
          />
        );
    }

    render() {
        const { currentUser, uiNavBar, uiModal, location, intl, isQR, shoppingCartId, shoppingCartQuantity, tableNum} = this.props
        let isFixed = uiNavBar.get('fixed'),
            isToggledCart = uiModal.get('type') === 'cart' && uiModal.get('isTogged'),
            isCartEnabled = uiNavBar.get('enableShopCartBtn')
        const linkCx = isFixed?'fixed':''
        const toggledCart = isToggledCart?'active':''
        const disableCart = !isCartEnabled?'disabled':''
        console.log(isQR, shoppingCartId)
        return(
                <StyledNav className={(this.state.isMobile||isFixed)&&'fixed'}>
                    <H1>{' '}</H1>
                    <NavCtn>
                     <ul style={{
                         maxWidth: '170px',
                         display: 'flex',
                         justifyContent: 'space-between',
                         alignItems: 'center'
                     }}>

                         {isQR && tableNum &&
                         <NavItem selected={isToggledCart||!isCartEnabled} onClick={this._onClickShopCartLink}
                                  style={{minWidth:'50px'}}
                         >

                         <Badge appearance={{ backgroundColor: '#333', textColor: '#fff' }}
                         >Table: {tableNum}</Badge>
                         </NavItem>
                         }
                        <NavItem selected={isToggledCart||!isCartEnabled} onClick={this._onClickShopCartLink}
                                 style={{minWidth:'50px'}}
                        >
                                <EmojiFoodIcon size="medium"/>
                                <Badge appearance={{ backgroundColor: '#333', textColor: '#fff' }}
                                >{shoppingCartQuantity}</Badge>
                        </NavItem>
<NavItem>
    {this.renderLanguagePopup()}
</NavItem>
                         {/*<NavItem onClick={this._onClickChangeLanguage} ref={(button) => (this.localeButton = button)}>
                            {intl.formatMessage(navigationBarPageTranslation.labelChangeLocale)}
                        </NavItem>*/}
                    </ul>
                    </NavCtn>
                </StyledNav>

        )
    }
}




export default injectIntl(Nav)

export const navigationBarPageTranslation = defineMessages({
    labelChangeLocale: {
        id: "navigationBar.labelChangeLocale",
        defaultMessage: "中",
        description: "label change locale",
    },
    labelExplore: {
        id: "navigationBar.labelExplore",
        defaultMessage: "Explore",
        description: "label Explore",
    },
    labelDashboard: {
        id: "navigationBar.labelDashboard",
        defaultMessage: "Dashboard",
        description: "label Dashboard",
    },
    labelAdmin: {
        id: "navigationBar.labelAdmin",
        defaultMessage: "Admin",
        description: "label Admin",
    },
})

/*
*{this.state.isMobile&&(
                        <Tabs style={{
                            height: '44px',

                        }} inkBarStyle={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                        }} tabItemContainerStyle={{
                            boxShadow: 'rgba(57, 70, 78, 0.2) 0px 4px 1px -2px',
                            backgroundColor: 'rgba(10, 120, 185, 0.85)',
                        }} initialSelectedIndex={-1}>
                            <Tab disableTouchRipple buttonStyle={{height: '44px'}} icon={<i className="fa fa-user fa-lg" aria-hidden="true"/>} />
                            <Tab disableTouchRipple buttonStyle={{height: '44px'}} icon={<i className="fa fa-shopping-cart fa-lg" aria-hidden="true"/>} />
                            <Tab disableTouchRipple buttonStyle={{height: '44px'}} icon={<i className="fa fa-list fa-lg" aria-hidden="true"/>} />
                        </Tabs>
                    )}



                    {this.state.isMobile&&(
                        <Drawer
                            docked={false}
                            openSecondary
                            width={'80%'}
                            open={this.state.drawerOpen}
                            onRequestChange={(drawerOpen) => this.setState({drawerOpen})}
                            containerStyle={{
                                paddingTop: "33px",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <DrawerSection  style={{
                                flex: "1 1 auto"
                            }}>
                                {currentUser.id?
                                    <div>
                                    <DrawerHead>Hi, {currentUser.fullName}</DrawerHead>
                                    <a onClick={this._logout}>Logout</a>
                                    <DrawerNavLink activeClassName="active"
                                                   to={{pathname: `/account/`,
                                                       state: { returnTo: this.props.location.pathname,type:'account' }}}
                                                   onClick={()=>this.setState({drawerOpen: false})}
                                        >My Account</DrawerNavLink>
                                    </div>:
                                    <div>
                                        <DrawerHead>Account</DrawerHead>
                                        <DrawerNavLink activeClassName="active"
                                              to={{
                                                  pathname: "/account/login/",
                                                  state: { modal: true,
                                                      returnTo: location.state&&location.state.modal?location.state.returnTo:this.props.location.pathname,
                                                      type:'login',
                                                  }
                                              }}
                                              onClick={this._onClickDrawerAccountLink}

                                        >Login
                                        </DrawerNavLink>
                                    </div>
                                }

                            </DrawerSection>
                            <Divider />
                            <Divider />
                            <DrawerSection  style={{
                                flex: "0 0 120px"
                            }}>
                                THIS IS ONLY DEMO
                            </DrawerSection>
                        </Drawer>
                    )}


 */
