/**
 * Created by Carrycat on 2016-12-20.
 */
let schema = 'http', schema_ws = 'ws'
if(window.location.protocol === 'https:'){
    schema = 'https'
    schema_ws = 'wss'
}

export const SOCKET_ROOT = schema_ws+'://api.cross-order.acrossor.com/online/';
export const API_ROOT = schema+'://api.cross-order.acrossor.com/online/';
export const COOKIES_DOMAIN = '.acrossor.com';
//Helper
export const CSRF_TOKEN_KEY = '_csrftoken'


export const GOOGLE_API_KEY = 'AIzaSyCeI28emFLHeUzeWzlfYPDC9pigD893AiE';

export function parseResponse (response) {
    const bodilessStatusCodes = [204, 205, 304];
    if (bodilessStatusCodes.includes(response.status)) {
        return {};
    }
    let json = response.json();
    if (response.status >= 200 && response.status < 300) {
        return json;
    } else {
        return json.then(err => Promise.reject(err));
    }
}
