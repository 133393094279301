import { PureComponent } from "react";
import CheckBox from "../form/CheckBox";
import AdditionalPrice from "./AdditionalPriceLabel";
import {OptionLine, OptionNameSpan} from "./OptionLine";


class CheckBoxOptionLine extends PureComponent {

    onChecked = (event) => {
        const {
            onChange,
            optionGroupId,
            id,
        } = this.props;
        onChange(id, optionGroupId,event.target.checked)
    };
    render(){
        const {
            id,
            name,
            appliedPrice,
            checked
        } = this.props;

        return(
            <OptionLine>
                <CheckBox checked={checked} id={id} onChange={this.onChecked} value="on" name={`${id}`}/>
                <label className="name" style={{cursor:'pointer', flex: '1 1 auto', display: 'flex'}} htmlFor={id}>
                    <OptionNameSpan>{name}</OptionNameSpan>
                    {(parseFloat(appliedPrice)>0)&&<AdditionalPrice price={appliedPrice}/>}
                </label>

            </OptionLine>
        )
    }

}
export default CheckBoxOptionLine