import styled from "styled-components";
import {useState, useEffect} from "react";
import TextField from '@atlaskit/textfield';

import Decimal from 'decimal.js'


const TipsSelector = styled.div`
  background: ${props => props.isSelected? 'black': 'white'};
  color:  ${props => props.isSelected? 'white': 'black'};
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.275);
  flex: 1;
  &:not(:last-child){
  border-right-color: transparent;
  }

  div:first-child {
    font-size: 16px;
  }
  div:last-child {
    font-size: 14px;
  }
`;
// Used when Typescript:
// declare type tipSelector =
// declare type tipState = {
//     tipSelector: 0.1 | 0.15 | 0.18 | 'custom';;
//     amount: Decimal;
//     customTipError: string;
//     customAmount: string;
// }
// declare type TipsSectionProps = {
//     baseTotal: Decimal;
//     parentSetTip: (tip: Decimal) => any
// }
const TipsSection = ({baseTotal, parentSetTip }) => {
    const DBaseTotal = Decimal(baseTotal)
    const [tip, setTip] = useState({
        amount: DBaseTotal.mul(.1),
        tipSelector: .1,
        customTipError: null,
        customAmount: null
    });
    const tipStr = tip.amount.toFixed(2)
    useEffect(() => {
        parentSetTip&&parentSetTip(tip.amount)
        // console.log('TipsSection Endpoint callback:',tip.amount)
    },[tipStr]) //just compare str is same instead of decimal object
    useEffect(() => {
        let tipSelector = tip.tipSelector
        if(tip.tipSelector === 'custom') tipSelector = 0.1
        setTip({amount: Decimal(baseTotal).mul(tipSelector),
            tipSelector: tipSelector,
            customTipError: null,
            customAmount: null});
    }, [baseTotal]); //just compare str is same instead of decimal object
    const onTipSelect = (tipSelector) => {
        if(tipSelector === 'custom') setTip({...tip, tipSelector});
        else setTip({tipSelector, amount: DBaseTotal.mul(tipSelector), customTipError: null, customAmount: null });
    };
    const onCustomChange = (e) => {
        const isDecimal = /^\d*\.?\d{0,2}$/.test(e.target.value);
        console.log('DEBUG: onCustomChange: ', e.target.value,)
        // case1: user reset inputField
        if(e.target.value === '') setTip({...tip, customTipError: null, customAmount: ''})
        // case2: valid input, update input value & clean errors
        else if(isDecimal && DBaseTotal.comparedTo(Decimal(e.target.value))>-1) setTip({...tip, customTipError: null, customAmount: e.target.value})
        // case3 invalid tips amount, shows error not update input value
        else if(isDecimal || tip.customAmount === null) setTip({...tip, customTipError: `please enter tip between $0.00 and $${baseTotal}`})
    }
    const onCustomBlur = (e) => {
        // console.log('DEBUG: onCustomBlur: ', e.target.value)
        // TODO:
        //  UX Improvement: consider to clear errors when off focus:
        //  if (!!tip.customAmount) setTip({...tip, amount:Decimal(e.target.value)}, customTipError: null)
        const amount = tip.customAmount !== '' ? tip.customAmount:'0.00'
        if (tip.customTipError === null) setTip({...tip, amount: Decimal(amount)})
    }
    console.debug('Optimization Monitor Warning: Rendering TipsSection, expected 1 time(s) while parent rerender')
    return (
        <div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '16px 12px',
        }}>
            <TipsSelector
                isSelected={0.1===tip.tipSelector}
                onClick={e=>onTipSelect( 0.1)}
            >
                <div>
                    10%
                </div>
                <div>
                    ${DBaseTotal.mul(.1).toFixed(2)}
                </div>
            </TipsSelector>
            <TipsSelector
                isSelected={0.15===tip.tipSelector}
                onClick={e=>onTipSelect( .15)}>
                <div>
                    15%
                </div>
                <div>
                    ${DBaseTotal.mul(.15).toFixed(2)}
                </div>
            </TipsSelector>
            <TipsSelector
                isSelected={0.18===tip.tipSelector}
                onClick={onTipSelect.bind(this,.18)}
            >
                <div>
                    18%
                </div>
                <div>
                    ${DBaseTotal.mul(.18).toFixed(2)}
                </div>
            </TipsSelector>
            <TipsSelector
                isSelected={'custom'===tip.tipSelector}
                onClick={onTipSelect.bind(this,'custom')}
            >
                <div>Custom</div>
            </TipsSelector>
        </div>
            <div style={{
                margin: '0 16px',
                display: tip.tipSelector === 'custom'? 'block': 'none'
            }}>
            <TextField
                type="text" name="custom_tip"
                value={tip.customAmount !== null?tip.customAmount: tip.amount.toFixed(2)}
                onBlur={onCustomBlur}
                onChange={onCustomChange}
            />
            {!!tip.customTipError &&
                <label style={{color: 'red'}}>
                    {tip.customTipError}
                </label>}
            </div>
            </div>
    )
}
export default TipsSection
