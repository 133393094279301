/**
 * Created by yueyang on 2017/2/10.
 */
// @flow
import { Component } from 'react';
import styled from 'styled-components';
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import Spinner from "@atlaskit/spinner";
import {injectIntl, FormattedMessage, defineMessages} from 'react-intl';

import CartLine from "./CartLine";
import {BarButton} from "../styledComponents/buttons/";
import {generalI18nMsg} from "../../generalI18nMsg";
// import * as shoppingActions from "../../actions/shoppingCartActions";
import {fetchShoppingCart, submitQRShoppingCart} from "../../store/shoppingCart/reducer"

// import * as notificationAction from "../../actions/notificationActions";
import * as uiAction from "../../actions/uiActions";
import {connect} from "react-redux";
import { withTheme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
// import {checkoutTranslation} from "../checkout/CheckOutPage";
import {ClosedChip} from "../partner/PartnerDetail";
import {getPartner} from "../../selectors/partnerSelectors";
import emptyPng from "./empty_illus.png"
import {P} from "../styledComponents";
import {selectCartPartner} from "../../pages/Checkout";
import {isNowAvailable} from "../checkout/utils";
export function SummarySection({subTotal, quantity, style, calcResult, tips }){
    const calc = calcResult || {};
    const subtotal = subTotal;
    const {distKM, delivery_fee, tax, total_tax} = calc;
    const grandTotal = subtotal.add(total_tax || 0).add(delivery_fee || 0).add(tips || 0);
    return(
      <div style={style}>
        <StyledPrice
          label={<FormattedMessage {...shoppingCartTranslation.textItems} values={{ itemCount: quantity}}/>}
          price={subtotal}
        />
        {distKM &&
          <StyledPrice label={<FormattedMessage {...shoppingCartTranslation.deliveryFeeLabel} values={{ dist: distKM}}/>}
                       price={delivery_fee} />}
          {/*<StyledPrice label={<><FormattedMessage {...shoppingCartTranslation.taxLabel}/> :</>}
    price={tax}
    />*/}
          {tips && <StyledPrice label={<><FormattedMessage {...shoppingCartTranslation.textTips}/> : </>}
                       price={tips}
          />}

          {tax && Object.keys(tax).map((tKey=>(
            <StyledPrice
              key={tKey}
            label={tKey}
            price={tax[tKey]}
            />
          )))}
    <StyledPrice label={<><FormattedMessage {...shoppingCartTranslation.totalLabel}/> :</>}
                 price={grandTotal}
    />
    </div>
    )
}

export function StyledPrice({label, price}){
    return(
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '7px 12px'
    }}>
        <div style={{flex:31}}>{label}</div>
        <div style={{flex:10, marginLeft: 8, maxWidth: 100}}>
            {"$" + parseFloat(price).toFixed(2)}
        </div>
    </div>)
}

const StyledClose = styled.span`
    position: absolute;
    top:10px;
    cursor: pointer;
    left:14px;
    z-index: 10;
    @media (min-width:480px){
      display:none;
    }
`

const StyledSummaryCtn = styled.div`
    flex: 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0px -4px 8px -2px, rgba(9, 30, 66, 0.31) 0px -2px 1px;
    align-items: center;
    justify-content: center;
`

const ShoppingCartTitle = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    //height: 120px;
    box-shadow: rgba(9,30,66,0.25) 0 4px 8px -2px, rgba(9,30,66,0.31) 0px 2px 1px;
`
//The shopping cart panel
class ShoppingCart extends Component {

    state = {
        isLoading: false,
        showSpinner: false,
    };

    componentWillMount(){
        //Load shopping cart if it is empty
        if(this.props.shoppingCart.id === null){
//            this.props.loadShoppingCart()
        }
    }

    _checkOutButtonOnClick = () =>{
        const {
            placeOrderShoppingCart,
            shoppingCart,
            location,
            history
        } = this.props;
        this.setState({ isLoading: true, showSpinner: true });
        let nextFunc = null
        if (shoppingCart.table_id) {
            nextFunc = placeOrderShoppingCart
            Promise.resolve( nextFunc()
            ).then(
                ()=>{
                    this.setState({ showSpinner: false})
                }
            )
        }
        else {
            this.props.history.push('/order', {
                returnTo: this.props.location.pathname,
                validEntry: true
            });
            this.props.onClose()
        }
        // nextFunc = placeOrderShoppingCart

    }

    completeLoad = () => {
        this.setState({ isLoading: false });
    };
    render(){
        const {className, shoppingCart: shoppingCartData,
            selectedPartner,
          isQR,
            setQuickOrderEmail, theme, isOnlineAvail, submitOnlineShoppingCart} = this.props;
        const {open_hours, initial_delay_time} = (selectedPartner || {open_hours:[], initial_delay_time: 0});
        const isNowAvail = open_hours && isNowAvailable(open_hours, initial_delay_time) // can't use memo due to it always requires current time, TBD check sec by sec, in case of idle webpage

        const { isLoading, showSpinner } = this.state;
        // console.log(isQR, selectedPartner)
        //Return empty shopping cart when there is no orders in shopping cart
        if(!shoppingCartData.partner && shoppingCartData.lines.length === 0 && !isQR){
            return(
                <div className={className}>
                    <StyledClose>
                        <CrossCircleIcon size='large' onClick={this.props.onClose}/>
                    </StyledClose>

                    <ShoppingCartTitle style={{flex:1}}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <img src={emptyPng} />
                        </div>
                        <div style={{flex: 2, display: 'flex', justifyContent: 'center',  width: '100%'}}>

                            <P><FormattedMessage {...shoppingCartTranslation.shoppingCartEmpty}/></P>
                        </div>
                    </ShoppingCartTitle>
                </div>
            )
        }
        if(isQR){
            // console.log(shoppingCartData);
            const numUnsubmittedLine = shoppingCartData.lines.filter(line=> line.status !== 'placed').length
            return (
            <Paper elevation={2} square className={className}>
                <StyledClose onClick={this.props.onClose}>
                    <CrossCircleIcon size='large' />
                </StyledClose>

                <ShoppingCartTitle>
                    <span style={{color: '#505F79'}}>
                        In Store Order
                    </span>
                </ShoppingCartTitle>
                <div style={{
                    flex: 1,
                    overflowY: 'scroll'
                }}>
                    {
                        //Render each order entry in the loop
                        shoppingCartData.lines.map((line, idx)=>{
                            // console.log(line);
                            return(
                              <CartLine key={`cartLine-${idx}`} line={line} index={idx} isEditable={line.status !== 'placed'}/>
                            )
                        })
                    }
                </div>
                <StyledSummaryCtn>
                    <SummarySection subTotal={shoppingCartData.subTotal} quantity={shoppingCartData.totalQuantity}/>
                    <div style={{
                        padding: '16px 20px',
                    }}>
                        <BarButton appearance="primary"
                                   isDisabled={
                                       shoppingCartData.status === 'Saved' ||
                                       shoppingCartData.status === 'Submitted' ||
                                       !numUnsubmittedLine

                                   }
                                   onClick={!isLoading ? ()=>{submitOnlineShoppingCart()}: undefined}
                                   iconAfter={
                                       <Spinner
                                         invertColor
                                         isCompleting={!showSpinner}
                                         onComplete={this.completeLoad}
                                       />
                                   }>
                            {this.props.intl.formatMessage(shoppingCartTranslation.submitButton)}
                        </BarButton>
                    </div>
                </StyledSummaryCtn>
            </Paper>
            )
        }
        return(
            <Paper elevation={2} square className={className}>
                <StyledClose onClick={this.props.onClose}>
                    <CrossCircleIcon size='large' />
                </StyledClose>
                {/*<Modal isOpen={!!this.state&&this.state.modal} contentLabel="popup Modal" style={modalStyles}>
                    <div className="popupTitle">
                        Your Email:
                    </div>
                    <div className="popupMessage">
                        <CustomTextField type='email' name='email' required style={{width:'100%'}} onChangeHandler={setQuickOrderEmail}/>
                    </div>
                    <button className="popupConfirmButton" onClick={()=>this.props.quickOrder({email:shoppingCartData.quickOrderEmail})}>Place Now</button>
                    <button className="popupCancelButton" onClick={()=>this.setState({modal:false})}>Go Back</button>
                </Modal>*/}

                <ShoppingCartTitle>
                    <span style={{color: '#505F79'}}>
                        <FormattedMessage {...shoppingCartTranslation.shoppingCartTitle}/>
                    </span>
                    <div style={{display:'flex', alignItems:'center'}}>
                        {shoppingCartData.partner?.name}
                        <ClosedChip extraInfo={{partnerId: shoppingCartData.partner?.id}}/>
                    </div>
                </ShoppingCartTitle>
                <div style={{
                    flex: 1,
                    overflowY: 'scroll'
                }}>
                    {
                        //Render each order entry in the loop
                        shoppingCartData.lines.map((line, idx)=>{
                            return(
                                <CartLine key={`cartLine-${idx}`} line={line} index={idx} isEditable/>
                            )
                        })
                    }
                </div>
                <StyledSummaryCtn>
                       <SummarySection subTotal={shoppingCartData.subTotal} quantity={shoppingCartData.totalQuantity}/>
                        <div style={{
                            padding: '16px 20px',
                        }}>
                            <BarButton appearance="primary"
                                       isDisabled={
                                           shoppingCartData.status === 'Saved' ||
                                           shoppingCartData.status === 'Submitted' || !isOnlineAvail
                                       }
                                       onClick={!isLoading ? this._checkOutButtonOnClick: undefined}
                                       iconAfter={
                                           <Spinner
                                               invertColor
                                               isCompleting={!showSpinner}
                                               onComplete={this.completeLoad}
                                           />
                                       }>

                                {this.props.intl.formatMessage(isOnlineAvail? (isNowAvail ? shoppingCartTranslation.checkoutButton : shoppingCartTranslation.preOrderCheckoutButton) : shoppingCartTranslation.checkoutClosedButton)}
                            </BarButton>
                        </div>
                </StyledSummaryCtn>
            </Paper>
        )
    }
}

// https://formatjs.io/docs/getting-started/message-declaration#pre-declaring-using-definemessage-for-later-consumption-less-recommended
export const shoppingCartTranslation = defineMessages({
    textTips: {
        id: "checkout.textTips",
        defaultMessage: "Tips",
        description: "textTips",
    },
    checkoutBeforeLoginErr: {
        id: "shoppingCart.checkoutBeforeLoginErr",
        defaultMessage: "Please login before you check out.",
        description: "checkout Before Login Err",
    },
    alreadyCheckingoutErr: {
        id: "shoppingCart.alreadyCheckingoutErr",
        defaultMessage: "You already in payment process.",
        description: "already Checkingout Err",
    },
    shoppingCartEmpty: {
        id: "shoppingCart.shoppingCartEmpty",
        defaultMessage: "Your cart is empty.",
        description: "shopping Cart Empty",
    },
    shoppingCartTitle: {
        id: "shoppingCart.shoppingCartTitle",
        defaultMessage: "My Order",
        description: "shopping Cart Title",
    },
    subtotalLabel: {
        id: "shoppingCart.subtotalLabel",
        defaultMessage: "Subtotal",
        description: "subtotal Label",
    },
    taxLabel: {
        id: "shoppingCart.taxLabel",
        defaultMessage: "Tax",
        description: "tax Label",
    },
    totalLabel: {
        id: "shoppingCart.totalLabel",
        defaultMessage: "Total",
        description: "total Label",
    },
    checkoutButton:{
        id: "shoppingCart.checkoutButton",
        defaultMessage: "Place Order",
        description: "checkout button text",
    },
    preOrderCheckoutButton:{
        id: "shoppingCart.preOrderCheckoutButton",
        defaultMessage: "Pre Order",
        description: "Pre Order checkout button text",
    },
    submitButton:{
        id: "shoppingCart.submitButton",
        defaultMessage: "Submit",
        description: "submit button text",
    },
    checkoutClosedButton:{
        id: "shoppingCart.checkoutClosedButton",
        defaultMessage: "Store Closed",
        description: "checkout closed button text",
    },
    textItems:{
        id: "shoppingCart.textItems",
        defaultMessage: "Subtotal ({itemCount, plural, =0 {no items} one {1 item} other {{itemCount} items}}):",
        description: "textItems",
    },
    deliveryFeeLabel: {
        id: "shoppingCart.deliveryFeeLabel",
        defaultMessage: "Delivery Fee: ({dist}Km)",
        description: "deliveryFeeLabel",
    },
    deliveryFeeTaxLabel: {
        id: "shoppingCart.deliveryFeeTaxLabel",
        defaultMessage: "Delivery Fee Tax",
        description: "deliveryFeeTax",
    },

})

const StyledShoppingCart = styled(ShoppingCart).attrs({
    width: props => props.fullWidth?'100%':'30vw',
})`
    width: calc(100vw - 20px);
    min-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    text-align: center;
    @media (min-width: 480px) {
        width: ${props => props.width};
    }
    ${ShoppingCartTitle} {
        color: ${props => props.theme.shoppingCart.shoppingCartTitle.color.default};
        font-size: 20px;
        font-weight: 600;
        z-index: 1;
        span {
          color: ${props => props.theme.shoppingCart.shoppingCartTitle.color.secondary};
          font-size: 18px;
          font-weight: 400;
        }
    }
`;
StyledShoppingCart.defaultProps = {
    theme: {
        shoppingCart:
            {
                shoppingCartTitle: {
                    color: {
                        default: '#333',
                        secondary: '#505F79',
                    },
                },
                option: {
                    color: {
                        default: 'rgb(80, 95, 121)',
                        hover: 'rgb(80,96,121)',
                    }
                }
            }
    }
}

const mapStateToProps = (state, ownProps) => {
    const shoppingCartPartner = state.shoppingCart.shoppingCart.partner
    const partner = shoppingCartPartner && shoppingCartPartner.id && getPartner(state, {match: {params: {partnerId: shoppingCartPartner.id}}});
    return {
        shoppingCart: state.shoppingCart.shoppingCart,
        // user:state.auth.user,
        accountIconId:state.ui.navBar.get("accountIconId"),
        isOnlineAvail: partner && partner.is_online_avail, // TODO: change partner result false
        selectedPartner: selectCartPartner(state),
        isQR : state.auth.isQR,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        loadShoppingCart: function () {
            dispatch(fetchShoppingCart())
        },
        submitOnlineShoppingCart: function () {
            dispatch(submitQRShoppingCart())
        },
        // showErrorMessage: function (msg) {
        //     dispatch(notificationAction.generateError(msg))
        // },
        // quickOrder: (shoppingInfo) => dispatch(shoppingActions.quickOrder(shoppingInfo)),
        onToggle: (rect,type) => {
            dispatch(uiAction.toggleDownMenu(rect,type))
        },
        onClose: () => {
            dispatch(uiAction.toggleUpMenu())
        },
        // setQuickOrderEmail: (email) => {
        //     dispatch(shoppingActions.setQuickOrderEmail(email))
        // },
        // placeOrderShoppingCart: () => dispatch(shoppingActions.placeOrderShoppingCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(StyledShoppingCart)));
