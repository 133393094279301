import styled from 'styled-components';

const P = styled.p`
  color: rgb(80,95,121);;
  line-height: 1.2;
  letter-spacing: .2px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  ::selection {
    background: #ffb7b7; /* WebKit/Blink Browsers */
  }
  @media (min-width: 480px){
    //font-size: calc(.0056*100vw + 13.3333px); /*calc(14+(16-14)*(100vw-480)/(1200-480))*/
    font-size: 16px;
  }
  @media (min-width: 1200px){
    //font-size: 20px;
  }
`;

export const PDiv = styled.div`
  color: rgb(80,95,121);;
  line-height: 1.2;
  letter-spacing: .2px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  ::selection {
    background: #ffb7b7; /* WebKit/Blink Browsers */
  }
  @media (min-width: 480px){
    //font-size: calc(.0056*100vw + 13.3333px); /*calc(14+(16-14)*(100vw-480)/(1200-480))*/
    font-size: 16px;
  }
  @media (min-width: 1200px){
    //font-size: 20px;
  }
  

`

export default P;
