import { PureComponent } from 'react';
import styled from 'styled-components';
import {Redirect} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {getPartner} from "../../selectors/partnerSelectors";
import * as partnerActions from "../../actions/partnerActions";
import {withSpinner} from "../../hoc";

class PartnerRedirect extends PureComponent {

    componentWillMount() {
        const {
            getPartnerById,
            onPromiseWithLoading,
            match
        } = this.props;
        onPromiseWithLoading(()=>getPartnerById(match.params.partnerId), true)
    }

    render() {
        const {
            partnerSelected,
            location
        } = this.props;
        return(
            partnerSelected&&<Redirect to={{
                pathname: `/${partnerSelected.id}/${partnerSelected.slug}`,
                state: { from: location }
            }}
            />
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        partnerSelected: getPartner(state, ownProps),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPartnerById: (partnerId) => dispatch(partnerActions.fetchPartnersById(partnerId)),
    }
};
/*
* Simple Compo don't want to separate files for its Container.
 */
export default connect(mapStateToProps, mapDispatchToProps)(withSpinner(PartnerRedirect));