import React, {memo, useEffect, useRef, useState} from "react";
import TextField from "@atlaskit/textfield";
import {useIntl} from "react-intl";
import {BarButton} from "../styledComponents";
import Modal from "react-modal";
import {GOOGLE_API_KEY} from '../../actions/api'

type Props = {
    isDisabled: boolean;
    onErrorUpdating?: (errorMessage: string | null) => void;
    onAddrChange?: (address: string | undefined, distance: number|null) => void;
    origin: string;
}
type AddrStates = {
    verifyingAddress: string|undefined;
    distance: number|null;
}


// TODO： since we only use api so far consider just directly use module from google
const googleMapLoader = require('google-map-react/lib/loaders/google_map_loader').default;
const AddressModal = ({verifyingAddress, onModalNo, onModalYes, origin, isOpen}:{
    verifyingAddress: string| undefined;
    isOpen: boolean;
    onModalNo: (errorMessage?:string)=>void;
    onModalYes: (address: string, dist:number)=>void;
    origin: string}) => {
    const { formatMessage } = useIntl();
    const unitInputRef = useRef<HTMLInputElement>(null);
    const onConfirmAddress = async () => {
        const unitNum = unitInputRef.current?.value
        const updatedAddr = (unitNum ? unitNum + '-' : '') + verifyingAddress
        const dist = await onCalculateDistance()
        if (dist) onModalYes(updatedAddr, dist)

        else {
            const errorMessage = formatMessage({
                defaultMessage: "Invalid Address",
                description: "errorInvalidAddress",
            })
            onModalNo(errorMessage)}
        // console.log("we should get returned val here", val);
    }
    const onCalculateDistance =  async () => {
        const maps = await googleMapLoader({key: GOOGLE_API_KEY,})
        const service = new maps.DistanceMatrixService();
        return new Promise<number | null>((resolve, reject) => {
            service.getDistanceMatrix(
                {
                    origins: [origin],
                    destinations: [verifyingAddress],
                    travelMode: 'DRIVING',
                    avoidHighways: false,
                    avoidTolls: true,
                } as google.maps.DistanceMatrixRequest, (response: google.maps.DistanceMatrixResponse, status: string) => {
                    //Address ok, calculate delivery fee
                    if (response.rows[0].elements[0].status === "OK" && status === "OK") {
                        resolve(response.rows[0].elements[0].distance.value || 1); //In meters
                    }
                    resolve(null);
                });
        })
    }
    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            ariaHideApp={false}
            // onRequestClose={()=>this.setState({verifyingAddress: '',isVerifying : false, validatedAddress: null, distance: 0})}
            style={{
                content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    transform             : 'translate(-50%, -50%)'
                }
            }} >
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <div>
                    {formatMessage({
                        defaultMessage: "Is this your address?",
                        description: "textConfirmAddress",
                    })}
                </div>
                <div style={{marginTop: 8, marginBottom: 8, fontWeight: 'bold'}}>
                    {verifyingAddress}
                </div>
                <div style={{width: '100%'}}>
                    <label style={{fontSize: 14}}>{formatMessage({
                        defaultMessage: "Apt./Unit Number",
                        description: "aptNumberField",})}</label>
                    <TextField
                        type="text"
                        ref={unitInputRef}
                    />
                </div>
                <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop: 16}}>
                    <BarButton appearance="primary" style={{minWidth:100, flex:0}}
                               onClick={onConfirmAddress} >
                        {formatMessage({
                            defaultMessage: "Yes",
                            description: "textYes",
                        })}
                    </BarButton>
                    <BarButton appearance="secondary" style={{minWidth:100, flex: 0}}
                               onClick={() => onModalNo()} >
                        {formatMessage({
                            defaultMessage: "No",
                            description: "textNo",
                        })}
                    </BarButton>
                </div>
            </div>
        </Modal>
    )
}

const AddressSection = (props: Props) => {
    const { isDisabled, onErrorUpdating, onAddrChange, origin} = props;
    const { formatMessage } = useIntl();
    const [isVerifying, setVerifying] = useState(false)
    // const [isModalOpen, setModalOpen] = useState(false)
    const [addrInfo, setAddrInfo] = useState<AddrStates>({verifyingAddress: undefined, distance: null})
    const isValid = (addrInfo.verifyingAddress && addrInfo.distance)
    const {verifyingAddress, distance} = addrInfo
    const inputRef = useRef<HTMLInputElement>(null)
    let errorMessage: string| null = null;
    useEffect(() => {
        onAddrChange&&onAddrChange(verifyingAddress, distance)
        console.log('AddressSection Endpoint callback:',addrInfo)
    },[addrInfo])
    const onVerifyAddress = async () => {
        setVerifying(true)
        const addr = inputRef.current?.value + ", Canada"
        try{
            const maps = await googleMapLoader({key: GOOGLE_API_KEY,})
            const geocoder = new maps.Geocoder();
            // We have to wrap promise since Google wont return promise in current ver. but its in beta now.
            // TODO: google API will work like this:  await geocoder.geocode({'address': addr}): google.maps.GeocoderResult[]
            const promise = new Promise<string>((resolve, reject) => {
                geocoder.geocode({'address': addr},  (response: google.maps.GeocoderResult[], status: string) => {
                    if (status === 'OK') {
                        const result: Record<string, google.maps.GeocoderAddressComponent | null> = {
                            streetNum: null,
                            route: null,
                            locality: null,
                            sublocality: null,
                            postcode: null,
                            province: null,
                        }

                        //preprocess response
                        let addrCompArr = response[0].address_components
                        for (let i = 0; i < addrCompArr.length; i += 1) {
                            const addrComp = addrCompArr[i];
                            //check if any type in types array matches
                            for (let j = 0; j < addrComp.types.length; j += 1) {
                                switch (addrComp.types[j]) {
                                    case "street_number":
                                        result.streetNum = addrComp
                                        break;
                                    case "route":
                                        result.route = addrComp
                                        break
                                    case "administrative_area_level_1":
                                        result.province = addrComp
                                        break
                                    case "locality":
                                        result.locality = addrComp
                                        break
                                    case "sublocality":
                                        result.sublocality = addrComp
                                        break
                                    case "postal_code":
                                        result.postcode = addrComp
                                        break
                                    default:
                                }
                            }
                        }
                        if (result.streetNum) { //Street Number must exist, otherwise address is invalid since google return a Street
                            return resolve(response[0].formatted_address)
                        } else {// streetnum not exist
                            //Invalid Address
                            console.log(response, status)
                            errorMessage = formatMessage({
                                defaultMessage: "Invalid Address",
                                description: "errorInvalidAddress",
                            })
                        }
                    } else { // geo failed
                        //geo failed
                        //dispatch(notificationAction.generateError(get_intl().formatMessage(userProfileActionsTranslation.errorInvalidAddress)))
                        errorMessage = formatMessage({
                            defaultMessage: "Invalid Address",
                            description: "errorInvalidAddress",
                        })
                    }
                    errorMessage&&reject(errorMessage)
                })
            })
            const validated_addr = await promise

            console.log('validated_addr', validated_addr)
            setAddrInfo({verifyingAddress:validated_addr, distance:null})
        } catch (e: any) {
            //initFailed
            console.log('errorMessage2', e)
            if (typeof e === 'string') errorMessage = e
            else errorMessage = formatMessage({
                defaultMessage: "Please Try Again Later",
                description: "errorTryAgain",
            })
            onErrorUpdating && onErrorUpdating(errorMessage)
        }
        setVerifying(false)
    }

    const onModalYes = (address: string, dist: number) => {
        setAddrInfo({verifyingAddress: address, distance: dist})
    }
    const onModalNo = (errorMessage?:string) => {
        setAddrInfo({verifyingAddress: undefined, distance: null})
        if(errorMessage && onErrorUpdating) onErrorUpdating(errorMessage)
    }
    console.debug('Optimization Monitor Warning: Rendering AddressSection, expected 0 time(s) while parent rerender')

    return (
        <>
        <AddressModal verifyingAddress={verifyingAddress} onModalNo={onModalNo} onModalYes={onModalYes} origin={origin}
                      isOpen={!!verifyingAddress && !distance}/>
        <div style={{display: 'flex', alignItems: 'center', margin: '16px 12px'}}>
            <div style={{flex: 1}}>
                <TextField
                    ref={inputRef}
                    type="text"
                    value={verifyingAddress || undefined}
                    isDisabled={isDisabled}
                    onChange={()=>isValid&&setAddrInfo({verifyingAddress: undefined, distance: null})}
                    placeholder={formatMessage({
                        defaultMessage: "Enter your address then confirm",
                        description: "streetNameField",})}
                />
            </div>
            <BarButton appearance="primary"
                       onClick={onVerifyAddress}
                       isDisabled={isValid || isVerifying}
                       style={{flex: '0 0 140px', marginLeft:15, }}>
                {isValid? formatMessage({
                    defaultMessage: "Verified",
                    description: "buttonVerified",})
                    :formatMessage({
                    defaultMessage: "Verify",
                    description: "buttonVerify",})}
            </BarButton>
        </div>
        </>
    )
}
export default memo(AddressSection);
