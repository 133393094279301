import styled from 'styled-components';

const Head1 = styled.h1`
  font-family: inherit;
  text-align: left;
  line-height: 1.2;
  font-size: 24px;
  margin: 10px 0;
  font-weight: 700;
  @media (min-width: 480px) {
    font-size: calc(.01111*100vw + 18.6667px); /*calc(24+(32-24)*(100vw-480)/(1200-480))*/
  }
  @media (min-width: 1200px){
    font-size: 32px;
  }                      
`;
const Head3 = styled.h3`
  font-family: inherit;
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0 8px 0;
  @media (min-width: 1200px){
    font-size: 24px;
  }
`
export {Head3};
export default Head1;
