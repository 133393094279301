/**
 * Created by haolunchai on 2017-01-04.
 */
import { Record} from 'immutable';
import {schema,denormalize} from 'normalizr'

export const OPTION_BOOLEAN = "boolean";
export const OPTION_INTEGER = "integer";
export const OPTION_TEXT = "text";

export const OPTION_GROUP_SINGLE_SELECT = "singleSelect"
export const OPTION_GROUP_MULTI_SELECT = "multiSelect"

//===============Normalizr=================

const dishDefault = {
    id:null,
    product_model:null,

    title_en:null,
    title_zh_hans:null,
    description_en: null,
    description_zh_hans: null,
    price:0.00,
    image:null,
    category:null,
    description:null,
    option_groups: [],
    is_online_avail: false,
    endorsement:[],
};

const partnerDefault = {
    id:null,
    address: null,
    background_image:null,
    description:null,
    logo: null,
    slug: null,
    name: null,
    categories: [],

    type: [],
    payment_methods: [],
    init_charge: '0.00',
    init_dist: 0,
    delivery_range: 0,
    per_km_charge: '0.00',
    delivery_tax_rate: '0.00',
    avail_language: [],
    tax_settings: [],

    open_hours: [],
    initial_delay_time: 3600,
    min_delivery_subtotal: '0.00',
    is_min_subtotal_req: false,
    below_min_fee: '0.00',
    is_online_avail: false,
    phone_number: "",
    stripe_pk: "pk_test_LIpF0KeX5xzuEE8lXwwQS6x500OpUBLe2s",
    /*coordinates:[],
    distance: null,
    default_phone_number: "",
    extra_phone_number: "",
    is_open: null,
    open_hours: [],
    closing_rules: [],
    tags: [],
    products: [],
    is_sponsored: false,
    notes:"",*/
};
const categoryDefault = {
        id:null,
    sort_order:null,
    name_en:null,
    name_zh_hans:null,
        products: [],
    is_online_avail: false,
    description_en: null,
    description_zh_hans: null,
};

const optionDefault = {
        id:null,
        type:null,
        name_en:null,
        name_zh_hans:null,
        applied_price:0.00,
    };

const optionGroupDefault = {
        id:null,
        type:null,
    name_en:null,
    name_zh_hans:null,
        required:null,
        options:[],
    };

const orderDefault = {
    id:null,
    number:null,
    lines:[],
    partner:null,
    shipping_address:null,
    total_incl_tax:null,
    total_excl_tax:null,
    shipping_incl_tax:null,
    shipping_excl_tax:null,
    tips:null,
    shipping_method:null,
    payment_method:null,
    status:null,
    date_placed:null,
    user:null,
    driver:null,
    ordering_timing:null,
    delivery_time:null,
    delivery_distance:null,
    partner_message:null,
    customer_message:null,
    admin_message:null,
    service_fee:null,
    voucher_discounts:null,
    total_sale_incl_tax: null,
    is_first_time:null,
};

const shoppingCartDefault = {
    //shopping cart basic info
    id:null,
    owner:null,
    status:null,
    partner:null,
    lines:[], //List of Orders
    delivery_info:null,
    voucher_discounts:null,
    comment: '',
};


export const SchemaDefault = {
    dishDefault,
    partnerDefault,
    categoryDefault,
    optionDefault,
    optionGroupDefault,
    orderDefault,
    shoppingCartDefault
};


const optionSchema = new schema.Entity("options",{},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.optionDefault,value)
    }
});

const optionGroupSchema = new schema.Entity("optionGroups",{options:[optionSchema]},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.optionGroupDefault,value)
    }
});

// option_groups are "customized", it is different for every dish
const dishSchema = new schema.Entity("dish",{/*option_groups:[optionGroupSchema]*/},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.dishDefault,value)
    }
});

const categorySchema = new schema.Entity("categories",{products:[dishSchema]},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.categoryDefault,value)
    }
});

const partnerSchema = new schema.Entity("partners",{categories:[categorySchema],products:[dishSchema]},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.partnerDefault,value)
    }
});


const orderSchema = new schema.Entity("ordersMap",{},{
    idAttribute:"number",
    processStrategy:(value, parent, key)=>{
        let obj =  ObjectAssignKeepShape(SchemaDefault.orderDefault,value);
        obj["id"] = value.number;
        return obj;
    }
});

const shoppingCartSchema = new schema.Entity("shoppingCart",{},{
    processStrategy:(value, parent, key)=>{
        return ObjectAssignKeepShape(SchemaDefault.shoppingCartDefault,value)
    }
});


export const Schema = {
    optionSchema,
    optionGroupSchema,
    dishSchema,
    categorySchema,
    partnerSchema,
    orderSchema,
    shoppingCartSchema,
}

export function ObjectAssignKeepShape(defVal, value){
    let val = Object.assign({},defVal);
    for(let field in val){
        if(value[field]!==undefined){
            val[field] = value[field];
        }
    }
    return val;
}
