/**
 * Created by yueyang on 2017/5/17.
 */
import { Component } from 'react';
import Modal from "react-modal"
import "./popup.css"

const modalStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.75)',
        zIndex            : 4001,
        display           : 'flex',
        alignItems        : 'center',
        justifyContent    : 'center',
    },
    content : {
        display                    : 'relative',
        position                   : 'relative',
        maxWidth                      : '500px',
        maxHeight                  : '500px',
        border                     : '0px solid #ccc',
        background                 : '#fff',
        overflow                   : 'auto',
        overflowX                  : 'hidden',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '0px',
        outline                    : 'none',
        padding                    : '0px',

        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
    }
}


export default class Popup extends Component {

    render() {
        const {popupState} = this.props
        return (
            <Modal isOpen={popupState.get("show")} contentLabel="popup Modal" style={modalStyles}>
                <div className="popupTitle">
                    {popupState.get("title")}
                </div>
                <div className="popupMessage">
                    {popupState.get("message")}
                </div>
                {(popupState.get("showConfirmButton")) && <button className="popupConfirmButton" onClick={popupState.get("confirmAction")}>{popupState.get("confirmTitle")}</button>}
                {(popupState.get("showCancelButton")) && <button className="popupCancelButton" onClick={popupState.get("cancelAction")}>{popupState.get("cancelTitle")}</button>}
            </Modal>
        )
    }
}

export {modalStyles}