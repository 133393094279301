/**
 * Created by yueyang on 2017/2/24.
 */
import {API_ROOT, parseResponse} from './api'
import {normalize} from 'normalizr'
import {showSpinner,hideSpinner} from './uiActions'
// import * as notificationAction from './notificationActions'
import {Schema} from './schemas'
import {getDish} from '../selectors/partnerSelectors'


/*****************************************
 *         Dish Detail Actions
 *****************************************/
export const FETCH_DISH_OPTION_STARTED = "FETCH_DISH_OPTION_STARTED"
export const FETCH_DISH_OPTION_FINISHED = "FETCH_DISH_OPTION_FINISHED"
export const FETCH_DISH_OPTION_ERROR = "FETCH_DISH_OPTION_ERROR"

//Fetch Dish option groups and its options, it will also update dish detail
export function fetchDishOption(dishID){
    return (dispatch, getState) => {
        dispatch(showSpinner())
        const productDetailUrl = API_ROOT + `product/${dishID}`;
        dispatch(fetchDishOptionStarted())
        return fetch(productDetailUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: "get",
            })
                .then(parseResponse)
                .then((json) => {
                    const normalizedDishDetail = normalize(json,Schema.dishSchema)
                    dispatch(fetchDishOptionFinished(normalizedDishDetail))
                    dispatch(hideSpinner())
                    //Check if this dish has option or description

                    dispatch(showProductModal(dishID,getState()))

                    // let dish = normalizedDishDetail.entities.dish[dishID.toString()]
                    // if((dish.description !== null && dish.description !== "") || dish.option_groups.length > 0) {
                    //     //yes, show the modal
                    //     dispatch(showProductModal(dishID,getState()))
                    // }
                    // else{
                    //     //no, add to shoppingCart
                    //     dispatch(addOrderToShoppingCart({product:dishID,quantity: 1,options:[]}))
                    // }
                })
                .catch((errors) => {
                    //PopUo errors thru some libs
                    // let errorMessage = notificationAction.parseJsonError(errors)
                    // dispatch(notificationAction.generateError(errorMessage))
                    dispatch(fetchDishOptionError(errors))
                    dispatch(hideSpinner())
                    throw errors
                });
    }
}

function fetchDishOptionStarted(){
    return {
        type: FETCH_DISH_OPTION_STARTED
    }
}

function fetchDishOptionFinished(normalizedDishDetail){
    return {
        type: FETCH_DISH_OPTION_FINISHED,
        payload: normalizedDishDetail,
        entities: {partner:normalizedDishDetail.entities}
    }
}

function fetchDishOptionError(error){
    return {
        type: FETCH_DISH_OPTION_ERROR,
        payload: error
    }
}

/*****************************************
 *         Product Modal Actions
 *****************************************/
export const SHOW_PRODUCT_MODAL = "SHOW_PRODUCT_MODAL"
export const OPTION_CHECKBOX_CLICKED = "OPTION_CHECKBOX_CLICKED"
export const REDUCE_DISH_IN_PRODUCTMODAL = "REDUCE_DISH_IN_PRODUCTMODAL"
export const INCREMENT_DISH_IN_PRODUCTMODAL = "INCREMENT_DISH_IN_PRODUCTMODAL"
export const REDUCE_OPTION_IN_PRODUCTMODAL = "REDUCE_OPTION_IN_PRODUCTMODAL"
export const INCREMENT_OPTION_IN_PRODUCTMODAL = "INCREMENT_OPTION_IN_PRODUCTMODAL"
export const CLOSE_PRODUCT_MODAL = "CLOSE_PRODUCT_MODAL"

function showProductModal(dishID,state){
    return {
        type: SHOW_PRODUCT_MODAL,
        dish: getDish(dishID)(state),
    }
}

export function optionCheckBoxClicked(optionCheckBoxGroupID, optionCheckBoxID, checkboxStatus){
    return {
        type: OPTION_CHECKBOX_CLICKED,
        optionGroupID: optionCheckBoxGroupID,
        optionID: optionCheckBoxID,
        checkboxStatus: checkboxStatus
    }
}

export function incrementOption(optionCheckBoxGroupID, optionCheckBoxID){
    return {
        type: INCREMENT_OPTION_IN_PRODUCTMODAL,
        optionCheckBoxGroupID: optionCheckBoxGroupID,
        optionCheckBoxID: optionCheckBoxID
    }
}

export function reduceOption(optionCheckBoxGroupID, optionCheckBoxID){
    return {
        type: REDUCE_OPTION_IN_PRODUCTMODAL,
        optionCheckBoxGroupID: optionCheckBoxGroupID,
        optionCheckBoxID: optionCheckBoxID
    }
}

export function reduceDish(){
    return {
        type: REDUCE_DISH_IN_PRODUCTMODAL,
    }
}

export function incrementDish(){
    return {
        type: INCREMENT_DISH_IN_PRODUCTMODAL,
    }
}

export function closeProductModal(){
    return {
        type: CLOSE_PRODUCT_MODAL,
    }
}
