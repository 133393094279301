import { PureComponent } from "react";
import RadioButton from "../form/RadioButton";
import AdditionalPrice from "./AdditionalPriceLabel";
import {OptionLine, OptionNameSpan} from "./OptionLine";


class RadioOptionLine extends PureComponent {

    onChecked = (event) => {
        const {
            onChange,
            optionGroupId,
            id,checked
        } = this.props;
        onChange(id, optionGroupId, !checked)
    };
    render(){
        const {
            id,
            name,
            appliedPrice,
            checked,
            optionGroupId
        } = this.props;
        //onChange={this.onChecked}
        return(
            <OptionLine>
                <RadioButton checked={checked} id={id}  onClick={this.onChecked} name={`${optionGroupId}`} value="on"/>
                <label style={{cursor:'pointer', flex: '1 1 auto', display: 'flex', alignItems: 'center'}} htmlFor={id}>

                    <OptionNameSpan>{name}</OptionNameSpan>
                    {(parseFloat(appliedPrice)>0)&&<AdditionalPrice price={appliedPrice}/>}
                </label>

            </OptionLine>
        )
    }

}
export default RadioOptionLine