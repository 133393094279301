import styled from "styled-components";

const InputReset = styled.input`
    appearance: none;
    opacity: 0;
    outline: 0;
    position: absolute;
    cursor: pointer;
    margin: 0;
    height: 24px;
    width: 24px;
`

export default InputReset