export class ScrollRegister {
  scrollTop = 0;
  regRef = [];
  index = false;
  onChangeSelection = null;
  offsetTop = 0;

  constructor(onChangeSelection, offsetTop) {
    //console.log(onChangeSelection);
    this.onChangeSelection = onChangeSelection;
    this.offsetTop = offsetTop;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.updateDimensions);
  }

  destructor() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.updateDimensions);

  }

  handleScroll = () => {
    //console.log(window)
    // console.log('handle Scroll')
    this.changeScrollTop(window.scrollY)
    if (this.onChangeSelection) {
      // console.log(this.onChangeSelection)
      this.onChangeSelection(this.getCurrentScrollIndex())
    }
    // this.setState({selectedCategory: this.scrollReg.getCurrentScrollIndex()})
  }
  updateDimensions = () => {
    // console.log('handle dimension')
    this.recallibrateIndex();
    if (this.onChangeSelection) {
      // console.log(this.onChangeSelection)
      this.onChangeSelection(this.getCurrentScrollIndex())
    }
    // this.setState({selectedCategory: this.scrollReg.getCurrentScrollIndex()});
  }

  resetRef() {
    this.regRef = [];
  }

  registerRef(ref, index) {
    // console.log(ref)
    if (ref) {
      this.regRef[index] = ref;
    }
    this.recallibrateIndex();
    // console.log(this.regRef)
  }

  changeScrollTop(scrollTop) {
    this.scrollTop = scrollTop
    for (let i = 0; i < this.regRef.length - 1; i += 1) {
      // console.log(this.regRef[i].offsetTop, scrollTop)
      if (this.regRef[i + 1].offsetTop - this.offsetTop >= scrollTop) {
        this.index = i;
        return;
      }
    }
    this.index = false;
  }

  recallibrateIndex() {
    this.changeScrollTop(this.scrollTop);
  }

  scrollToRefTop(index) {
    window.scrollTo({top: this.regRef[index].offsetTop, behavior: 'smooth'})
  }

  getRefOffsetTop(index) {
    return this.regRef[index].offsetTop
  }

  getCurrentScrollIndex() {
    return this.index
  }
}

/*
Example - not modify for hook use yet

class Comp extends component {
  constructor(){
      this.scrollReg = new ScrollRegister(this.changeSelection, offsetPixel(int));
  }

  changeSelection = (i) => {
    // anything that calls setState or force rerender
  }

  renderMenu = () => {
    const {list} = this.props;
    return(
      <MenuCtn>
        {list && list.map((item,index) =>(
          <Menu
            isSelected={index === this.scrollReg.getCurrentScrollIndex} //getting Index from registry to determine which is selected
            onClicked={()=>this.scrollToRefTop(index)} // passing the selected index of the selection, let the registry do the rest
          >blah blah</Menu>
        )}
      </MenuCtn>
    )

  }

  renderContent = () => {
    const {list} = this.props;
    return (
      <div>
        {list && list.map((item,index) =>
          (<div ref={ref=>this.scrollReg.registerRef(ref,index)}}> //Register the ref to the registry
            //blah blah blah
          </div>)}
      </div>
    )
  }
}

 */