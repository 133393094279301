/**
 * Created by haolunchai on 2017-02-09.
 */

import { connect } from 'react-redux'
import * as uiActions from '../../../actions/uiActions'
import * as partnerActions from '../../../actions/partnerActions'
import * as dishActions from '../../../actions/dishActions'
import {getPartner, getIsFullPartnerLoaded} from "../../../selectors/partnerSelectors";
import PartnerDetail from "../../../components/partner";
// import * as shoppingActions from "../../../actions/shoppingCartActions";
import {addOrderToShoppingCart} from "../../../store/shoppingCart/reducer";

const mapStateToProps = (state, ownProps) => {
    return {
        partnerSelected: getPartner(state, ownProps),
        isFullPartnerLoaded: getIsFullPartnerLoaded(state, ownProps),
        productModal: state.productModal,
        shoppingCart: state.shoppingCart.shoppingCart,
        menuLanguage: state.ui.menuLanguage,
        isQR: state.auth.isQR,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        unfixingNavBar: () => dispatch(uiActions.unfixingNavBar()),
        fixingNavBar: () => dispatch(uiActions.fixingNavBar()),
        addFlag: (flagData) => dispatch(uiActions.addFlag(flagData)),
        getPartnerById: (partnerId, tableToken) => dispatch(partnerActions.fetchPartnersById(partnerId, tableToken)),
        enableShopCartModal: () => {
            dispatch(uiActions.enableShopCartModal())
        },
        disableShopCartModal: () => {
            dispatch(uiActions.disableShopCartModal())
        },
        closeProductModal: () =>{
            dispatch(dishActions.closeProductModal())
        },
        addOrderToShoppingCart: (newLineObj) =>  dispatch(addOrderToShoppingCart(newLineObj)),
        // setupSocket: (pathArgs) => dispatch(shoppingActions.setupSocket(pathArgs)),
        getDishDetailById: (dishId) => dispatch(dishActions.fetchDishOption(dishId)),
        showPopup: (info) =>   dispatch(uiActions.showPopupWindow(info)),
        closePopup: () => dispatch(uiActions.closePopupWindow()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PartnerDetail);
