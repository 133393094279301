import styled from 'styled-components';

const defaultTheme = {
    button: {
        color: {
            default: '#fff',
            hover: "#fff",
            active: "#fff",
            disabled: "#fff",
            selected: "#fff",
        },
        background: {
            default: "#333",
            hover: "rgba(51, 51, 51, 0.85)",
            active: "#222",
            disabled: "#333",
            selected: "#333",
        },
        size: {
            large: '32px',
            medium: '24px',
            small: '12px'
        },
    }
};
/**
 * @deprecated AmountBtn
 */
const AmountBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 25px;
  width: 25px;
  background-color: rgb(10, 120, 185);
  color: white;
  outline-style: none;                   
`;

const IconCircleButton = styled.button`
    color: ${props=> props.theme.button.color.default};
    background: ${props=> props.theme.button.background.default};
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 4px;
    &:focus {
        outline:0;
    }
    &:hover {
        background:  ${props=> props.theme.button.background.hover};
    }
     &:active {
         background: ${props=> props.theme.button.background.active};
    }
`;
IconCircleButton.defaultProps = {
    theme: defaultTheme
};
export {AmountBtn, IconCircleButton as default};
