import React, { Component } from 'react';
import './globalStyle/App.css';
import { connect } from 'react-redux'
import {AutoDismissFlag} from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import FlagGroup from './components/flagGroup'
import Nav from './containers/navigationBar/nav'
import Modal from "react-modal"
import * as uiAction from './actions/uiActions'
// import * as authAction from './actions/authActions'
import ShoppingCart from './components/shoppingCart/ShoppingCart'
// import Notification from './components/notification'
import {PartnerDetailPage} from "./containers/partner/index";
import Popup from "./containers/popup/popup";
// import Login from './containers/auth/login'
import {Route, Switch} from 'react-router-dom'
import PartnerRedirect from "./components/partner/PartnerRedirect";
import QRAuth from "./components/QROrder/QRAuth";
import QRDetail from "./components/QROrder/QRDetail";
// import CheckOutPage from "./components/checkout/CheckOutPage";
import CompletePage from "./components/checkout/CompletePage";
import Spinner from './widgets/spinner'
import Checkout from "./pages/Checkout";

class App extends Component{
    constructor(props) {
        super(props);
        this.previousLocation = props.location
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        // const { initAuth, currentUser } = this.props;
        // !currentUser.id&&initAuth&&initAuth()
    }
    componentWillUpdate(nextProps) {
        const { location } = this.props
        // set previousLocation if props.location is not modal
        if (
            nextProps.history.action !== 'POP' &&
            (!location.state || !location.state.modal)
        ) {
            this.previousLocation = this.props.location
        }
        this.previousPath = this.props.location.pathname
    }
    componentWillReceiveProps(nextProps) {
    }
    closeModal = () => {
        const {onDismissClick, history, location, modal} = this.props
        modal.get('type')!=='cart'&&history.push(location.state.returnTo);
        onDismissClick()
    }
    getModalStyles = () => {
        const {modal} = this.props
        // const right = modal&&modal.get('type')=='cart'?document.body.clientWidth- modal.getIn(['rect','right']):0
        const top = modal?modal.getIn(['rect','bottom']):0
        let contentDefaultStyles = {
            position                   : 'absolute',
            width: 'auto',
            height: 'auto',
            margin: 'auto',
            top                        : top,
            left                      : 'auto',
            bottom: 0,
            right: 0,
            overflow                   : 'auto',
            WebkitOverflowScrolling    : 'touch',
            borderRadius               : '0px',
            outline                    : 'none',
            padding                    : '0px',
            border: 'none',
            background: 'rgb(55, 55, 55)',
        }
        let content_styles = {}
        let right =  modal.getIn(['rect','right'])
        if (modal) {
            switch (modal.get('type')){
                case 'cart':
                    content_styles = Object.assign({}, contentDefaultStyles, {
                        right:0,
                        bottom: 0,
                    })
                    break;
                case 'account':
                    content_styles = right?Object.assign({}, contentDefaultStyles, {
                            right: window.innerWidth - modal.getIn(['rect','right']),
                            bottom: 'auto',
                        })
                        :Object.assign({}, contentDefaultStyles, {
                            right:0,
                            left: 0,
                            bottom: 'auto',
                        })
                    break;
                default:
                    content_styles = right?content_styles = Object.assign({}, contentDefaultStyles, {
                        right: document.body.clientWidth - modal.getIn(['rect','right']),
                        bottom: 'auto',
                    }):Object.assign({}, contentDefaultStyles, {
                        left: '50%',
                        right: 'auto',
                        transform: 'translateX(-50%)',
                        bottom: 'auto',
                    })
                    break;
            }
        }
        return {
            overlay : {
                position          : 'fixed',
                top               : 0,
                left              : 0,
                right             : 0,
                bottom            : 0,
                backgroundColor   : 'rgba(0, 0, 0, 0.65)',
                zIndex: 400,
            },
            content : content_styles
        }
    }

    render() {
        const {
            modal,
            location,
            history,
            flags,
            dismissFlag
        } = this.props
        const isModal = (
            location.state &&
            location.state.modal &&
            this.previousLocation !== location // not initial render
        )

        const isShopCartOpened = (
            modal.get('isTogged') &&
            modal.get('type') === 'cart'
        )
        const iconMap = (key, color) => {
            const icons = {
                error: <Error label="Error icon" primaryColor={color || colors.R300} />,
            };
            return key ? icons[key] : icons;
        };
        //TODO: change height 100vh logic, potentially to root, so base div is always full height for more display control
        //if Modal is shown, previousChildren will display at the background
        return (
            <div className="App">
                {/*<Notification/>*/}
                <Popup/>
                <Spinner/>
                <FlagGroup onDismissed={dismissFlag} style={{position: 'relative', marginLeft: '50px'}}>
                    {flags.map(flag => <AutoDismissFlag icon={iconMap(flag.icon)} {...flag}  />)}
                </FlagGroup>
                <Nav location={isModal ? this.previousLocation : location} history={history} />
                <div style={{marginTop: '70px', position: 'relative'}}>
                    <Switch location={isModal ? this.previousLocation : location}>
                        <Route exact path='/order/complete' component={CompletePage} />
                        <Route exact path='/order' component={Checkout} />
                        <Route exact path='/:partnerId(\d+)' component={PartnerRedirect}/>
                        <Route exact path='/:partnerId(\d+)/:partnerSlug' component={PartnerDetailPage} />
                        <Route exact path='/qrOrder/:partnerId(\d+)/' component={QRAuth}/>
                        {/*<Route path="/account/login/" component={Login}/>*/}
                    </Switch>
                </div>
                <Modal
                    shouldCloseOnOverlayClick={true}
                    isOpen={!!(isModal||isShopCartOpened)} //TODO：[TOGGLE_UP] isOpen account 不会被影响
                    onRequestClose={this.closeModal}
                    style={this.getModalStyles()}
                    contentLabel="Example Modal" >
                    <ShoppingCart location={location} history={history}/>
                    {/*{modal.get('type') === 'cart'?(<ShoppingCart location={location} history={history}/>):<Route path="/account/login/" component={Login}/>}*/}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    modal: state.ui.modal,
    // currentUser: state.auth.user,
    flags: state.ui.flags,
})

const mapDispatchToProps = (dispatch) => {
    return {
        onDismissClick: () => {
            dispatch(uiAction.toggleUpMenu())
        },
        // initAuth: () => {
        //     dispatch(authAction.initAuth())
        // },
        dismissFlag: () => {
            dispatch(uiAction.dismissFlag())
        }
    }
}
// export default App
export default connect(mapStateToProps,mapDispatchToProps)(App)
