/**
 * Created by haolunchai on 2017-02-09.
 */
import {API_ROOT, parseResponse, SOCKET_ROOT} from './api'
import {Schema} from './schemas'
import {normalize} from 'normalizr'
// import {fetchShoppingCart} from "./shoppingCartActions";
import {fetchShoppingCart} from "../store/shoppingCart/reducer"
// import {WEBSOCKET_CONNECT} from "../middlewares/websocket";
export const UPDATE_PARTNER_DETAIL      = "UPDATE_PARTNER_DETAIL";


/*
* active action
*/
export const fetchPartnersById = (partner_id, tableToken=null) => {

    return (dispatch, getState) => {
        let fullUrl = API_ROOT + `catalog/${partner_id}` + (tableToken?`/${tableToken}/`:'');
        let redirect = false
        return fetch(fullUrl, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            redirect: 'follow',
            method: "get",
        }).then(response=>{
            if(response.redirected) redirect = true;
            return response}
            )
            .then(parseResponse)
            .then((json) => {
                const ret = json;
                /* ret.categories = ret.categories.filter(cate=> cate.is_online_avail).map((cate=>{
                    const rt = {...cate};
                    rt.products = rt.products.filter(prod=> prod.is_online_avail);
                    return rt;
                 })).filter(cate => cate.products.length).sort((cate1,cate2)=>cate1.sort_order - cate2.sort_order)*/
                return normalize(ret,Schema.partnerSchema)
            })
            .then(
                response => {
                    dispatch(_updatePartnerDetail(response));
                    dispatch(fetchShoppingCart());
                    /*dispatch({
                        type: WEBSOCKET_CONNECT,
                        payload:{
                            url: SOCKET_ROOT+`table/`
                        }
                    })*/
                }
            )
            .catch((errors) => {
                throw errors;
            });
    };
}


export const authQRCode = (qr_code, partner_id) => {

    return (dispatch, getState) => {
        let fullUrl = API_ROOT + `qrmenu/auth?qr_token=${qr_code}`;
        return fetch(fullUrl, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            credentials: 'include',
            method: "get",
        }).then(parseResponse)
          .then((json) => {
              dispatch(qrcodeTokenGot())
              return Promise.resolve();
          })
          .catch((errors) => {
              return new Promise.reject();
              throw errors;
          });
    };
}

export const QR_TOKEN_GOT = 'QR_TOKEN_GOT';

function qrcodeTokenGot(token){
    return {
        type: QR_TOKEN_GOT,
    }
}

const _updatePartnerDetail = (response, id) => {
    return {
        type: UPDATE_PARTNER_DETAIL,
        entities: {partner:response.entities}
    }
}
