/**
 * Created by haolunchai on 2017-01-17.
 */
export const FIXING_NAV_BAR = 'FIXING_NAV_BAR'
export const UN_FIXING_NAV_BAR = 'UN_FIXING_NAV_BAR'
export const DISABLE_SHOP_CART_BTN = 'DISABLE_SHOP_CART_BTN'
export const ENABLE_SHOP_CART_BTN = 'ENABLE_SHOP_CART_BTN'
export const INIT_ACCOUNT_ICON_ID = 'INIT_ACCOUNT_ICON_ID'
export const DISMISS_FLAG = 'DISMISS_FLAG';
export const ADD_FLAG = 'ADD_FLAG';

export const addFlag = (flagData) => ({
    type: ADD_FLAG,
    payload: flagData
})

export const dismissFlag = () => ({
    type: DISMISS_FLAG,
})

export const fixingNavBar = () => ({
    type: FIXING_NAV_BAR,
})

export const unfixingNavBar = () => ({
    type: UN_FIXING_NAV_BAR,
})

export const initAccountIconId = (ref) =>({
    type:INIT_ACCOUNT_ICON_ID,
    ref:ref
})

export const TOGGLE_DOWN_MENU = 'TOGGLE_DOWN_MENU';
export const TOGGLE_UP_MENU = 'TOGGLE_UP_MENU';

export function toggleDownMenu(rect,type) {
    return {
        type: TOGGLE_DOWN_MENU,
        payload:{
            rect: rect,
            type: type,
            isTogged: true
        }

    }
}
export function toggleUpMenu() {
    return {
        type: TOGGLE_UP_MENU,
    }
}

export function disableShopCartModal() {
    return {
        type: DISABLE_SHOP_CART_BTN,
        payload:{
            enableShopCartBtn: false
        }

    }
}
export function enableShopCartModal() {
    return {
        type: ENABLE_SHOP_CART_BTN,
        payload:{
            enableShopCartBtn: true
        }

    }
}


export const SHOW_LOADING_SPINNER = "SHOW_LOADING_SPINNER"
export const HIDE_LOADING_SPINNER = "HIDE_LOADING_SPINNER"

export function showSpinner(){
    return {
        type: SHOW_LOADING_SPINNER,
    }
}

export function hideSpinner(){
    return {
        type: HIDE_LOADING_SPINNER,
    }
}


export const SHOW_POPUP_WINDOW = "SHOW_POPUP_WINDOW"
export const CLOSE_POPUP_WINDOW = "CLOSE_POPUP_WINDOW"
/*
    info should contains:
    show: boolean
    title: string,
    message: string,
    showConfirmButton: boolean,
    showCancelButton: boolean,
    confirmTitle: string,
    cancelTitle: string,
    confirmAction: ()=>{...},
    cancelAction: ()=>{...},
*/

export function showPopupWindow(info){
    return {
        type:SHOW_POPUP_WINDOW,
        info:info,
    }
}

export function closePopupWindow(){
    return {
        type:CLOSE_POPUP_WINDOW,
    }
}

export const START_SOUND_BEEP = "START_SOUND_BEEP"
export const END_SOUND_BEEP = "END_SOUND_BEEP"

export function startSoundBeep(){
    return{
        type:START_SOUND_BEEP
    }
}

export function endSoundBeep(){
    return{
        type:END_SOUND_BEEP
    }
}

export const FIRST_VISIT_END = "FIRST_VISIT_END"
export const FIRST_BANNER_END = "FIRST_BANNER_END"

export function finishFirstVisit(){
    return{
        type:FIRST_VISIT_END
    }
}

export function firstFirstBanner(){
    return{
        type:FIRST_BANNER_END
    }
}

export const MENU_LANGUAGE_CHANGE = 'MENU_LANGUAGE_CHANGE'

export function changeMenuLanguage(index, abbr){
    return {
        type:MENU_LANGUAGE_CHANGE,
        lang:{index, abbr},
    }
}