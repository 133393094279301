import {themeNamespace} from "@atlaskit/button";

const theme = {
    button:
        {
            color: {
                default: '#fff',
                hover: "#fff",
                active: "#fff",
                disabled: "#fff",
                selected: "#fff",
            },
            background: {
                default: "#333",
                hover: "rgba(51, 51, 51, 0.85)",
                active: "#222",
                disabled: "#333",
                selected: "#333",
            },
            size: {
                large: '32px',
                medium: '24px',
                small: '12px'
            },
        },
    header:
        {
            navItem: {
                color: {
                    default: '#505F79',
                    hover: '#333',
                    selected: '#333',
                },
            },
            option: {
                color: {
                    default: 'rgb(80, 95, 121)',
                    hover: 'rgb(80, 95, 121)',
                }
            }
        },
    shoppingCart:
        {
            shoppingCartTitle: {
                color: {
                    default: '#333',
                    secondary: '#505F79',
                },
            },
            option: {
                color: {
                    default: 'rgb(80, 95, 121)',
                    hover: 'rgb(80, 95, 121)',
                }
            }
        },
    cartLine:
        {
            divider: {
                background: {
                    default: '#EBECF0',
                    hover: '#EBECF0',
                },
            },
            option: {
                color: {
                    default: 'rgb(80, 95, 121)',
                    hover: 'rgb(80, 95, 121)',
                }
            }
        },
    radioButton:
        {
            background: {
                default: "#333",
                hover: "#333",
                active: "#333",
                disabled: "#333",
                selected: "#333",
            },
            size: {
                medium: '24px',
                small: '12px'
            },
            padding: {
                medium: '3px',
                small: '2px'
            }
        },
    checkBox:
        {
            background: {
                default: "#fff",
                hover: "#333",
                active: "#333",
                disabled: "#333",
                selected: "#333",
            },
            size: {
                medium: '24px',
                small: '12px'
            },
            padding: {
                medium: '3px',
                small: '2px'
            }
        },
    optionGroup:
        {
            background: {
                default: "#fff",
                hover: "#333",
                active: "#333",
                disabled: "#333",
                selected: "#333",
            },
            size: {
                medium: '24px',
                small: '12px'
            },
            padding: {
                medium: '3px',
                small: '2px'
            },
            color: {
                default: "#fff",
                hover: "#333",
                disabled: "#333",
            }
        },
};

export default theme
