/**
 * Created by YUEYUE on 10/7/2016.
 */
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor'
import DockMonitor from 'redux-devtools-dock-monitor'

export default createDevTools(
    <DockMonitor toggleVisibilityKey="ctrl-h" defaultIsVisible={false}
                 changePositionKey='ctrl-q'>
        <LogMonitor />
    </DockMonitor>
)