import { PureComponent } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
/*
* Props
*  onPromiseWithLoading : 把你想要render spinner，before func return 的 action func 放进去。
 */
const withSpinner = Component => {
    return class SpinnerWrapper extends PureComponent {
        state = {
            isLoading: false,
            showSpinner: false,
            checkOnce: false,
            loadStop: false,
        };

        onPromiseWithLoading = (promiseActionCall, checkOnce) => {
            this.setState({isLoading: true, showSpinner: true, checkOnce: !!checkOnce});
            Promise.resolve(
                promiseActionCall()
            ).then(
                () => this.setState({showSpinner: false, checkOnce: false, loadStop: false})
            ).catch(error=> this.setState({showSpinner: false, checkOnce: false, loadStop: checkOnce}))
        }

        completeLoad = () => {
            this.setState({isLoading: false});
        };

        render() {
            const {isLoading, showSpinner, checkOnce, loadStop} = this.state;
            // console.log(keepComponentAlive, isLoading)
            return (
                <div>
                    {isLoading && <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Spinner size="large" isCompleting={!showSpinner} onComplete={this.completeLoad}/>
                    </div> }
                    {(!isLoading && !loadStop) && (<Component onPromiseWithLoading={this.onPromiseWithLoading} {...this.props}/>)}
                </div>
            )
        }
    };
}

export default withSpinner;