import { PureComponent } from "react";
import AdditionalPrice from "./AdditionalPriceLabel";
import styled from "styled-components";

import {CircleButton} from "../styledComponents/buttons/";
import EditorDividerIcon from "@atlaskit/icon/glyph/editor/divider";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import {OptionLine, OptionNameSpan, OptionQuantitySpan} from "./OptionLine";

class QuantityOptionLine extends PureComponent {

    changeQuantity = (delta) => {
        const {
            value,
            onChange,
            optionGroupId,
            id,
        } = this.props
        if(value + delta >= 0)  onChange(id, optionGroupId, value + delta)
    }
    render(){
        const {
            id,
            name,
            appliedPrice,
            value,
            optionGroupId
        } = this.props;

        return(
            <OptionLine>
                <CircleButton onClick={this.changeQuantity.bind(this, -1)}>
                    <EditorDividerIcon size="small"/>
                </CircleButton>
                <OptionQuantitySpan>{value}</OptionQuantitySpan>
                <CircleButton onClick={this.changeQuantity.bind(this, 1)}>
                    <EditorAddIcon size="small"/>
                </CircleButton>

                <label style={{cursor:'pointer', flex: '1 1 auto', display: 'flex', alignItems: 'center'}} htmlFor={id}>
                    <OptionNameSpan>{name}</OptionNameSpan>
                    {(appliedPrice>0)&&<AdditionalPrice price={appliedPrice}/>}
                </label>

            </OptionLine>
        )
    }

}

export default QuantityOptionLine
