import { PureComponent } from "react";
import styled from "styled-components";
import {productModalTranslation} from "./ProductModal";
import * as Schemas from "../../actions/schemas";
import CheckBoxOptionLine from "./CheckBoxOptionLine";
import RadioOptionLine from "./RadioOptionLine";
import QuantityOptionLine from "./QuantityOptionLine";
import {getLocaleName} from "../../utils";

const defaultTheme = {
  optionGroup: {
    background: {
      default: "#fff",
      hover: "#333",
      active: "#333",
      disabled: "#333",
      selected: "#333",
    },
    size: {
      medium: '24px',
      small: '12px'
    },
    padding: {
      medium: '3px',
      small: '2px'
    },
    color: {
      default: "#fff",
      hover: "#333",
      disabled: "#333",
    }
  }
};


export const OptionGroupTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    text-align: left;
    flex: 1;
    
`
const Label = styled.span`
    width: 80px;
    text-align: center;
    letter-spacing: 0.72px;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px 5px;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 .14285714em;
    border-radius: .18571429rem;
    background-color: ${props => props.hide ? 'transparent' : props.theme.optionGroup.background.selected};
    color: ${props => props.hide ? 'transparent' : props.theme.optionGroup.color.default};
`;
Label.defaultProps = {
  theme: defaultTheme
};

class OptionGroup extends PureComponent {

  onChange = (optionId, optionGroupId, value) => {
    const {
      onChange
    } = this.props
    onChange && onChange(optionId, optionGroupId, value)
  };
  // shouldComponentUpdate(nextProps, nextState){
  //     console.log(nextProps, nextState)
  // }
  render() {
    const {
      required,
      options,
      type,
      id,
      selectedOptions,
    } = this.props;
    return (
      <div style={{marginTop: '15px'}}>
        <div style={{
          display: "flex", justifyContent: "space-between",
          background: '#EBECF0',
          margin: '0 -20px',
          padding: '15px 20px'
        }}>
          <OptionGroupTitle>{getLocaleName(this.props)}</OptionGroupTitle>
          {
            //check if this option group is required

            <Label>
              {this.props.intl.formatMessage(type === Schemas.OPTION_GROUP_SINGLE_SELECT ?
                productModalTranslation.textSingleSelect : productModalTranslation.textMultiSelect)}
            </Label>

          }
          {
            //check if this option group is required
            required &&
              <Label>
                {this.props.intl.formatMessage(productModalTranslation.textRequired)}
              </Label>
          }
        </div>
        {
          //Render each option entry in the loop, and create optionCheckBox data
          options.map((option) => {
            return ((option.type === Schemas.OPTION_BOOLEAN || option.type === Schemas.OPTION_TEXT) ?
                (type === Schemas.OPTION_GROUP_SINGLE_SELECT ?
                  <RadioOptionLine key={option.id} id={option.id} intl={this.props.intl}
                                   name={getLocaleName(option)}
                                   checked={selectedOptions.has(option.id.toString())}
                                   optionGroupId={id}
                                   onChange={this.onChange}
                                   appliedPrice={option.applied_price}/> :
                  <CheckBoxOptionLine key={option.id} intl={this.props.intl}
                                      id={option.id}
                                      name={getLocaleName(option)}
                                      checked={selectedOptions.has(option.id.toString())}
                                      optionGroupId={id}
                                      onChange={this.onChange}
                                      appliedPrice={option.applied_price}/>)
                : /*integer*/
                (<QuantityOptionLine key={option.id} intl={this.props.intl}
                                     id={option.id}
                                     name={getLocaleName(option)}
                                     value={selectedOptions.has(option.id.toString()) ?
                                       selectedOptions.get(option.id.toString()) : 0}
                                     optionGroupId={id}
                                     onChange={this.onChange}
                                     appliedPrice={option.applied_price}
                />)
            )

          })
        }
      </div>
    )
  }
}

export default OptionGroup;
