/**
 * Created by YUEYUE on 10/13/2016.
 */
import { Component, PropTypes } from 'react';
import { Provider } from 'react-redux'
import Router from "./router";



const Root = ({ store, history }) => (
    <Provider store={store}>
        <Router/>
    </Provider>
)

export default Root