import {RootState, useAppSelector} from '../store/configureStore';
import {createSelector} from "reselect";
import {denormalize} from "normalizr";
import {useIntl} from "react-intl"
import {Schema} from "../actions/schemas";
import {
    H1,
    H3,
    P,
    MainCtn as AbstractCtn,
    BarButton,
    Selector,
    A
} from "../components/styledComponents"
import getAvailTime, {A_DAY, getCurrentTS, isNowAvailable, timestampToFormatTime} from '../components/checkout/utils'
import TextField from "@atlaskit/textfield";
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import Decimal from "decimal.js";

import TipsSection from "../components/checkout/TipsSection";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import RadioButton from "../components/form/RadioButton";
import {OptionNameSpan} from "../components/product/OptionLine";
import CartLine from "../components/shoppingCart/CartLine";
import {SummarySection} from "../components/shoppingCart/ShoppingCart";
import InputBase from "@material-ui/core/InputBase";
import AddressSection from "../components/checkout/AddressSection";
import {useHistory} from "react-router";
import {TaxProps} from "../store/partner/types";
import {PlaceOrderRequest} from "../store/shoppingCart/types";
import CreditCardSection from "../components/checkout/CreditCardSection";
import {checkShoppingCart, getStripeSecret} from "../store/shoppingCart/reducer";
import {useTimeoutFn} from "react-use";
import AfterPayment from "../components/checkout/AfterPaymentModal";


type TimeAvaType = {name: string; ts: number;}
type SelectorType = { value: string; label: string; disabled?: undefined; }

const COMMENT_LENGTH_MAX = 140
const TAX_TYPE = {
    pst: { key: 'pst', name: 'PST' },
    hst: { key: 'hst', name: 'HST' },
    gst: { key: 'gst', name: 'GST' },
};
const MainCtn = styled(AbstractCtn)`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px){
    flex-wrap: nowrap;

  }
`
const LeftCol = styled.div`
  width: calc(100%);
  text-align: left;
  padding-right: 40px;
  @media (min-width: 768px){
    width: calc(61.8667%);
    border-right: 1px solid rgba(0,0,0,0.274510);

  }

`
const RightCol = styled.div`
  width: calc(100%);
  padding-bottom: 40px;
  text-align: left;
  margin-left: 0;
  @media (min-width: 768px){
    margin-left: 40px;
    width: calc(38.1333%);

  }
`
const OrderSec = styled.div`
  margin-bottom: 20px;
  &:not(:first-child){
    border-top: 1px solid rgba(0,0,0,0.274510);
  }
 
`
const HeadDivider = styled.span<{focus?: boolean;}>`
  position: absolute;
  left: 0px;
  right: ${props => props.focus?'33%':'100%'};
  bottom: -3px;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  height: 4px;
  background-color: rgb(255, 78, 61);
  &:hover{
    right: 0px;
  }
`
const RadioLine = styled.div`
    margin-top: 15px;
    margin-left: 12px;
    align-items: center;
    max-width: 220px;
    display: flex;
    justify-content: space-between;
`;

export const selectCartPartner = createSelector(
    (state:RootState) => state.shoppingCart.shoppingCart.partner?.id,
    (state:RootState) => state.entities.partner,
    (id, entities) => denormalize(id, Schema.partnerSchema, entities)
)
// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
const Checkout = () => {
    const shoppingCart = useAppSelector(state => state.shoppingCart.shoppingCart)
    const selectedPartner = useAppSelector(state => selectCartPartner(state))
    // console.log(selectedPartner)
    // const checkoutSecret = useAppSelector(state => state.shoppingCart.secret)
    const { formatMessage } = useIntl();
    const history = useHistory();
    const {
        open_hours,
        initial_delay_time,
        payment_methods,
    } =  selectedPartner;
    const {lines, totalQuantity, subTotal: rawSubTotal,
    } = shoppingCart;
    const availTime: TimeAvaType[]  = useMemo( //Too Much Computing here...
        () => open_hours&&getAvailTime(open_hours, initial_delay_time), [open_hours, initial_delay_time])
    const subTotal = new Decimal(rawSubTotal)

    const [placingOrderStatus, setPlacingOrder]  = useState<'idle'|'processing'|'success'>('idle')
    const [stripeSecret, setStripeSecret] = useState<undefined|string>(undefined)
    // const [successModalOpen, setSuccessModalOpen]  = useState(false)
    const [time, setTime] = useState('now')
    const [orderData, setOrderData] = useState(()=>{
        return {
            tips: "0.00",
            distance: 0,
            type: '',
            payment_type: '',
            comment: '',
            request_time: availTime[0] ? timestampToFormatTime(availTime[0].ts) : '' //availTime may yield empty arr
        }
    })
    const customerInfo = useRef<PlaceOrderRequest['customer_json']>({phone_number: '', email: '', name: '', address: ''})
    const commentTextRef = useRef<HTMLTextAreaElement>(null)
    const [isContactValidated, validateContact] = useState(false)
    const [isAddressValidated, validateAddress] = useState(false)
    const [isPaymentValidated, validatePayment] = useState(false)
    const [addressError, onAddrErrorUpdating] = useState<string|null>(null)
    const [paymentError, onPaymentErrorUpdating] = useState<string|null>(null)
    const onUserInfoChange = (field: keyof PlaceOrderRequest['customer_json']) => (e:ChangeEvent<HTMLInputElement>) => {
        customerInfo.current = {...customerInfo.current, [field]:e.target.value};
        validateContact(!!(customerInfo.current.phone_number && customerInfo.current.email))
    }
    const checkCartTimeout = async () => {
        try{
            console.debug('calling basket check')
            const result = await checkShoppingCart()
            if (!result || result.status) {
                reset();
            } else {
                cancel();
                setPlacingOrder('success')
                // this.setState({status: 'success', isPaying: false})
            }
        } catch(error: any){
            reset()
        }
    };
    const [_isReady, cancel, reset] = useTimeoutFn(checkCartTimeout, 2000);
    useEffect(()=> cancel(),[])
    const onOrderDataChange = <K extends keyof PlaceOrderRequest>(field: K) => (value: string|number|Decimal) => {

        if ( value instanceof Decimal) value = value.toFixed(2) //... looks SummarySection, userInfo only accept string not Decimal
        setOrderData(previous => {return {...previous, [field]: value}}) //https://stackoverflow.com/questions/64538271/is-reacts-usereducer-is-synchronous-compared-to-asynchronous-nature-of-usestate
    }
    const onTimeChange = (type: 'now'|'future') => {
        setTime(type)
        if(type === 'now') onOrderDataChange('request_time')(timestampToFormatTime(getCurrentTS()))
    }
    const onTypeChange = (type: string) => {
        setOrderData(previous => {return {...previous, distance: 0, type: type}})
        customerInfo.current = {...customerInfo.current, address: ''}
    }
    const onCardChange = useCallback((cardComplete: boolean, captured?: boolean) => {
        if (captured){
            reset()
        }
        else validatePayment(cardComplete)
        onPaymentErrorUpdating(null)
    },[reset,])

    const onAddrChange = useCallback(
        (address: string|undefined, distance: number|null) => {
        const errorMessage = formatMessage({
            defaultMessage: "Address out of range",
            description: "errorOutOfRange",
        })
        if(distance && distance > selectedPartner.delivery_range * 1000){
            onAddrErrorUpdating(errorMessage)
            address = ''
        }
        else if(address && distance) {
            onOrderDataChange('distance')(distance)
            validateAddress(true)
            onAddrErrorUpdating(null)
        }
        else validateAddress(false)
        customerInfo.current = {...customerInfo.current, address: address || ''};
    },[])
    const onCheckoutPlaceOrder= async ()=>{
        setPlacingOrder('processing')
        const orderRequest = getUserInfo() as PlaceOrderRequest
        if(orderRequest.payment_type === 'cash') orderRequest.tips = '0.00'
        try {
            const checkoutSecret = await getStripeSecret(orderRequest)
            if(orderRequest.payment_type === 'cash') setPlacingOrder('success')
            else setStripeSecret(checkoutSecret)
        } catch (error: any) {
            if ( error.non_field_errors?.message) onPaymentErrorUpdating(error.non_field_errors?.message)
            setPlacingOrder('idle')
        }
        // this.setState({isPlacingOrder: true/*, isCashPaying : payment_type === 'cash'*/})
    }
    const onEndTransaction = () => {
        history.replace(selectedPartner.id+'/')
    }
    // useEffect(() => console.log(orderData),[orderData])
    const getAvailType = () =>{
        const {type, is_min_subtotal_req, min_delivery_subtotal} = selectedPartner;
        const  isDeliveryAvail = !is_min_subtotal_req || (subTotal as Decimal).gte(min_delivery_subtotal||0);
        return (type as string[]).map((val)=>{
            if(val === 'take-out'){
                return {value: 'take-out', label: formatMessage({
                        defaultMessage: 'Pickup',
                        description: 'textPickup',
                    })};
            } else if (val === 'delivery' && isDeliveryAvail){
                return {value: 'delivery', label: formatMessage({
                        defaultMessage: 'Delivery',
                        description: 'textDelivery',
                    }), disabled: isDeliveryAvail};
            }
            return null;
        }).filter((val): val is SelectorType => val !== null)
    }
    const getAvailPayment = () => {
        return (payment_methods as string[]).map((val)=>{
            if(val === 'online-payment'){
                return {value: 'online-payment', label: formatMessage({
                        defaultMessage: 'Debit/Credit',
                        description: 'textCard',
                    })};
            } else if (val === 'cash'){
                return {value: 'cash', label: formatMessage({
                        defaultMessage: 'Cash',
                        description: 'textCash',
                    })};
            }
            return null;
        }).filter((val): val is SelectorType => val !== null).sort((prev,next) => {
            if(prev.value === 'online-payment') return -1
            return 1})

    }
    const availType = getAvailType() || [{value: 'unavailable', label: formatMessage({
                defaultMessage: 'Currently Unavailable',
                description: 'textUnavailable',
            })},]

    function isDeliverMinFeeAdd(subTotal:Decimal){
        const {is_min_subtotal_req, min_delivery_subtotal} = selectedPartner;
        return (!is_min_subtotal_req && subTotal.lt(min_delivery_subtotal||0));
    }
    const taxAndDeliveryCalc = (subtotal:Decimal, distance:number) =>{
        const {
            init_dist, per_km_charge, init_charge, delivery_tax_rate, tax_settings, below_min_fee
        } = selectedPartner
        const distKM = distance ? Math.max(1, distance/1000).toFixed(0) : false;
        let delivery_fee = (distKM ?
            new Decimal(per_km_charge).mul(Math.ceil(Math.max(0, parseFloat(distKM) - init_dist)) ).plus(init_charge)
                : new Decimal(0)
        )
        if(distKM && isDeliverMinFeeAdd(subtotal)){
            delivery_fee = delivery_fee.plus(below_min_fee)
        }
        const tax:Record<string, string> = {};
        let totalTax = new Decimal(0);
        (tax_settings as TaxProps[])?.forEach(setting=>{
            if((setting.apply_condition === 'ge' && subtotal.gte(setting.amount))
                || (setting.apply_condition === 'le' && subtotal.lte(setting.amount)) && TAX_TYPE[setting.key]){
                const taxAmount = subtotal.mul(setting.value).div(100).toFixed(2);
                tax[TAX_TYPE[setting.key].name] = taxAmount;
                totalTax = totalTax.plus(taxAmount)
            }
        })
        if(delivery_fee.gt(0)){
            const deliveryFeeTax = delivery_fee.mul(delivery_tax_rate || 0.13)
            tax[formatMessage({ defaultMessage: "Delivery Fee Tax",  description: "deliveryFeeTax",})] = deliveryFeeTax.toFixed(2);
            totalTax = totalTax.plus(deliveryFeeTax);
        }

        return {distKM, delivery_fee, tax, total_tax: totalTax, isMinAdd: distKM && isDeliverMinFeeAdd(subtotal)}
    }

    const calcResult = taxAndDeliveryCalc(subTotal, orderData.distance);
    const isPlacingDisabled = (!isContactValidated || (orderData.type==='delivery' && !isAddressValidated) || (orderData.payment_type==='online-payment' && !isPaymentValidated || !orderData.request_time))
    const isPlacingOrder = placingOrderStatus !== 'idle'
    const getUserInfo=() => {
        // const delivery_fee = calcResult.delivery_fee;
        return {
            ...orderData,
             customer_json: customerInfo.current,
            delivery_fee: calcResult.delivery_fee?.toFixed(2) || '0.00',
            total_tax: calcResult.total_tax.toFixed(2),
            comment: (time ===  'now'? '[ASAP ORDER] ': '') + commentTextRef.current?.value || "",
        }
    }
    const isNowAvail = open_hours && isNowAvailable(open_hours, initial_delay_time) // can't use memo due to it always requires current time, TBD check sec by sec, in case of idle webpage
    console.debug('Optimization Monitor Warning: Rendering Checkout', getUserInfo(), isContactValidated, isAddressValidated, isPaymentValidated, isPlacingOrder)
    return(
        <>
        <AbstractCtn style={{paddingTop: 20, textAlign: 'left'}}>
            <H1><A as="a" height={2} style={{display:'inline-block', fontWeight:700, fontSize:'32px', color:'inherit'}} href={`/${selectedPartner?.id}`}>{selectedPartner?.name}</A></H1>
        </AbstractCtn>
        <MainCtn>
                <AfterPayment isOpen={placingOrderStatus==='success'} onEndTransaction={onEndTransaction}/>
                <LeftCol>
                    <div>
                        <OrderSec>
                            <H3>Method</H3>
                            <P>{formatMessage({
                                defaultMessage: 'Delivery order with subtotal less than ${min_delivery_subtotal} cannot be delivered.',
                                description: 'warningDeliveryMinSubtotalDisable',
                            }, {min_delivery_subtotal: selectedPartner.min_delivery_subtotal})}</P>
                            <div style={{
                                display: 'flex',
                                position: 'relative',
                                margin: '16px 12px'
                            }}>
                                <Selector values={availType} onChangeCallback={onTypeChange}/>
                            </div>
                        </OrderSec>
                        <OrderSec>

                            <div style={{
                                position: 'relative',
                                display: 'inline-block',
                                marginBottom: 10
                            }}>
                                <H3>Contact</H3>
                                <HeadDivider focus={!isContactValidated}/>
                            </div>
                                <P>Please enter correct email and phone number to receive order information.</P>

                            <div style={{margin: '16px 12px'}}>
                                <label>{formatMessage({
                                    defaultMessage: 'Full name',
                                    description: 'placeholder name',
                                })}</label>
                                <TextField
                                    type="text"
                                    name={formatMessage({
                                        defaultMessage: 'Full name',
                                        description: 'placeholder name',
                                    })}
                                    onChange={(onUserInfoChange('name'))}
                                    isDisabled={isPlacingOrder}
                                />
                                <label>{formatMessage({
                                    defaultMessage: 'Phone Number',
                                    description: 'placeholder phoneNumber',
                                })+ " " + formatMessage({
                                    defaultMessage: 'required',
                                    description: 'placeholder required',
                                })}</label>
                                <TextField
                                    type="tel"
                                    name={formatMessage({
                                        defaultMessage: 'Phone Number',
                                        description: 'placeholder phoneNumber',
                                    })}
                                    onChange={onUserInfoChange('phone_number')}
                                    isDisabled={isPlacingOrder}
                                />
                                <label>{formatMessage({
                                    defaultMessage: 'Email',
                                    description: 'placeholder email',
                                })+ " " + formatMessage({
                                    defaultMessage: 'required',
                                    description: 'placeholder required',
                                })}</label>
                                <TextField
                                    type="email"
                                    name={formatMessage({
                                        defaultMessage: 'Email',
                                        description: 'placeholder email',
                                    })}
                                    onChange={onUserInfoChange('email')}
                                    isDisabled={isPlacingOrder}
                                />
                            </div>

                        </OrderSec>
                        {orderData.type === 'delivery' && <OrderSec>
                            <div style={{
                                position: 'relative',
                                display: 'inline-block',
                                marginBottom: 10
                            }}>
                                <H3>Address</H3>
                                <HeadDivider focus={!isAddressValidated}/>

                            </div>
                            <P style={{color: 'red'}}>{addressError}</P>
                            <AddressSection isDisabled={false}
                                            origin={selectedPartner.address}
                                            onErrorUpdating={onAddrErrorUpdating}
                                            onAddrChange={onAddrChange}
                            />
                        </OrderSec>}
                        <OrderSec>
                            <H3>{availType.find(type=>type.value === orderData.type)?.label} Time</H3>
                            {availTime.length ?
                                <>
                                    {isNowAvail &&
                                    <RadioLine>
                                        <RadioButton defaultChecked checked={time === 'now'}
                                                 onChange={() => onTimeChange('now')} value="on"/>
                                            <label onClick={() => onTimeChange('now')} style={{
                                                cursor: 'pointer',
                                                flex: '1 1 auto',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <OptionNameSpan style={{fontSize: '16px'}}>Right now</OptionNameSpan>
                                            </label>
                                    </RadioLine>}
                                    <RadioLine>
                                        <RadioButton  checked={time!=='now'} onChange={()=>onTimeChange('future')}  value="on"/>
                                        <label  onClick={()=>onTimeChange('future')} style={{cursor:'pointer', flex: '1 1 auto', display: 'flex', alignItems: 'center'}}>
                                            <OptionNameSpan style={{fontSize: '16px'}}>Schedule</OptionNameSpan>
                                        </label>
                                        {time === 'future' &&
                                        <FormControl>
                                            <Select
                                                style={{minWidth: 100}}
                                                defaultValue={availTime[0].ts}
                                                onChange={(e) => onOrderDataChange('request_time')(timestampToFormatTime(e.target.value))}
                                            >
                                                {availTime?.map((time) => <MenuItem key={time.ts} value={time.ts}>{time.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>}
                                    </RadioLine>
                                </> :
                                <P style={{marginLeft: '12px'}}>Currently Unavailable</P>
                            }
                        </OrderSec>
                        {orderData.payment_type !== 'cash' && <OrderSec>
                            <H3>Tips</H3>
                            <TipsSection baseTotal={subTotal.plus(calcResult.delivery_fee).plus(calcResult.total_tax).toFixed(2)} parentSetTip={onOrderDataChange('tips')}/>
                        </OrderSec>}
                        <OrderSec>
                            <InputBase
                                inputProps={{
                                    maxlength: "140"
                                }}
                                rows={3}
                                inputRef={commentTextRef}
                                multiline
                                placeholder={formatMessage({
                                    defaultMessage: "Leave notes for this order",
                                    description: "textLeaveComment",
                                })}
                                onChange={(e) => onOrderDataChange('comment')(e.target.value)}
                                style={{
                                    margin: '16px 12px',
                                    padding: 0,
                                    display: 'flex'
                                }}
                            />
                            <P style={{width:'100%', textAlign:'right'}}>{
                                // TODO: Consider move this OrderSec as a child component. avoiding rendering whole Page while typing.
                                `${commentTextRef.current?.value.length || 0}/${COMMENT_LENGTH_MAX}`}</P>
                        </OrderSec>
                    </div>
                </LeftCol>
                <RightCol>
                    <div style={{
                        position: 'sticky',
                        top: '85px'}}>
                        <OrderSec>
                            <H3>Summary</H3>
                            <div style={{margin: '16px 12px'}}>
                                {lines.map((line, idx) =>  <CartLine key={`cartLine-${idx}`} line={line} index={idx} isCheckout/>)}
                                <SummarySection
                                    style={{width: '100%'}}
                                    subTotal={subTotal} quantity={totalQuantity} calcResult={calcResult} tips={
                                    orderData.payment_type !== 'cash' && orderData.tips} //Cash no need to take tips here
                                />
                            </div>
                        </OrderSec>
                        <OrderSec>
                            <div style={{
                                position: 'relative',
                                display: 'inline-block',
                                marginBottom: 10
                            }}>
                                <H3>Payment</H3>
                                <HeadDivider focus={orderData.payment_type==='online-payment'&&!isPaymentValidated}/>
                            </div>
                            <P style={{color: 'red'}}>{paymentError}</P>
                            <div style={{
                                margin: '16px 12px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    position: 'relative',

                                }}>
                                    <Selector values={getAvailPayment()} onChangeCallback={onOrderDataChange('payment_type')}/>

                                </div>
                                {orderData.payment_type==='online-payment' &&
                                <CreditCardSection stripe_pk={selectedPartner.stripe_pk as string}
                                                   clientSecret={stripeSecret}
                                                   // orderData={getUserInfo() as PlaceOrderRequest}
                                                   onErrorUpdating={onPaymentErrorUpdating}
                                                   onCardChange={onCardChange}
                                />
                                }

                            </div>

                        </OrderSec>

                        <BarButton onClick={onCheckoutPlaceOrder} appearance="primary" isDisabled={isPlacingDisabled||isPlacingOrder}
                                   >

                            {
                                placingOrderStatus === 'processing' ?
                                    formatMessage({
                                        defaultMessage: "Processing...",
                                        description: "buttonPlaceOrderProcessing",
                                    })
                                    :
                                orderData.payment_type === 'cash' ?
                                formatMessage({
                                    defaultMessage: "Place Order",
                                    description: "buttonPlaceOrder",
                                })
                                : formatMessage({
                                    defaultMessage: "Proceed To Payment",
                                    description: "buttonProceedToPayment",
                                })}
                        </BarButton>
                    </div>

                </RightCol>
            </MainCtn>
        </>

    )
}

export default Checkout
