/**
 * Created by yueyang on 2017/5/17.
 */
import { connect } from 'react-redux'
import Popup from  '../../components/popup/popup'


const mapStateToProps = (state, ownProps) => {
    return {
        popupState: state.ui.popup
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);