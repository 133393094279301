import styled from 'styled-components';

const MainCtn = styled.div`
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1350px;
  position: relative;
  @media (min-width: 768px){
      padding-left: 50px;
      padding-right: 50px;
      max-width: 1390px;
  }     
  @media (min-width: 1080px) {
     max-width: 1430px;
  }         
`;
const SubMainCtn = styled.div`
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 950px;
  @media (min-width: 768px){
    padding-left: 100px;
    padding-right: 100px;
    max-width: 1240px;
  }
  
`
export default MainCtn;
export {SubMainCtn,}
