/*
 * drop ImmutableJS, using immer or Redux Toolkit
 * Redux Toolkit's createReducer and createSlice automatically use Immer
 * createSlice uses createReducer inside
 * Ref： https://redux-toolkit.js.org/usage/usage-guide
 */
import {OrderLine, OrderLineRequest, PlaceOrderRequest} from "./types";
import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {addFlag, hideSpinner, showSpinner} from "../../actions/uiActions";
import {
    parseResponse,
    API_ROOT,
    CSRF_TOKEN_KEY,
} from '../../actions/api';
import * as Schemas from "../../actions/schemas";
import Decimal from "decimal.js";
import {get_cookie} from "../../utils";
import Error from "@atlaskit/icon/glyph/error";
import {colors} from "@atlaskit/theme";
import {verifiedIsQR} from "../auth/reducer";

interface Partner {
    name: string;
    id: number;
    stripe_pk?: string;
    app_fee_factors?: {
        factor: string;
        base: string;
    }
}
interface ShoppingCartInterface {
    id?: string;
    status?: string;
    partner?: Partner;
    lines: OrderLine[];
    totalQuantity: number;
    subTotal: string | Decimal;
    taxRate: string;
    deliveryFee: string;
    totalPrice: string;
    tip: string;
    comment: string;
    otherInstruction: string;
    distance: number;
    delivery_info: string;
    deliveryMethod: 'delivery' | 'take-out';
    paymentMethod: 'cash' | 'online-payment';
    order_id?: string;
    table_num?: string;
}
interface ShoppingCartInitialStateInterface {
    shoppingCart: ShoppingCartInterface;
    secret?: string;
}
export const SECRET_ERROR = 'SECRET_ERROR'
// Define the initial state using that type
const initialState: ShoppingCartInitialStateInterface = {
    shoppingCart:{
        //order detail
        lines:[],
        totalQuantity: 0,
        subTotal: new Decimal(0),
        //other fees
        taxRate: "0.13",
        deliveryFee: "0",
        delivery_info:'',
        totalPrice: "0", //subtotal + tax + deliveryFee
        tip:"0", //This should be string, parse it before calculation

        comment: '',
        //payment info
        otherInstruction : "",
        distance: 0,
        paymentMethod: "cash", //cash in default, have 'pp_standard' 'pickup' options
        deliveryMethod: "delivery",
        table_num: '',
        // owner:null,
        // isFutureOrder:null,
        //Experiment
        // voucher_discounts:null,
        // futureTime: null,
        // quickOrderEmail: "",
        // table_id: "",
        // table_token: "",
    }
}
const shoppingCartReducer = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        getShoppingCart(state, action) {
            state.shoppingCart = action.payload;
            state.shoppingCart.subTotal = calculateTotalPrice(action.payload);
            state.shoppingCart.totalQuantity = calculateTotalQuantity(action.payload.lines);
        },
        checkoutSecretGot(state, action) {
            state.secret = action.payload;
        },
        resetShoppingCart(state, action) {
            state = initialState;
        }
    },
})
const { actions, reducer } = shoppingCartReducer
const { getShoppingCart, checkoutSecretGot, resetShoppingCart } = shoppingCartReducer.actions

/*
 * Asynchronous Logic and Data Fetching
 * https://redux-toolkit.js.org/usage/usage-guide#asynchronous-logic-and-data-fetching
 */
const fetchShoppingCart = () =>
    async (dispatch: Dispatch) => {
        const shoppingCartUrl = API_ROOT + 'cart';
        try {
            const respJson = await fetch(shoppingCartUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                method: "get",
            }).then(parseResponse)
            dispatch(getShoppingCart(respJson))
            if(respJson.qr_session !== undefined){
                dispatch(verifiedIsQR(respJson.qr_session))
            }
        } catch (e) {
            throw e
        }
    }

const checkShoppingCart = async () => {
        const shoppingCartUrl = API_ROOT + 'cart';
        try {
            return await fetch(shoppingCartUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                method: "get",
            }).then(parseResponse)
        } catch (e) {
            throw e
        }
    }

const addOrderToShoppingCart = (newLineObj:OrderLineRequest) =>
    //Generate Order Info
    async (dispatch: Dispatch) => {
        dispatch(showSpinner())
        const shoppingCartUrl = API_ROOT + "product/" + newLineObj.product.toString() + "/add-cart";
        try{
            const respJson = await fetch(shoppingCartUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'X-CSRFToken': get_cookie(CSRF_TOKEN_KEY)
                },
                method: "post",
                credentials: 'include',
                body: JSON.stringify(newLineObj)
            }).then(parseResponse)
            dispatch(getShoppingCart(respJson))
        }catch (e){
            throw e
        }finally {
            dispatch(hideSpinner())
        }
    }
const editOrderInShoppingCart = (index:number,quantity:number) =>
    //Generate Order Info
    async (dispatch:Dispatch) => {
        const orderInfo = {
            index,
            line:{
                quantity,
            }
        }
        dispatch(showSpinner())
        const shoppingCartUrl = API_ROOT + 'cart';
        try{
            const respJson = await fetch(shoppingCartUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'X-CSRFToken': get_cookie(CSRF_TOKEN_KEY)
                },
                credentials: 'include',
                method: "PATCH",
                body: JSON.stringify(orderInfo)
            }).then(parseResponse)
            dispatch(getShoppingCart(respJson))
        }catch (e){
            throw e
        }finally {
            dispatch(hideSpinner())
        }
}
// we also accept Cash ;) which returns {status: 'success'} if success instead of resp.secret
const getStripeSecret = async (userInfo:PlaceOrderRequest) => {
    const url = API_ROOT + 'checkout';
    try{
        const resp = await fetch(url, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'X-CSRFToken': get_cookie(CSRF_TOKEN_KEY)
            },
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(userInfo)
        }).then(parseResponse)
        if(userInfo.payment_type === 'cash')
            return resp.status
        return resp.secret
    } catch (errors){
        throw errors;
    }
}


const submitQRShoppingCart = (/*lines:[OrderLine]*/) =>
    async (dispatch: Dispatch) => {
        dispatch(showSpinner())
        const checkoutUrl = API_ROOT + 'qrmenu/checkout';
        try {
            const resp = await fetch(checkoutUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                method: "POST",
                body: JSON.stringify({/*lines*/})
            }).then(parseResponse)
            dispatch(getShoppingCart(resp))
        } catch (e) {
            throw e
        }finally {
            dispatch(hideSpinner())
        }
    }


const prepareCheckout = (userInfo:PlaceOrderRequest) =>
    async (dispatch:Dispatch) => {
        const url = API_ROOT + 'checkout';
        dispatch(showSpinner())
        try{
            const {secret} = await fetch(url, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'X-CSRFToken': get_cookie(CSRF_TOKEN_KEY)
                },
                credentials: 'include',
                method: "POST",
                body: JSON.stringify(userInfo)
            }).then(parseResponse)
            return secret
        } catch (errors: any){
            const nonFieldError = errors.non_field_errors?.message
            if (errors.hasOwnProperty('errors') && nonFieldError) {
                dispatch(addFlag(
                    {
                        description: nonFieldError.message,
                        title: 'Error',
                        icon: <Error label="Error icon" secondaryColor={colors.R300}/>,
                        appearance: 'error'
                    }
                ));
            }
            dispatch(checkoutSecretGot(SECRET_ERROR))
            throw errors;
        } finally {
            dispatch(hideSpinner())
        }
}


export default reducer
export {fetchShoppingCart, resetShoppingCart, addOrderToShoppingCart, editOrderInShoppingCart, prepareCheckout,getStripeSecret, checkShoppingCart, submitQRShoppingCart, actions}


//Calculate Price for an OrderEntry
function calculatePrice(line: OrderLine){
    return line.options.reduce((line_total, option) => {
        if(option.option.type === Schemas.OPTION_BOOLEAN)
            return line_total.add(option.option.applied_price)
        else if(option.option.type === Schemas.OPTION_INTEGER)
            return line_total.add(new Decimal(option.option.applied_price).mul(option.value as number))
        else return line_total
    }, new Decimal(line.product.price)).mul(line.quantity)
}

//Calculate total price for all orders in shopping cart
function calculateTotalPrice(shoppingCart:ShoppingCartInterface){
    // console.log(shoppingCart.lines);
    return shoppingCart.lines.reduce((subTotal, line) => subTotal.add(calculatePrice(line)) , new Decimal(0))
    // const couponDiscount = extraPriceFactors.voucher_discounts? Decimal(extraPriceFactors.voucher_discounts.amount):Decimal(0)
    // const totalPrice = subTotal.add(extraPriceFactors.deliveryFee).mul(1.13)/*.sub(couponDiscount)*/.toFixed(2)
}


//Calculate total quantity for all orders in shopping cart
function calculateTotalQuantity(lines:OrderLine[]){
    return lines.reduce((total, line) => total + line.quantity, 0)
}
